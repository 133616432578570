import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import { LoginActionTypes } from '../../redux/login/login.types';
import { showWarningLayer, hideWarningLayer } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { LoginActionTypes } from '../../redux/login/login.types';
import axios from 'axios';
import KendoMenu from '../kendo/kendo-menu/kendo-menu';
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';


import './header.styles.scss';

//JQUERY CONFIRM DIALOG BOX
const changePasswordDialog = (open = false, type = 'confirm', title = 'Change Password', changeUserPassword,setpass,setrepass) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} title={title} action={changeUserPassword}>
          <p>Lütfen yeni şifrenizi tanımlayınız. </p>
          <ul id="PasswordWindowID" className="PasswordWindowID">
            <li>
                <input type="password" className="editpass" name="editPass" placeholder="Yeni Şifre" onChange={(event)=> setpass(event.target.value)} />
            </li>
            <li>
                <input type="password" className="editpass" name="editRePass" placeholder="Yeni Şifre Tekrar" onChange={(event)=> setrepass(event.target.value)} />
            </li>
          </ul>        
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class TopHeader extends React.Component {
  constructor(props){
    super(props);
    this.x = "SR";
  }
  
  handleLogout = (event) => {
    //console.log(event);
    this.props.setLoading(true);
    this.props.onWarning({warningMessageType:'success', warningMessageText: 'Çıkış yapıldı. Giriş sayfasına yönlendiriliyorsunuz.'});
    showWarningLayer();
    setTimeout(() => {
      this.props.logOut();
      this.props.setDefault();
      const bodyElement = document.querySelector('body');
      bodyElement.classList.remove('noBodyImage');
      hideWarningLayer();
    },3000);
    
  }

  changePassword = async () => {
    //console.log(this.props.user);
    const pass = this.props.user.Pass;
    const repass = this.props.user.RePass;

    if (pass === null || pass === ''){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanını kontrol edin.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    }    

    if (repass === null || repass === ''){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre tekrar alanını kontrol edin.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    }    

    if (pass !== repass){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Şifre alanları aynı değil.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    } 
    
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/change-password";
    
    const response = await axios.post(dataUrl,{ "uid": this.props.user.UserID, "sid": this.props.user.SessionID, "pass": pass, "repass":repass},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        let warningMessageText = "Şifreniz değiştirildi.";
        this.props.onWarning({warningMessageType:'done', warningMessageText: warningMessageText});
        showWarningLayer();
        this.props.setLoading(false);
        return;
      }else{
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
        return false;  
      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    }
    
    
  }

  handleReportChange = (value) => {
    if (value === "summary"){
      this.props.history.push('/raporlar');
    }else if (value === "passenger"){
      this.props.history.push('/raporlar-servis-yolcu-listesi');
    }else if (value === "monthlyinvoicedservices"){
      this.props.history.push('/raporlar-aylik-faturali-servis-kullanimi');
    }else if (value === "monthlyservicecounts"){
      this.props.history.push('/raporlar-rotaya-gore-aylik-servis-adetleri');
    }else{
      return false;
    }
  }

  handleActionChange = (value) => {
    if (value === "servisarac"){
      this.props.history.push('/servis-islemleri');
    }else if (value === "yolcuaktarma"){
      this.props.history.push('/servis-yolculari-aktarma');
    }else if (value === "limitdegistirme"){
      this.props.history.push('/servis-limit-degistirme');
    }else if (value === "guzergaharac"){
      this.props.history.push('/guzergah-arac-ve-surucu-degistirme');
    }else{
      return false;
    }
  }  


  render(){
    //console.log(currentPagePrefix);
    let currentPagePrefix =  this.props.extraPage !== null ? this.props.extraPage : "";
    //let extraPage = this.props.extraPage;
    // try{
    //   currentPagePrefix = currentPagePrefix.replace('-','');
    // }catch(ex){
    //   currentPagePrefix = this.props.currentPage;
    // }
    //console.log(currentPagePrefix);
    return(
      
      <div className="rightTopLayer">
          <div className="breadcrumbLayer float-left">
            {
              currentPagePrefix === 'raporlar' ?
                <KendoMenu 
                methods={{ //name of method and array of arguments to pass to method

                }}
                //Right now, always updates
                events={{ //name of event, and callback
                    close:()=>{

                    },
                    select:()=>{

                    },
                    open:()=>{

                    }
                  }
                }
                //updates if array is different from initial mount
                unbindEvents={[ //name of event to unbind, string
                    
                ]}
                //updates if array is different from initial mount
                triggerEvents={[ //name of event to trigger, string
                  
                ]}>            
            
                <ul className="mainMenu raporMenu">
                    <li>
                        {this.props.breadcrumbTitle} 
                        <ul>
                            <li onClick={() => this.handleReportChange('summary')} className="border-sep">
                                Günlük Rezervasyon Listesi
                            </li>   
                            
                            {
                              this.props.user.UserRoles.includes("SUADMIN") ? 
                              <React.Fragment>
                                <li onClick={() => this.handleReportChange('passenger')}>
                                  Servis Yolcu Raporu
                                </li>
                                <li onClick={() => this.handleReportChange('monthlyinvoicedservices')}>
                                  Aylık Faturalı Servis Kullanımı
                                </li>                                
                                <li onClick={() => this.handleReportChange('monthlyservicecounts')}>
                                  Rotaya göre Aylık Servis Adetleri
                                </li>                                
                              </React.Fragment> : ''
                            }
                        </ul>
                    </li>
                </ul>
              </KendoMenu>
              :
              currentPagePrefix === 'servisislemleri' || currentPagePrefix === 'servisislemleri' ?
                <KendoMenu 
                methods={{ //name of method and array of arguments to pass to method

                }}
                //Right now, always updates
                events={{ //name of event, and callback
                    close:()=>{

                    },
                    select:()=>{

                    },
                    open:()=>{

                    }
                  }
                }
                //updates if array is different from initial mount
                unbindEvents={[ //name of event to unbind, string
                    
                ]}
                //updates if array is different from initial mount
                triggerEvents={[ //name of event to trigger, string
                  
                ]}>            
            
                <ul className="mainMenu raporMenu">
                    <li>
                        {this.props.breadcrumbTitle} 
                        <ul>
                            
                            <li onClick={() => this.handleActionChange('servisarac')} className="border-sep">
                                Servis Araç ve Sürücü Değiştirme
                            </li>                              
                            {
                              this.props.isLoggedIn === true && this.props.checkUser === true && this.props.user.UserRoles.includes("SUADMIN") ?
                                <li onClick={() => this.handleActionChange('yolcuaktarma')}>
                                    Servis Yolcuları Aktarma
                                </li>
                              : ''
                            }
                            {
                              this.props.isLoggedIn === true && this.props.checkUser === true && this.props.user.UserRoles.includes("SUADMIN") ?
                                <li onClick={() => this.handleActionChange('limitdegistirme')}>
                                    Servis Limit Değiştirme
                                </li>
                                : ''
                            }
                            {
                              this.props.isLoggedIn === true && this.props.checkUser === true && this.props.user.UserRoles.includes("SUADMIN") ?
                              <li className="k-separator">-</li>
                              : ''
                            }
                            {
                              this.props.isLoggedIn === true && this.props.checkUser === true && this.props.user.UserRoles.includes("SUADMIN") ?
                              <li onClick={() => this.handleActionChange('guzergaharac')}>
                                  Güzergah Araç ve Sürücü Değiştirme
                              </li>
                              : ''
                            }

                            
                        </ul>
                    </li>
                </ul>
              </KendoMenu>
              :
                <p className="breadcrumbTitle">{this.props.breadcrumbTitle}</p>
            }
          </div>
          <div className="menuWrapper float-right">
            <KendoMenu 
                options={{
                  openOnClick: true
                }}

                methods={{ //name of method and array of arguments to pass to method

                }}
                //Right now, always updates
                events={{ //name of event, and callback
                    close:()=>{

                    },
                    select:()=>{

                    },
                    open:()=>{

                    }
                  }
                }
                //updates if array is different from initial mount
                unbindEvents={[ //name of event to unbind, string
                    
                ]}
                //updates if array is different from initial mount
                triggerEvents={[ //name of event to trigger, string
                  
                ]}>            
            
                <ul className="mainMenu">
                    <li>
                        {this.props.user.DisplayName} <i className="fas fa-user-circle mainMenuIcon"></i>
                        <ul>
                            <li onClick={() => changePasswordDialog(true,'changepassword','Şifre Değiştir',this.changePassword,this.props.setPass,this.props.setRePass)()} className="border-sep">
                                Şifre Değiştir
                            </li>                      
                            <li onClick={(event) => this.handleLogout(event)}>
                                Çıkış
                            </li>
                        </ul>
                    </li>
                </ul>
            </KendoMenu>
          </div> 
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.login.isLoading,
    user: state.login.User,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setPass: (pass) => dispatch({ type: LoginActionTypes.SET_PASS, value: pass}),
    setRePass: (repass) => dispatch({ type: LoginActionTypes.SET_REPASS, value: repass}),
    setBreadcrumbTitle: (value) => dispatch({ type: ProjectActionTypes.SET_BREADCRUMB_TITLE, value: value})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(TopHeader));



