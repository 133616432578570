import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import Select from 'react-select';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';
import PopupModal from '../../components/popup-modal/popup-modal.component';
import { showWarningLayer, showWarningLayerPopup, hideWarningLayerPopup } from '../../utils/utils';

import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import axios from 'axios';

import './routes-buses-drivers.styles.scss';

import '../../assets/styles/react-bootstrap-table-all.min.css';

class RoutesBusesDrivers extends React.Component {
  constructor(props){
    super(props);
    //console.log(this.props.location.state);
    this.props.setServices([]);
    this.excelData = [];
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
    this.searchRef = React.createRef()
    this.state = {
      loading: false,
      filters: this.props.location.state === undefined ? [] : this.props.location.state.filters,
      selectedOption: this.props.location.state === null || this.props.location.state === undefined  ? null : this.props.location.state.selectedOption,
      excelFileName: "",
      modalShowPopup:false,
      isDisableFilter:true,
      defaultSearch:'',
      disableSaat:true,
      selectedOptionSaat: this.props.location.state === null || this.props.location.state === undefined  ? null : this.props.location.state.selectedOptionSaat,
      currentOptionTarihValue: "",
      currentOptionSaatValue: "",
      currentRoute: null,
      drivers:[],
      buses:[],
      selectedOptionBus: null,
      selectedOptionDriver: null
    }

  }

  componentDidMount(){
    this.getRoutesBusesDrivers();
  }
 
  getRoutesBusesDrivers = () => {
    this.setState({
      ...this.state,
      loading:true,
      isDisableFilter:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-routes-buses-drivers`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let routesbusdrivers;
        try{
          routesbusdrivers = res.data.data;
          if (routesbusdrivers === null || routesbusdrivers === undefined){
            routesbusdrivers = [];
          }
        }catch(ex){
          routesbusdrivers = [];
        }
        this.props.setRouteBusDrivers(routesbusdrivers);
        this.setState({...this.state, loading:false, isDisableFilter:false });
      }     
    })
  }


  getBuses = (row) => {
    this.props.setLoadingPopup(true);
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-buses`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let buses;
        try{
          buses = res.data.data;
          if (buses === null || buses === undefined){
            buses = [];
          }
        }catch(ex){
          buses = [];
        }
        
        let oldBusInfo = buses.filter(item => item.BUSPLATE === row.BUSPLATE);
        //let filteredBus = buses.filter(item => item.BUSPLATE !== row.BUSPLATE);
        let filteredBus = buses;
        //this.setState({people: filteredArray});   
        try{
          row.OLDBUSID = oldBusInfo[0].BUSID;   
        }catch(ex){}
        //row.OLDBUSID = oldBusInfo[0].BUSID;   
        this.props.setBuses(filteredBus);
       
        this.getDrivers(row); 
      }     
    })
  }

  getDrivers = (row) => {
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-drivers`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let drivers;
        try{
          drivers = res.data.data;
          if (drivers === null || drivers === undefined){
            drivers = [];
          }
        }catch(ex){
          drivers = [];
        }
        //this.setState({...this.state,isFilterDisable:false,firstLoading:false, loading:false});
        //let filteredDrivers = drivers.filter(item => item.DISPLAYNAME !== row.BUSDRIVER);
        let filteredDrivers = drivers;
        //this.setState({people: filteredArray});        
        this.props.setDrivers(filteredDrivers);        
        this.props.setLoadingPopup(false);
        //console.log(row);
        //this.setState({...this.state, currentRoute: row})
      }     
    })
  }  


  updateRouteDriver = async () => {
    this.props.setLoadingPopup(true);
    let newbusid = this.state.selectedOptionBus === null ? "" : this.state.selectedOptionBus.BUSID;
    let oldbusid = this.state.currentRoute.OLDBUSID;
    let driverid = this.state.selectedOptionDriver === null ? "" : this.state.selectedOptionDriver.DRIVERID;
    let routeid = this.state.currentRoute.ROUTEID;

    if (newbusid === "" || newbusid === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen araç seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }


    if (driverid === "" || driverid === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen sürücü seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-route-bus-driver`,
      {
        "sid": this.props.user.SessionID,
        "newbusid": newbusid,
        "oldbusid":oldbusid,
        "driverid":driverid,
        "routeid":routeid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Newbusid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen araç seçiniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Driverid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen sürücü seçiniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Güzergah araç ve sürücü güncellendi.'});
        showWarningLayer();
        this.getRoutesBusesDrivers(); 
        this.setState({...this.state,modalShowPopup:false});
       
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }    






  handleChangePopup = (row) => {
    console.log(row);
    if (row !== null){
      this.setState({...this.state, currentRoute: row, modalShowPopup:true});
      this.getBuses(row); 
             
    }
  }  


  handleChangeBuses = selectedOptionBus => {
    this.setState(
      { selectedOptionBus });
  };   

  handleChangeDriver = selectedOptionDriver => {
    this.setState(
      { selectedOptionDriver });
  };   




  buttonDetail = (cell, row) => {
    return (
      <button type="button" className="btn btn-warning btn-rota-detay" onClick={() => this.handleChangePopup(row)}>Değiştir</button>
    )
  }

  routeType = (cell, row) => {
    return cell === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"
  }

  // handleFilterSearch = (value) => {
  //   try{ this.refs.servicenameCol.applyFilter(value);} catch(ex){}
  // }

  handleFilterSearch = (value) => {
    //this.refs.servicenameCol.applyFilter(value);

    if (value === ""){
      try{
        this.refs.tableService.handleSearch(value);
        this.setState({defaultSearch: value});
      }catch(e){
        return false;
      }
      
    }else{
      if (value.length >= 3){
        try{
          this.refs.tableService.handleSearch(value);
          this.setState({defaultSearch: value});
        }catch(ex){
          return false;
        }
      }else{
        return false;
      }
    }
    
  }

  handleSaatChange = (selectedOption) => {
    try{
       this.refs.servicenameCol.applyFilter(selectedOption.value);
       this.setState({...this.state, selectedOptionSaat: selectedOption });      
      
      } catch(ex){}
  }  


  handleFilterChange = (selectedOption ) => {
    this.getServiceSummary(selectedOption);
//    this.refs.servicedateCol.applyFilter(selectedOption.value);
  }

  afterColumnFilter = (filterConds, result) => {
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
  }

  afterSearch = (searchText, result) => {
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
  }  

  render(){
    
    const options = {
      // sizePerPageList: [],
      // hidePageListOnlyOnePage: true,
      // sizePerPage: 100,
      //afterColumnFilter: this.afterColumnFilter,
      afterSearch: this.afterSearch,
      defaultSearch: this.state.defaultSearch,
      noDataText: 'Gösterilecek kayıt yok'
      
    };
    const footerData = [
      [
        {
          label: '',
          columnIndex: 1,
          align: 'left',
          formatter: (tableData) => {
            let total = tableData.length;
            return (
              <strong>Toplam : { total } adet</strong>
            );
          }
          
        }
      ]
    ];    
     
    
    return(
      <div>
      <div className="card card-body bg-light content-toolbar">
      <div className="d-flex">
          <div className="p-2 d-flex">
        
          </div>
          <div className="ml-auto p-2">
              <div className="d-flex">
                <label className="filterLabelYolcuRapor">Filtrele : </label> <input id="editFilter" ref={this.searchRef} type="text" className="form-control edit" placeholder="Arama kriteri" onChange={(event) => this.handleFilterSearch(event.target.value)}  />
              </div>
          </div>
      </div>   
      </div>       
      {
        this.state.loading ? <LoadingIndicator /> :
        this.props.routeBusDrivers.length > 0 ? (
          <BootstrapTable ref='tableService' data={this.props.routeBusDrivers} containerClass='services-list' striped hover options={options} footerData={ footerData } footer search>
            <TableHeaderColumn dataField='ROUTEID' isKey={true} hidden>ID</TableHeaderColumn>
            <TableHeaderColumn dataField='ROUTENAME' width='350px' dataSort={ true }>Rota / Güzergah Adı</TableHeaderColumn>
            <TableHeaderColumn dataField='SCHNAME' width='120px' dataAlign='center' headerAlign='center' dataSort={ true }>Rota Saati</TableHeaderColumn>
            <TableHeaderColumn dataField='ROUTEDIRECTION' width='160px' dataAlign='center' headerAlign='center' dataFormat={ this.routeType } dataSort={ true }>Rota Yönü</TableHeaderColumn>
            <TableHeaderColumn dataField='BUSPLATE' width='130px' dataAlign='center' headerAlign='center' dataSort={ true }>Plaka No</TableHeaderColumn>
            <TableHeaderColumn dataField='DISPLAYNAME' width='250px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Adı </TableHeaderColumn>
            <TableHeaderColumn dataField='PHONENUMBER' width='200px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Cep Telefonu </TableHeaderColumn>
            <TableHeaderColumn dataField='BUSCAPACITY' dataAlign='center' headerAlign='center' width='160px' dataSort={ true }>Servis Kapasitesi</TableHeaderColumn>
            <TableHeaderColumn dataField='BUSLIMIT' dataAlign='center' headerAlign='center' width='130px' dataSort={ true }>Yolcu Limiti</TableHeaderColumn>
            <TableHeaderColumn dataField="button" width='130px' tdStyle={ { padding: '5px' } } dataFormat={this.buttonDetail} dataSort={ true }></TableHeaderColumn>
          </BootstrapTable>   
        ) : 
        <p>Gösterilecek kayıt yok.</p>
      }
              
      <PopupModal
          show={this.state.modalShowPopup}
          onHide={() => this.setState({...this.state,modalShowPopup:false})}
          titlemodal={`Güzergah Araç ve Sürücü Değiştirme | ${this.state.currentRoute === null ? "" :this.state.currentRoute.ROUTENAME + " - " + this.state.currentRoute.BUSPLATE }`}
          onClick={()=> this.updateRouteDriver}>
          <p className="rotalabel">Araç Listesi</p> 
          <Select
            placeholder="Araç seçiniz"
            value={this.selectedOptionBus}
            onChange={this.handleChangeBuses}
            options={this.props.buses}
            getOptionLabel={({BUSPLATE}) => BUSPLATE}
            getOptionValue={({BUSID}) => BUSID}
          />
          <br/>
          <p className="rotalabel">Sürücü Listesi</p> 
          <Select
            placeholder="Sürücü seçiniz"
            value={this.state.selectedOptionDriver}
            options={this.props.drivers}
            onChange={this.handleChangeDriver}
            getOptionLabel={({DISPLAYNAME}) => DISPLAYNAME}
            getOptionValue={({DRIVERID}) => DRIVERID}            
          />
   
        <LoadingLayer showLoading={this.props.showLoadingPopup} />
        <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />

      </PopupModal>            


      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    isLoading: state.login.isLoading,
    error: state.login.error,
    services: state.passenger.services,
    serviceRow: state.passenger.serviceRow,
    showLoadingPopup:state.login.showLoadingPopup,
    buses: state.definitions.buses,
    drivers: state.definitions.drivers,
    routeBusDrivers: state.definitions.routeBusDrivers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setBuses: (buses) => dispatch({ type: DefinitionsActionTypes.SET_BUSES, buses: buses}),
    setRouteBusDrivers: (routeBusDrivers) => dispatch({ type: DefinitionsActionTypes.SET_ROUTEBUSDRIVERS, routeBusDrivers: routeBusDrivers}),
    setDrivers: (drivers) => dispatch({ type: DefinitionsActionTypes.SET_DRIVERS, drivers: drivers}),
    setServices:  (services) => dispatch({ type: PassengerActionTypes.SET_SERVICES, services: services}),
    setCurrentService: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE, payload: payload})
    
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(RoutesBusesDrivers));