import React from 'react';

import './login-copyright.styles.scss';

const currentDate = new Date();

const LoginCopyright = ({copyrightText,copyright}) => (
  <div className="loginCopyrightID">
      <p>{copyright} © {currentDate.getFullYear()}. {copyrightText}.</p>
  </div>
);

export default LoginCopyright