export const PassengerActionTypes = {
  SET_PASSENGERS: 'SET_PASSENGERS',
  SET_SERVICES: 'SET_SERVICES',
  SET_PASSENGERS_LIST: 'SET_PASSENGERS_LIST',
  SET_CURRENT_SERVICE: 'SET_CURRENT_SERVICE',
  SET_CURRENT_SERVICE_ID: 'SET_CURRENT_SERVICE_ID',
  SET_CURRENT_SERVICE_NAME: 'SET_CURRENT_SERVICE_NAME',
  SET_SERVICE_DATE: 'SET_SERVICE_DATE',
  SET_SERVICE_FILTERS: 'SET_SERVICE_FILTERS',
  SET_EXCEL_DATA: 'SET_EXCEL_DATA',
  SET_EXCEL_YOLCU: 'SET_EXCEL_YOLCU',
  SET_ADDRESS_FORM: 'SET_ADDRESS_FORM',
  SET_ROUTES: 'SET_ROUTES'
}