import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import ActiveServices from '../../components/active-services/active-services.component';
import ActiveServicesMobile from '../../components/active-services-mobile/active-services-mobile.component';
import MainLogo from '../../components/main-logo/main-logo.component';
import TopHeader from '../../components/header/header.component';
import MobileHeader from '../../components/mobile-header/mobile-header.component';
import ContentWrapper from '../../components/content-wrapper/content-wrapper.component';
import NavMenu from '../../components/nav-menu/nav-menu.component';
import MainCopyright from '../../components/main-copyright/main-copyright.component';
import { ProjectActionTypes } from '../../redux/project/project.types';

import '../../assets/styles/edms-main.scss';
import '../../assets/styles/edms-overrides.scss';
import './servisler.styles.scss';



class Servisler extends React.Component {
  constructor(){
    super();
    this.title = "Servisler / Rezervasyonlar";
  }

  

  componentDidMount() {
    if (this.props.isLoggedIn === true) {
      const bodyElement = document.querySelector('body');
      bodyElement.classList.add('noBodyImage');
      //console.log(this.props.user);
      this.props.setCurrent('servisler');
    }
  }

  render(){
    if (this.props.isLoggedIn === false) {
      return <Redirect push to="/" />;
    }      
    return(
      <div className="middleID">
        <MediaQuery minWidth={768}>
          <div className="leftID">
            <MainLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
            <NavMenu currentPage={this.props.currentPage} /> 
            <MainCopyright companyName="DocPlant" />
          </div>
          <div className="rightID">
            <TopHeader breadcrumbTitle={this.title} />
            <ContentWrapper>
              <ActiveServices/>
            </ContentWrapper>
          </div>
          <div id="jqueryconfirm"></div>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className="mobileWrapper">
            <MobileHeader />
            <ContentWrapper>
              <ActiveServicesMobile/>
            </ContentWrapper>
          </div>
          <div id="jqueryconfirm"></div>
        </MediaQuery>

     </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    currentPage: state.project.currentPage,
    warningMessageShow: state.project.warningMessageShow,
    warningMessageType: state.project.warningMessageType,
    warningMessageText: state.project.warningMessageText,
    user: state.login.User
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(Servisler);