import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import axios from 'axios';

import './service-passengers.styles.scss';
import 'react-tabs/style/react-tabs.css';


class ServicePassengers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      passengers: [],
      passengersAll: [],
      selectedPassengerID: null,
      tabIndex: 0
    }

  }

  componentDidMount() {
    this.getPassengersAll();
  }

  //...
  handleChangeUserRoute = async (selectedOption) => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
    //console.log(selectedOption.value);
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-userotherroutes`, { "sid": this.props.user.SessionID, "uid": this.state.selectedUserID, "rid": selectedOption.value }, { crossDomain: true });
    if (res.data.type === "error") {
      if (res.data.message === "DP_Session") {
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');
      }
    } else {
      let routes;
      try {
        routes = res.data.data;
        if (routes === null || routes === undefined) {
          routes = [];
        }
      } catch (ex) {
        routes = [];
      }

      //console.log(JSON.stringify(routes));
      //console.log(services);
      this.setState({ ...this.state, loading: false, activeRoutes: routes, activeRouteDisable: false, selectedOption });
    }

    // this.setState(
    //   {
    //     ...this.state,
    //     activeRouteDisable:false,
    //     selectedOption 
    //   }
    // );


  };


  handleChangeActiveRoute = selectedOptionActiveRoute => {
    this.setState(
      { ...this.state, selectedOptionActiveRoute }
    );
  };

  getPassengersAll = () => {
    this.setState({
      ...this.state,
      loading: true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-passengers-all`, { "sid": this.props.user.SessionID, "srid": this.props.currentServiceID }, { crossDomain: true })
      .then(res => {
        if (res.data.type === "error") {
          if (res.data.message === "DP_Session") {
            this.props.logOut();
            this.props.setDefault();
            const bodyElement = document.querySelector('body');
            bodyElement.classList.remove('noBodyImage');
          }
        } else {
          let passengers;
          let passengersAll;
          try {
            passengers = res.data.data;
            if (passengers === null || passengers === undefined) {
              passengers = [];
            }
          } catch (ex) {
            passengers = [];
          }

          try {
            passengersAll = res.data.data2;
            if (passengersAll === null || passengersAll === undefined) {
              passengersAll = [];
            }
          } catch (ex) {
            passengersAll = [];
          }

          this.setState({ ...this.state, passengers: passengers, passengersAll: passengersAll, loading: false });
        }
      })
  }


  goBack = () => {
    this.props.history.push('/raporlar', this.props.location.state);
  }

  render() {
    //console.log(this.state.activeServices);
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 300,
      noDataText: 'Gösterilecek kayıt yok'
    };

    let tempDate = this.props.serviceRow.SERVICEDATE.split(" ");
    let tempName = this.props.serviceRow.SERVICENAME.split(" ");
    let currentDate = tempDate[0].replace(/[\/]/g, '-'); //eslint-disable-line
    const excelFileName = currentDate + " " + tempName[0] + "-" + tempName[2];


    return (
      <div>
        <div className="card card-body bg-light content-toolbar">
          <div className="d-flex">
            <div className="d-flex p-2">
              <h5 className="toolbarTitle">{this.props.serviceRow.SERVICEDATE} {this.props.currentServiceName}</h5>
            </div>
            <div className="ml-auto p-2">
              <div className="d-flex">
                <ReactHTMLTableToExcel
                  className={`btn btn-info btn-excel ${this.state.tabIndex === 0 ? " " : " hidden"}`}
                  table="emp-reserved"
                  filename={`${excelFileName} Rezervasyon Yapan Yolcular`}
                  sheet="Rezervasyon Yapan Yolcular"
                  buttonText="Excel'e aktar"
                />
                <ReactHTMLTableToExcel
                  className={`btn btn-info btn-excel ${this.state.tabIndex === 1 ? " " : " hidden"}`}
                  table="emp-all"
                  filename={`${excelFileName} Aktif Yolcular`}
                  sheet="Aktif Yolcular"
                  buttonText="Excel'e aktar"
                />
                <ReactHTMLTableToExcel
                  className={`btn btn-info btn-excel ${this.state.tabIndex === 2 ? " " : " hidden"}`}
                  table="emp-all"
                  filename={`${excelFileName} Pasif Yolcular`}
                  sheet="Pasif Yolcular"
                  buttonText="Excel'e aktar"
                />

                <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>
              </div>
            </div>
          </div>
        </div>
        <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ ...this.state, tabIndex })}>
          <TabList>
            <Tab><b>Rezervasyon Yapan Yolcular</b></Tab>
            <Tab><b>Aktif Yolcular</b></Tab>
            <Tab><b>Pasif Yolcular</b></Tab>
          </TabList>

          <TabPanel>
            <table id="emp-reserved" className="excel-table">
              <tbody>
                <tr>
                  <td><b>Servis Tarihi</b></td>
                  <td>{this.props.serviceRow.SERVICEDATE}</td>
                </tr>
                <tr>
                  <td><b>Servis Adı</b></td>
                  <td>{this.props.serviceRow.SERVICENAME}</td>
                </tr>
                <tr>
                  <td><b>Sürücü Adı</b></td>
                  <td>{this.props.serviceRow.BUSDRIVER}</td>
                </tr>
                <tr>
                  <td><b>Araç Plaka</b></td>
                  <td>{this.props.serviceRow.BUSPLATE}</td>
                </tr>
                <tr>
                  <td><b>Sürücü Telefon</b></td>
                  <td>{this.props.serviceRow.DRIVERPHONE}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Ad / Soyad</b></td>
                  <td><b>Sicil No</b></td>
                  <td><b>E-Posta</b></td>
                  <td><b>Telefon</b></td>
                </tr>
                {
                  this.state.passengers.map((p, index) => {
                    return <tr key={index}>
                      <td >{p.DISPLAYNAME}</td>
                      <td >{p.IDNO}</td>
                      <td >{p.EMAIL}</td>
                      <td >{p.PHONENUMBER}</td>
                    </tr>
                  })
                }

              </tbody>
            </table>

            {
              this.state.loading ? <LoadingIndicator /> :
                this.state.passengers.length > 0 ? (
                  <BootstrapTable data={this.state.passengers} containerClass='passengers-list' striped hover options={options} pagination>
                    <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='DISPLAYNAME' dataSort={true}>Ad / Soyad</TableHeaderColumn>
                    <TableHeaderColumn dataField='IDNO' dataSort={true}>Sicil No</TableHeaderColumn>
                    <TableHeaderColumn dataField='EMAIL' dataSort={true}>E-Posta</TableHeaderColumn>
                    <TableHeaderColumn dataField='PHONENUMBER' dataSort={true}>Telefon Numarası</TableHeaderColumn>
                  </BootstrapTable>
                ) :
                  <p>Gösterilecek kayıt yok.</p>
            }



          </TabPanel>
          <TabPanel>
            <table id="emp-all" className="excel-table">
              <tbody>
                <tr>
                  <td><b>Servis Tarihi</b></td>
                  <td>{this.props.serviceRow.SERVICEDATE}</td>
                </tr>
                <tr>
                  <td><b>Servis Adı</b></td>
                  <td>{this.props.serviceRow.SERVICENAME}</td>
                </tr>
                <tr>
                  <td><b>Sürücü Adı</b></td>
                  <td>{this.props.serviceRow.BUSDRIVER}</td>
                </tr>
                <tr>
                  <td><b>Araç Plaka</b></td>
                  <td>{this.props.serviceRow.BUSPLATE}</td>
                </tr>
                <tr>
                  <td><b>Sürücü Telefon</b></td>
                  <td>{this.props.serviceRow.DRIVERPHONE}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Ad / Soyad</b></td>
                  <td><b>Sicil No</b></td>
                  <td><b>E-Posta</b></td>
                </tr>
                {
                  this.state.passengersAll.filter(x => x.ISACTIVE === 1).map((p, index) => {
                    return <tr key={index}>
                      <td >{p.DISPLAYNAME}</td>
                      <td >{p.IDNO}</td>
                      <td >{p.EMAIL}</td>
                    </tr>
                  })
                }

              </tbody>
            </table>
            {
              this.state.loading ? <LoadingIndicator /> :
                this.state.passengersAll.filter(x => x.ISACTIVE === 1).length > 0 ? (
                  <BootstrapTable data={this.state.passengersAll.filter(x => x.ISACTIVE === 1)} containerClass='passengers-list' striped hover options={options} pagination>
                    <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='DISPLAYNAME' dataSort={true}>Ad / Soyad</TableHeaderColumn>
                    <TableHeaderColumn dataField='IDNO' dataSort={true}>Sicil No</TableHeaderColumn>
                    <TableHeaderColumn dataField='EMAIL' dataSort={true}>E-Posta</TableHeaderColumn>
                    <TableHeaderColumn dataField='PHONENUMBER' dataSort={true}>Telefon Numarası</TableHeaderColumn>
                  </BootstrapTable>
                ) :
                  <p>Gösterilecek kayıt yok.</p>
            }

          </TabPanel>
          <TabPanel>
            <table id="emp-all" className="excel-table">
              <tbody>
                <tr>
                  <td><b>Servis Tarihi</b></td>
                  <td>{this.props.serviceRow.SERVICEDATE}</td>
                </tr>
                <tr>
                  <td><b>Servis Adı</b></td>
                  <td>{this.props.serviceRow.SERVICENAME}</td>
                </tr>
                <tr>
                  <td><b>Sürücü Adı</b></td>
                  <td>{this.props.serviceRow.BUSDRIVER}</td>
                </tr>
                <tr>
                  <td><b>Araç Plaka</b></td>
                  <td>{this.props.serviceRow.BUSPLATE}</td>
                </tr>
                <tr>
                  <td><b>Sürücü Telefon</b></td>
                  <td>{this.props.serviceRow.DRIVERPHONE}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td><b>Ad / Soyad</b></td>
                  <td><b>Sicil No</b></td>
                  <td><b>E-Posta</b></td>
                </tr>
                {
                  this.state.passengersAll.filter(x => x.ISACTIVE === 0).map((p, index) => {
                    return <tr key={index}>
                      <td >{p.DISPLAYNAME}</td>
                      <td >{p.IDNO}</td>
                      <td >{p.EMAIL}</td>
                    </tr>
                  })
                }

              </tbody>
            </table>
            {
              this.state.loading ? <LoadingIndicator /> :
                this.state.passengersAll.filter(x => x.ISACTIVE === 0).length > 0 ? (
                  <BootstrapTable data={this.state.passengersAll.filter(x => x.ISACTIVE === 0)} containerClass='passengers-list' striped hover options={options} pagination>
                    <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='DISPLAYNAME' dataSort={true}>Ad / Soyad</TableHeaderColumn>
                    <TableHeaderColumn dataField='IDNO' dataSort={true}>Sicil No</TableHeaderColumn>
                    <TableHeaderColumn dataField='EMAIL' dataSort={true}>E-Posta</TableHeaderColumn>
                    <TableHeaderColumn dataField='PHONENUMBER' dataSort={true}>Telefon Numarası</TableHeaderColumn>
                  </BootstrapTable>
                ) :
                  <p>Gösterilecek kayıt yok.</p>
            }

          </TabPanel>          
        </Tabs>
        {
          // this.state.loading ? <LoadingIndicator /> :
          // <p>Gösterilecek kayıt yok.</p>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    passengers: state.passenger.passengers,
    currentServiceID: state.passenger.currentServiceID,
    currentServiceName: state.passenger.currentServiceName,
    serviceRow: state.passenger.serviceRow
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload }),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading }),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT }),
    setPassenger: (passengers) => dispatch({ type: PassengerActionTypes.SET_PASSENGERS, passengers: passengers })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServicePassengers));