import React from 'react';
import LoginLogo from '../../components/login-logo/login-logo.component';
import LoginWelcome from '../../components/login-welcome/login-welcome.component';
import LoginCopyright from '../../components/login-copyright/login-copyright.component';
import LoginForm from '../../components/login-form/login-form.component';

import './loginpage.styles.scss';

const LoginPage = () => (
      <div className="overlayID">
            <LoginLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
            <LoginWelcome welcomeText="Lütfen kullanıcı bilgilerinizi giriniz." />
            <LoginForm />
            <LoginCopyright copyrightText='Tüm hakları saklıdır' copyright='DocPlant' />   
      </div>
);

export default LoginPage;