import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import { showWarningLayer, customShowLoadingLayer, customHideLoadingLayer } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import axios from 'axios';

import './driver-passengers.styles.scss';
import 'react-tabs/style/react-tabs.css';


class DriverPassengers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      passengers: [],
      passengersAll: [],
      selectedPassengerID: null,
      tabIndex:0
    }

  }

  componentDidMount(){
    this.getPassengersAll();
  }
  
  //...
  handleChangeUserRoute = async (selectedOption) => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
    //console.log(selectedOption.value);
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-userotherroutes`,{ "sid": this.props.user.SessionID, "uid": this.state.selectedUserID,"rid": selectedOption.value},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let routes;
      try{
        routes = res.data.data;
        if (routes === null || routes === undefined){
          routes = [];
        }
      }catch(ex){
        routes = [];
      }
      
      //console.log(JSON.stringify(routes));
      //console.log(services);
      this.setState({...this.state, loading:false, activeRoutes: routes,activeRouteDisable:false, selectedOption });
    } 

    // this.setState(
    //   {
    //     ...this.state,
    //     activeRouteDisable:false,
    //     selectedOption 
    //   }
    // );

    
  };


  handleChangeActiveRoute = selectedOptionActiveRoute => {
    this.setState(
      { ...this.state, selectedOptionActiveRoute }
    );
  };

  getPassengersAll = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-passengers-all`,{ "sid": this.props.user.SessionID,"srid":this.props.location.state.SERVICEIDX},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let passengers;
        let passengersAll;
        try{
          passengers = res.data.data;
          if (passengers === null || passengers === undefined){
            passengers = [];
          }
        }catch(ex){
          passengers = [];
        }

        try{
          passengersAll = res.data.data2;
          if (passengersAll === null || passengersAll === undefined){
            passengersAll = [];
          }
        }catch(ex){
          passengersAll = [];
        }
        
        this.setState({...this.state, passengers: passengers, passengersAll: passengersAll, loading:false });
      }     
    })
  }

  handleSwitchChange = async (checked,serviceid,passengerid) => {
    customShowLoadingLayer();
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/";
    if (checked){
      dataUrl+="passenger-service-in";
    }else{
      dataUrl+="passenger-service-in-cancel";
    }
    
    const response = await axios.post(dataUrl,{ "pid": passengerid, "sid": this.props.user.SessionID, "serid": serviceid},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        //this.props.setLoading(false);
        customHideLoadingLayer(); 
      }else{
        let warnmessage = "";
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();
        customHideLoadingLayer(); 
        //this.props.setLoading(false);
        this.forceUpdate();
      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      //this.props.setLoading(false);
      this.forceUpdate();
    }

  }  

  buttonDetail = (cell, row) => {
    return (

      <BootstrapSwitchButton 
        checked={row.ACTIONSTAT === "3" ? true : false}
        onlabel={row.SERVICESTAT === "1" || row.SERVICESTAT === "2" ? "Geldi" : "Kapalı"}
        offlabel={row.SERVICESTAT === "1" || row.SERVICESTAT === "2" ? "Gelmedi" : "Kapalı"}
        onstyle={row.SERVICESTAT === "1" || row.SERVICESTAT === "2" ? "success" : "light"} 
        offstyle={row.SERVICESTAT === "1" || row.SERVICESTAT === "2" ? "danger" : "light"}
        disabled={row.SERVICESTAT === "1" || row.SERVICESTAT === "2" ? false : true}
        onChange={(checked) => this.handleSwitchChange(checked,row.SERVICEID,row.PASSENGERID)}
      />        
    )
  }  


  goBack = () => {
    this.props.history.push('/servisler');
  }

  render(){
    //console.log(this.state.activeServices);
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 300,
      noDataText: 'Gösterilecek kayıt yok'
    };    

    return(
      <div>
      <div className="card card-body bg-light content-toolbar">
        <div className="d-flex">
            <div className="d-flex p-2">
              <h5 className="toolbarTitle">{this.props.location.state.SERVICEDATE} {this.props.location.state.SERVICENAME}</h5>
            </div>
            <div className="ml-auto p-2">
                <div className="d-flex">
                  <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>                
                </div>
            </div>
        </div>   
      </div>       
      <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({...this.state, tabIndex })}>
      <TabList>
        <Tab><b>Rezervasyon Yapan Yolcular</b></Tab>
        <Tab><b>Tüm Yolcular</b></Tab>
      </TabList>
  
      <TabPanel>
      {
        this.state.loading ? <LoadingIndicator /> :
        this.state.passengers.length > 0 ? (
          <BootstrapTable data={ this.state.passengers } containerClass='passengers-list' striped hover options={options} pagination>
            <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
            <TableHeaderColumn dataField='DISPLAYNAME' dataSort={ true }>Ad / Soyad</TableHeaderColumn>
            <TableHeaderColumn dataField='IDNO' dataSort={ true }>Sicil No</TableHeaderColumn>
            <TableHeaderColumn dataField="button" width='170px' tdStyle={ { padding: '5px' } } dataFormat={this.buttonDetail} dataSort={ true }></TableHeaderColumn>
            
          </BootstrapTable>          
        ) : 
        <p>Gösterilecek kayıt yok.</p>
      }



      </TabPanel>
      <TabPanel>
      {
        this.state.loading ? <LoadingIndicator /> :
        this.state.passengersAll.length > 0 ? (
          <BootstrapTable data={ this.state.passengersAll } containerClass='passengers-list' striped hover options={options} pagination>
            <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
            <TableHeaderColumn dataField='DISPLAYNAME' dataSort={ true }>Ad / Soyad</TableHeaderColumn>
            <TableHeaderColumn dataField='IDNO' dataSort={ true }>Sicil No</TableHeaderColumn>
          </BootstrapTable>          
        ) : 
        <p>Gösterilecek kayıt yok.</p>
      }

      </TabPanel>
    </Tabs>      
      {
        // this.state.loading ? <LoadingIndicator /> :
        // <p>Gösterilecek kayıt yok.</p>
      }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    passengers: state.passenger.passengers,
    currentServiceID: state.passenger.currentServiceID,
    currentServiceName: state.passenger.currentServiceName,
    serviceRow: state.passenger.serviceRow
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setPassenger: (passengers) => dispatch({ type: PassengerActionTypes.SET_PASSENGERS, passengers: passengers})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(DriverPassengers));