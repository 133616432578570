import React from 'react';

import './main-logo.styles.scss';

const MainLogo = ({logoName,appName}) => (
  <div className="mainLogoWrapperID">
      <img className="mainLogoID" src={require(`../../assets/images/${logoName}`)} alt={appName} />
  </div> 
);

export default MainLogo;