import React from 'react';

import './main-copyright.styles.scss';


const MainCopyright = ({companyName = ''}) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return(
    <div className="bottomLogoWrapper">
    <p className="mainCopyright">
      &copy;{currentYear} {companyName}. Tüm hakları saklıdır.
    </p>
  </div>
  )
}


export default MainCopyright;