import React,{ forwardRef } from 'react';

import './login-input.styles.scss';

const LoginInput = ({handleChange, label, ...otherProps},ref) => (
  <div className="elementWrapper">
    <input className="form-control edit" ref={ref}  type={!otherProps.type ? 'text' : otherProps.type } onChange={handleChange} {...otherProps} />
    {
      otherProps.warning === "true" &&
      <div className="loginError"></div>
    }
    
  </div>
);

export default forwardRef(LoginInput);