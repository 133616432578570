import React from 'react';
import ReactDOM from 'react-dom';
//import { useHistory } from 'react-router-dom
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginInput from '../login-input/login-input.component';
import LoginRememberLostPassword  from '../login-remember-lostpassword/login-remember-lost-password.component';
import Spacer from '../spacer/spacer.component';
import CustomButton from '../custom-button/custom-button.component';
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import { showWarningLayer, validateEmail } from '../../utils/utils';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import * as actionCreators from '../../redux/login/login.actions';
import axios from 'axios';
import './login-form.styles.scss';


//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action , editaction) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action} editaction={editaction}>
        <p className="text-center passwordInfo">{text}</p>
        <input type="input" className="editEmail form-control edit" placeholder="E-Posta adresiniz." onChange={(event) => editaction(event.target.value)} />
      </JqueryConfirm>
   , document.getElementById('jqueryPopupContainer'));  
}

class LoginForm extends React.Component{
  constructor(){
    super();
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();

    this.state = {
      resetEmail: null
    }
  }

  handleEmailChange = (value) => {
    this.setState({
      ...this.state,
      resetEmail:value
    });
  
  }

  resetPassword = () => {
    const resetEmail = this.state.resetEmail;
    if (resetEmail !== null && validateEmail(resetEmail)){
      this.props.setLoading(true);
      axios.post(`https://azr04.infotraq.com/servisradarsu/forget-password`,{ "email": resetEmail },{crossDomain: true})
      .then(res => {
        if (res.data.type === "error"){
          if (res.data.message === "DP_Session"){
            this.props.logOut();
            this.props.setDefault();
            const bodyElement = document.querySelector('body');
            bodyElement.classList.remove('noBodyImage');          
          }else if (res.data.message === "DP_Email"){
            this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta adresini kontrol edin.'});
            showWarningLayer();
            this.props.setLoading(false);
          }else{
            this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
            showWarningLayer();
            this.props.setLoading(false);
          }
        } else{
          if (res.data.message === "DP_Done"){
            this.props.onWarning({warningMessageType:'done', warningMessageText: 'Şifre resetleme linki e-posta adresinize gönderildi.'});
            showWarningLayer();
            this.props.setLoading(false);
          }else{
            this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
            showWarningLayer();
            this.props.setLoading(false);
          }
        }     
      })    
  

    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta adresini kontrol edin.'});
      showWarningLayer();
      return false;
    }
  }

  handleLostPassword = (event, toggle) => {
    confirmDialog(true,'resetpassword','Lütfen yeni şifre için kayıtlı e-posta adresinizi giriniz.',() => this.resetPassword(),this.handleEmailChange)();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.props.setLoading(true);
    this.props.setWarning({warningName: 'usernameWarning', warningStatus: false});
    this.props.setWarning({warningName: 'passwordWarning', warningStatus: false});

    //const recaptchaValue = recaptchaRef.current.getValue();

    if (this.props.username === null || this.props.username === ''){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin.'});
      showWarningLayer();
      this.props.setWarning({warningName: 'usernameWarning', warningStatus: true});
      this.usernameRef.current.focus();
      this.props.setLoading(false);
      return;
    }

    if (this.props.password === null || this.props.password === ''){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanını kontrol edin.'});
      showWarningLayer();
      this.props.setWarning({warningName: 'passwordWarning', warningStatus: true}); 
      this.passwordRef.current.focus();
      this.props.setLoading(false);
      return;
    }
    
    // if (!recaptchaValue){
    //   this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen güvenlik doğrulamasını yapınız.'});
    //   showWarningLayer();
    //   // this.props.setWarning({warningName: 'usernameWarning', warningStatus: true});
    //   // this.usernameRef.current.focus();
    //   this.props.setLoading(false);
    //   return;
    // }

    this.props.onUserSignIn(this.props.username,this.props.password).then(() => {
      if (this.props.isLoggedIn){
        if (this.props.user.UserRoles.includes("SUADMIN")){
          this.props.history.push('/hosgeldiniz');
        }else if (this.props.user.UserRoles.includes("SUMANAGER")){
          this.props.history.push('/raporlar');
        }else{
          this.props.history.push('/servis-bilgisi');
        }
        this.props.setLoginStart(false);
        return;
      }else{
        if (this.props.user !== null){
          if (this.props.user.data.type === "success"){
            this.props.onWarning({warningMessageType:'done', warningMessageText: 'Giriş yapıldı. Yönlendiriliyorsunuz.'});
            showWarningLayer();
            this.props.setWarning({warningName: 'usernameWarning', warningStatus: true});
            this.props.setLoading(true);
          }else{
            this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen takrar deneyiniz.'});
            showWarningLayer();
            this.props.setWarning({warningName: 'usernameWarning', warningStatus: true});
            this.props.setLoading(false);
          }          
        }else{
             
          if (this.props.error !== null){
            if (this.props.error.data.message === "DP_UserPass"){
              this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adı veya şifrenizi kontrol edin.'});
              showWarningLayer();
              this.props.setWarning({warningName: 'usernameWarning', warningStatus: true});
              this.usernameRef.current.focus();
              this.props.setLoading(false);
            }else if (this.props.error.data.message === "DP_Captcha"){
              this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen güvenlik doğrulamasını işaretleyiniz.'});
              showWarningLayer();
              this.props.setWarning({warningName: 'usernameWarning', warningStatus: true});
              this.usernameRef.current.focus();
              this.props.setLoading(false);
            }else{
              this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen takrar deneyiniz.'});
              showWarningLayer();
              this.props.setWarning({warningName: 'usernameWarning', warningStatus: true});
              this.props.setLoading(false);
            }
          }else{
            if (this.props.generalError === "network"){
              this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen takrar deneyiniz.'});
              showWarningLayer();
              return;
            }              
          }
        }
      }
    });
  }

  render(){
    //const { projects, selectedOption, rememberme } = this.state;
    return(
      <form className="loginForm" method="post" onSubmit={this.handleSubmit}>
          <div className="loginFormElements">
          {
            /* 
            <Select
              value={this.props.selectedOption}
              onChange={this.props.onHandleSelect}
              options={this.props.projects}
              placeholder="Please select a project name"
              className="select"
              ref={this.projectnameRef}
              //warning={this.props.projectWarning}
            />
            
            */
          }
            <LoginInput
              autoFocus
              name="username"
              placeholder="Kullanıcı adı"
              type="text"
              onChange={this.props.onHandleChange}
              ref={this.usernameRef}
              warning={this.props.usernameWarning.toString()}
            />
            <LoginInput
              name="password"
              placeholder="Şifre"
              type="password"
              onChange={this.props.onHandleChange}
              ref={this.passwordRef}
              warning={this.props.passwordWarning.toString()}
            />   
            <LoginRememberLostPassword
              remembermeText="Beni hatırla"
              lostPasswordText="Şifremi unuttum"
              defaultChecked={this.props.rememberme}
              handleChecked={this.props.onHandleChecked}
              handleLostPassword={this.handleLostPassword}
            />
            <Spacer />
            {
              /*
              <div className="g-recaptcha-wrapper">
                <ReCAPTCHA className="g-recaptcha" ref={recaptchaRef} sitekey={googleCaptchaKey}  />
              </div>
              */
            }
            <CustomButton type='submit'>Giriş</CustomButton>
          </div>
          
      </form>    
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    isLoading: state.login.isLoading,
    rememberme: state.login.rememberme,
    selectedOption: state.login.selectedOption,
    projectname: state.login.projectname,
    username: state.login.username,
    password: state.login.password,
    usernameWarning: state.login.usernameWarning,
    passwordWarning: state.login.passwordWarning,
    projectWarning: state.login.projectWarning,
    projects: state.login.projects,
    user: state.login.User,
    error: state.login.error,
    generalError: state.login.generalError,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType

  };
}

const mapDispatchToProps = dispatch => {
  return {
    onHandleChange: (event) => dispatch({ type: LoginActionTypes.LOGIN_INPUT_CHANGE, event: event}),
    onHandleSelect: (selectedOption) => dispatch({ type: LoginActionTypes.LOGIN_SELECT_CHANGE, selectedOption: selectedOption}),
    onHandleChecked: (event) => dispatch({ type: LoginActionTypes.LOGIN_REMEMBER_CHECKED, event: event}),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setWarning: (payload) => dispatch({ type: LoginActionTypes.SET_LOGIN_WARNING, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    onUserSignIn: (projectname,username,password) => dispatch(actionCreators.userSignIn(projectname, username, password)),
    setLoginStart: (payload) => dispatch({ type: LoginActionTypes.LOGIN_START, payload: payload}),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));