import React from 'react';

import './loading-indicator.styles.scss';

const LoadingIndicator = () => (
  <div className="lds-grid-wrapper">
    <div className="cssload-whirlpool"></div>
  </div>
);

export default LoadingIndicator;
