import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PopupModal from '../../components/popup-modal/popup-modal.component';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import DatePicker from "react-datepicker";
import tr from 'date-fns/locale/tr';
import { showWarningLayer, customShowLoadingLayer, customHideLoadingLayer,showWarningLayerPopup,parseDate,formatDate, hideWarningLayerPopup } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import axios from 'axios';

import './active-services-admin.styles.scss';
//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action}>
        <p className="text-center passwordInfo"><b>{text}</b></p>
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}

class ActiveServicesAdmin extends React.Component {
  constructor(props){
    super(props);

    this.baslangictarih = "baslangictarih_"+this.generateID();
    this.bitistarih = "bitistarih_"+this.generateID();    
    if (props.activeUserID !== null && props.activeUserID !== undefined){
      //console.log(props.activeUserID);
    }else{
      
    }

    this.state = {
      loading: false,
      loadingPopup:false,
      firstLoading:false,
      activeServices: [],
      selectServices:[],
      selectedServiceID: null,
      activeDisplayName: this.props.activeDisplayName,
      activeUserID: this.props.activeUserID,
      modalShowNewService: false,
      selectedServiceData:null,
      userRotes:[],
      userAddress:[],
      filters:[],
      selectedOption: null,
      selectedOptionAddress: null,
      selectedOptionSaat: null,
      selectedOptionService:null,
      disableSaat:true,
      disableServis:true,
      baslangicTarih:null,
      bitisTarih:null



    }

  }

  componentDidMount(){
    this.getServices();
  }

  getServices = () => {
    this.props.setLoading(true);
    this.setState({
      ...this.state,
      loading:true
    });

    let basdate = this.state.baslangicTarih === null ? "" : this.state.baslangicTarih;
    let bitdate = this.state.bitisTarih === null ? "" : this.state.bitisTarih;    

    if (basdate !== ""){
      basdate = formatDate(Date.parse(basdate));
      console.log(basdate);
    }

    if (bitdate !== ""){
      bitdate = formatDate(Date.parse(bitdate));
      console.log(bitdate);
      
    }

    
    

    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services`,{ "uid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID,"basdate":basdate , "bitdate":bitdate },{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        
        this.setState({...this.state, loading:false,firstLoading:false, activeServices: services });
        this.props.setLoading(false);
        customHideLoadingLayer();
        this.getServiceDates();
      }     
    })
  }

  getActiveServices = (srdate,srtime) => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
   
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-active-services`,{ "srdate": srdate, "srtime":srtime, "sid": this.props.user.SessionID },{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        
        this.setState({...this.state, loading:false,disableServis:false, selectServices: services });
        this.props.setLoadingPopup(false);
      }     
    })
  }


 
  getServiceDates = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    this.props.setLoading(true);
    

    // if (basdate !== ""){
    //   basdate = new Date(this.state.baslangicTarih);
    //   basdate = new Intl.DateTimeFormat('en-US').format(basdate);
    //   basdate = parseDate(basdate);
    // }

    // if (bitdate !== ""){
    //   bitdate = new Date(this.state.bitisTarih);
    //   bitdate = new Intl.DateTimeFormat('en-US').format(bitdate);
    //   bitdate = parseDate(bitdate);
    // }

    let getAllVal = 0;
    if (this.props.user.UserRoles.includes("SUADMIN")){
      getAllVal = 1;
    }
    //this.props.setLoadingPopup(true);
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services-passenger-dates`,{ "sid": this.props.user.SessionID,"GETALL":getAllVal },{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
        this.props.setLoading(false);
      } else{
        let filters;

        try{
          filters = res.data.data;
          if (filters === null || filters === undefined){
            filters = [];
          }          
        }catch(ex){
          filters = [];
        }
        
        this.setState({...this.state, filters: filters, loading:false });        
        this.props.setLoadingPopup(false);
        this.props.setLoading(false);
      }     
    })
  }

  getSaatFilters = (tarihValue) => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
    this.props.setLoadingPopup(true);  
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-activeservice-times`,{ "sid": this.props.user.SessionID, "sdate": tarihValue},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let filtersSaat;
        try{
          filtersSaat = res.data.data;
          if (filtersSaat === null || filtersSaat === undefined){
            filtersSaat = [];
          }          
        }catch(ex){
          filtersSaat = [];
        }
        //filtersSaat.unshift({"value":'',"label":"Hepsi"});
        this.setState(
          {
            ...this.state,
            filtersSaat: filtersSaat,
            disableSaat:false,
            loading:false
          }
        );
        this.props.setLoadingPopup(false);
        
        //this.props.setPassengersList([]);


      }     
    })
  }  

  getUserAddress = async (userid) => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-passenger-address-select`,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let address;
      try{
        address = res.data.data;
        if (address === null || address === undefined){
          address = [];
        }
      }catch(ex){
        address = [];
      }
      
      //console.log(JSON.stringify(routes));
      //console.log(services);
      this.setState({...this.state, userAddress: address });
    } 
  }   


  handleNewService = () => {
    if (this.state.pagedata !== null){
      this.props.setLoadingPopup(true);
      //this.getServiceDates();
      this.getUserAddress(this.state.activeUserID); 
      this.setState({...this.state, modalShowNewService:true,selectedOptionSaat:null, selectedOptionService:null, filtersSaat:[], selectServices:[],disableService:true, disableSaat:true });    
    }
  }  

  handleFilterChange = (selectedOption ) => {
    this.props.setLoadingPopup(true);
    this.setState({...this.state, selectedOption: selectedOption, selectedOptionSaat:null, selectedOptionService:null, filtersSaat:[], selectServices:[],disableService:true }); 
    this.getSaatFilters(selectedOption.value);
  }  

  handleSaatChange = (selectedOption) => {
    this.props.setLoadingPopup(true);
    try{
      this.getActiveServices(this.state.selectedOption.value, selectedOption.value);
       this.setState({...this.state, selectedOptionSaat: selectedOption });      
      
      } catch(ex){
        this.props.setLoadingPopup(false);
      }
  }    

  handleServiceChange = (selectedOption) => {
    this.setState({...this.state, selectedOptionService: selectedOption }); 
  }

  handleChangeAddress = selectedOptionAddress => {
    this.setState(
      { ...this.state, selectedOptionAddress }
    );
  };

  goBack = () => {
    this.props.history.push('/yolcu-islemleri',this.props.location.state);
  }    

  generateID = () => {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16); //eslint-disable-line
    });
    return uuid;
  }  


  insertService = async () => {
    this.props.setLoadingPopup(true);
    let busid = this.state.selectedOptionService.busid;
    let serviceid = this.state.selectedOptionService.serviceid;
    let padrid = this.state.selectedOptionAddress.value;
    let passengerid = this.state.activeUserID;

    if (serviceid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen bir servis seçin.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (padrid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen bir adres seçin.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }    

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-service`,
      {
        "sid": this.props.user.SessionID,
        "busid": busid,
        "serviceid":serviceid,
        "padrid":padrid,
        "passengerid":passengerid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Serviceid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen bir servis seçin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Padrid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen bir adres seçin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Servis eklendi.'});
        showWarningLayer();
        this.props.setLoading(true);
        this.getServices();
        this.setState({...this.state,modalShowNewService:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }

  handleDeleteService = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen bir servis seçiniz.'});
      showWarningLayerPopup();
      return;      
    }    

    //

    let passengerid = this.state.activeUserID;
    let serviceid = this.state.selectedServiceData.SERVICEID;
  

    confirmDialog(true,'changepassword','Servisi silmek istediğinizden emin misiniz ?',() => this.deleteService(passengerid,serviceid))();  

  }

  deleteService = async (passengerid,serviceid) => {
    this.props.setLoading(true);

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/delete-service`,
      {
        "sid": this.props.user.SessionID,
        "passengerid":passengerid,
        "serviceid":serviceid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Serviceid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen bir servis seçin.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Servis silindi.'});
        this.getServices();
        showWarningLayer();
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }


  handleSwitchChange = async (checked,serviceid) => {
    //this.props.setLoading(true);
    customShowLoadingLayer();
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/";
    if (checked){
      dataUrl+="make-reservation";
    }else{
      dataUrl+="remove-reservation";
    }
    //return true;
    const response = await axios.post(dataUrl,{ "uid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID, "serid": serviceid},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        // let warningMessageText = "";
        // if (checked){
        //   warningMessageText = "Rezervasyon yapıldı.";
        // }else{
        //   warningMessageText = "Rezervasyon iptal edildi.";
        // }
        //this.props.onWarning({warningMessageType:'done', warningMessageText: warningMessageText});
        //showWarningLayer();
        //this.props.setLoading(false);
        //this.forceUpdate();
        this.getServices();
      }else{
        let warnmessage = "";
        if (response.data.message === "DP_Limit") {
          warnmessage = "Seçmiş olduğunuz servisin kapatesi dolmuştur. Lütfen başka bir gün ve saat seçiniz.";
        }else{
          warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        }
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();
        this.props.setLoading(false);
        customHideLoadingLayer();
        //this.forceUpdate();
      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      this.props.setLoading(false);
      customHideLoadingLayer();
      this.forceUpdate();
    }
  }

  buttonDetail = (cell, row) => {
    return (
      <button type="button" className="btn btn-warning btn-rota-detay" onClick={() => {this.props.setCurrentService(row); this.props.history.push('/raporlar-yolcu-listesi',this.state); }}>Yolcu Listesi</button>
    )
  }

  routeType = (cell, row) => {
    return cell === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"
  }  
  
  //Column Templates
  suColumnType = (cell, row, type) => {
    let isDisable = row.SERVICESTAT > 2 ? true : false;

    if (this.props.user.UserRoles.includes("SUADMIN")){
      isDisable = false;
    }
    if (type === "routetype"){
      return cell === "1" ? '<span class="badge badge-primary columnInfo">Kampüse Gidiş</span>' : '<span class="badge badge-warning columnInfo">Kampüsten Dönüş</span>'
    }else if(type === "actionstat"){
      return(
        cell === "1" ? 'Rezervasyon Yapıldı' :
        cell === "2" ? 'Rezervasyon İptal Edildi' :
        cell > 2 ? 'Kullanıcı Geldi' : 'Kullanıcı Gelmiyor'

      )
    }else if(type === "button"){
      return(
        <BootstrapSwitchButton 
        checked={row.ACTIONSTAT === "1" || row.ACTIONSTAT > 2 ? true : false}
        onlabel="Geliyor"
        offlabel="Gelmiyor"
        onstyle="success"
        offstyle={"danger"} 
        width="130" 
        disabled={isDisable}
        onChange={(checked) => this.handleSwitchChange(checked,row.SERVICEID)}
      />             
      )
    }
  }   

  handleRowSelect = (row, isSelected, e) => {
    // console.log(row);
    // console.log(isSelected);
    console.log(e);
    this.setState(
      {
      ...this.state,
      selectedServiceData: row,
      isRowSelected: isSelected
      }
      );
  }    

  handleBaslangicTarihChange = (date) => {
    this.setState({
      ...this.state,
      baslangicTarih: date
    })
  }

  handleBitisTarihChange = (date) => {
    this.setState({
      ...this.state,
      bitisTarih: date
    })
  }


  render(){
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      noDataText: 'Gösterilecek kayıt yok'
    };    
    const selectRow = {
      mode: 'radio',  // multi select
      clickToSelect: true,
      className: 'row-select-class',
      bgColor: '#004288',
      Color:'white',
      onSelect: this.handleRowSelect      
    };   

    //console.log(this.state.activeServices);
    return(
      <div>
        <div className="card card-body bg-light content-toolbar">
        <div className="d-flex">
            <div className="d-flex p-2">
              <button type="button" className="btn btn-sm btn-general btn-light p-2 margin-left-5 userbuttons minibuttons" data-tip="Kullanıcı Servisi Ekle" onClick={() => this.handleNewService()}><i className="fas fa-plus"></i></button>
              <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Kullanıcı Servisi Sil" onClick={() => this.handleDeleteService()}><i className="fas fa-times"></i> </button>
              <div className="basdate-wrapper">
                <label htmlFor={this.baslangictarih} className="window-label basdate-label">Başlangıç :</label>
                <DatePicker 
                  id={this.baslangictarih}
                  name={this.bitistarih}
                  className="form-control edit max-width-element"
                  selected={this.state.baslangicTarih} 
                  onChange={this.handleBaslangicTarihChange} 
                  locale={tr}
                  placeholderText="Lütfen başlangıç tarihi giriniz."
                  dateFormat="yyyy-MM-dd"
                />                
              </div>
              <div>
                <label htmlFor={this.bitishtarih} className="window-label bitdate-label">Bitiş :</label>
                <DatePicker 
                  id={this.bitistarih}
                  name={this.bitistarih}
                  className="form-control edit max-width-element"
                  selected={this.state.bitisTarih} 
                  onChange={this.handleBitisTarihChange} 
                  locale={tr}
                  placeholderText="Lütfen başlangıç tarihi giriniz."
                  dateFormat="yyyy-MM-dd"
                />                          
              </div>
            </div>
            <div className="d-flex">
              <button type="button" className="btn btn-general btn-light margin-left-5 btnalonesearch" onClick={() => this.getServices()} ><i className="fas fa-search"></i> Filtrele</button>                
            </div>
            <div className="ml-auto p-2">
                <div className="d-flex">
                  <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>                
                </div>
            </div>
        </div>   
      </div>       
        <div className="servisTableWrapper adminServisList">
      {
        this.state.firstLoading ? <LoadingIndicator/> :
        this.state.activeServices.length > 0 ? (
          <div className="servisTable">
            <BootstrapTable data={ this.state.activeServices } container className='servis-list' hover options={options} selectRow={ selectRow }>
              <TableHeaderColumn dataField='SERVICEID' isKey={true} hidden>ID</TableHeaderColumn>
              <TableHeaderColumn dataField='SERVICENAME' width='450px' dataSort={ true }>Servis Adı</TableHeaderColumn>
              <TableHeaderColumn dataField='ROUTETYPE'  width='200px' dataAlign='center' headerAlign='center'  dataSort={ true } dataFormat={(cell,row) => this.suColumnType(cell,row,'routetype') } >Servis Yönü</TableHeaderColumn>
              <TableHeaderColumn dataField='ACTIONSTAT' width='150px' dataAlign='center' headerAlign='center' dataSort={ true } dataFormat={(cell,row) => this.suColumnType(cell,row,'actionstat') }>Durum</TableHeaderColumn>
              <TableHeaderColumn dataField='CAPACITY' width='100px' dataAlign='center' headerAlign='center' dataSort={ true }>Kapasite</TableHeaderColumn>
              <TableHeaderColumn dataField="button" width='150px' dataAlign='center' tdStyle={ { padding: '5px' } } dataFormat={(cell,row) => this.suColumnType(cell,row,'button') } dataSort={ true }></TableHeaderColumn>
            </BootstrapTable>               
          </div>
        ) : 
        <p>Gösterilecek servis yok.</p>   
     
      }
      </div>

      <PopupModal
        show={this.state.modalShowNewService}
        onHide={() => this.setState({...this.state,modalShowNewService:false})}
        titlemodal={`Yeni Servis - ${this.state.activeDisplayName}`}
        onClick={()=> this.insertService}>


        <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen servis bilgilerini giriniz.</p>
        <form className="address-form" name="service-form">
        <div className="form-row address-row">
          <div className="col-md-4 mb-4 margin-bottom-0">
            <label>Tarih :</label>
            <Select
              className="filterSelect"
              options={this.state.filters} 
              value={this.state.selectedOption}
              onChange={this.handleFilterChange}
              placeholder="Servis tarihi seçin.."
            />
          </div>
          <div className="col-md-4 mb-4 margin-bottom-0">
            <label>Saat :</label>
            <Select
              className="filterSelectYolcuRaporu"
              options={this.state.filtersSaat} 
              value={this.state.selectedOptionSaat}
              onChange={this.handleSaatChange}
              placeholder="Saat seçiniz.."
              isDisabled={this.state.disableSaat}
            />             
          </div>          
        </div>
        <div className="form-row address-row">
          <div className="col-md-12 mb-12">
            <label>Servis :</label>
            <Select
              placeholder="Servis seçiniz"
              value={this.state.selectedOptionService}
              onChange={this.handleServiceChange}
              options={this.state.selectServices}
              isDisabled={this.state.disableServis}
            />            
          </div>
        </div>
        <div className="form-row address-row">
          <div className="col-md-12 mb-12">
            <label>Adres :</label>
            <Select
              placeholder="Adres seçiniz"
              value={this.state.selectedOptionAddress}
              options={this.state.userAddress}
              onChange={this.handleChangeAddress}
            />            
          </div>
        </div>
      </form>


       
  
        <LoadingLayer showLoading={this.props.showLoadingPopup} />
        <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />

      </PopupModal>  
      <ReactTooltip />          
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    serviceRow: state.passenger.serviceRow
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setCurrentService: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setRoutes: (routes) => dispatch({ type: PassengerActionTypes.SET_ROUTES, routes: routes}),
    setAddress: (payload) => dispatch({ type: PassengerActionTypes.SET_ADDRESS_FORM, payload: payload})


  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ActiveServicesAdmin));