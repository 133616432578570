import React from 'react';

import './login-remember-lost-password.styles.scss';

const LoginRememberLostPassword = ({remembermeText,lostPasswordText,defaultChecked,handleChecked,handleLostPassword}) => (
  <div className="elementWrapper">
    <div className="loginAdditionalWrapper">
        <div className="float-left">
          <label className="form-check-label" htmlFor="rememberme">
            <input type="checkbox" id="rememberme" className="rememberme form-check-input" name="rememberme"
               defaultChecked={defaultChecked} onChange={handleChecked}  /> {remembermeText}
          </label>
        </div>
        <div className="float-right">
          <button type="button" className="buttonDefault lostPassword" onClick={handleLostPassword}>{lostPasswordText}</button>
        </div>
    </div>  
    <div id="jqueryconfirm"></div>    
  </div>

);

export default LoginRememberLostPassword;