import React from 'react';
import { connect } from 'react-redux';
import { defaultPages } from '../../utils/utils';
import { LoginActionTypes } from '../../redux/login/login.types';
import { withRouter } from 'react-router-dom';
import './nav-menu.styles.scss';


class NavMenu extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      servisbilgisi: 'servisbilgisi',
      servisler: 'servisler',
      yolcuislemleri: 'yolcuislemleri',
      servisislemleri: 'servisislemleri',
      raporlar: 'raporlar',
      tanimlamalar: 'tanimlamalar',
      aractanimlari: 'aractanimlari',
      surucutanimlari: 'surucutanimlari',
      kullanicitanimlari: 'kullanicitanimlari',
      guzergahtanimlari: 'guzergahtanimlari',
      zamanlamatanimlari: 'zamanlamatanimlari',
      "geri": 'geri'
    }
  }

  componentDidMount(){
    if (this.props.currentPage === 'servisler'){
      this.setState(
        {...this.state,
         servisler: 'servisler-on'
        }
      );
    }else if (this.props.currentPage === 'servis-bilgisi'){
      this.setState(
        {...this.state,
         servisbilgisi: 'servisbilgisi-on'
        }
      );
    }else if (this.props.currentPage === 'yolcu-islemleri'){
      this.setState(
        {...this.state,
         yolcuislemleri: 'yolcuislemleri-on'
        }
      );
    }else if (this.props.currentPage === 'servis-islemleri'){
      this.setState(
        {...this.state,
          servisislemleri: 'servisislemleri-on'
        }
      );
    }else if (this.props.currentPage === 'raporlar'){
      this.setState(
        {...this.state,
         raporlar: 'raporlar-on'
        }
      );
    }else if (this.props.currentPage === 'tanimlamalar'){
      this.setState(
        {...this.state,
         tanimlamalar: 'tanimlamalar-on'
        }
      );
    }else if (this.props.currentPage === 'aractanimlari'){
      this.setState(
        {...this.state,
         aractanimlari: 'aractanimlari-on'
        }
      );
    }else if (this.props.currentPage === 'surucutanimlari'){
      this.setState(
        {...this.state,
         surucutanimlari: 'surucutanimlari-on'
        }
      );
    }else if (this.props.currentPage === 'kullanicitanimlari'){
      this.setState(
        {...this.state,
         kullanicitanimlari: 'kullanicitanimlari-on'
        }
      );
    }else if (this.props.currentPage === 'guzergahtanimlari'){
      this.setState(
        {...this.state,
         guzergahtanimlari: 'guzergahtanimlari-on'
        }
      );
    }else if (this.props.currentPage === 'zamanlamatanimlari'){
      this.setState(
        {...this.state,
         zamanlamatanimlari: 'zamanlamatanimlari-on'
        }
      );
    }
  }

  goTo = (event,url) => {
    event.preventDefault();
    //this.props.setLoading(true);
    return this.props.history.push('/'+url);    
  }

  onButtonMouseOver = (event,image) => {
    //const currentPagePrefix = this.props.currentPage.replace('-','');
    this.setState(
      {
        ...this.state,
        [image]: image + '-on'
    })
  }

  onButtonMouseOut = (event,image) => {
    let currentPagePrefix = "";
    try{
      currentPagePrefix = this.props.currentPage.replace('-','');
    }catch(ex){
      currentPagePrefix = this.props.currentPage;
    }
    
    this.setState({
      ...this.state,
      //[currentPagePrefix]: image
      [image]: currentPagePrefix === image ? currentPagePrefix+'-on' : image
    })
  }


  render(){
    const currentPage = this.props.currentPage;
    const parentPage = this.props.parentPage === null ? "" : this.props.parentPage;
    return (
      <div className="leftMenuWrapper">
        <div className={`navmenuMask ${this.props.isProcessFinished === true ? 'maskShow' : 'maskHide'}`}></div>
        {
          parentPage === "tanimlamalar"
            ? 
              this.props.user.UserRoles.includes("SUADMIN") === true
                ?
                  <ul className="leftMenuID">
                  {
                    <li>
                      <button
                        type="button" 
                        className={`menu-link`} 
                        onClick={(event) => this.goTo(event,'hosgeldiniz')} 
                        onMouseOver={(event, image) => this.onButtonMouseOver(event,'geri')}
                        onMouseOut={(event, image) => this.onButtonMouseOut(event,'geri')}
                      >
                        <img  className="menu-img" src={require(`../../assets/images/${this.state.geri}.png`)} alt="Geri" />
                        <span className="menu-text">Geri</span>
                      </button>
                    </li>  
                  }                   
                  {
                    <li className={currentPage === 'arac-tanimlari' ? 'selectedMenu' : ''}>
                      <button
                        type="button" 
                        className={`menu-link ${ currentPage === "arac-tanimlari" ? "color-white" : ""}`} 
                        onClick={(event) => this.goTo(event,'arac-tanimlari')} 
                        onMouseOver={(event, image) => this.onButtonMouseOver(event,'aractanimlari')}
                        onMouseOut={(event, image) => this.onButtonMouseOut(event,'aractanimlari')}
                      >
                        <img  className="menu-img" src={require(`../../assets/images/${this.state.aractanimlari}.png`)} alt={defaultPages.aractanimlari} />
                        <span className="menu-text">{defaultPages.aractanimlari}</span>
                      </button>
                    </li>  
                  }    
                  {
                    <li className={currentPage === 'surucu-tanimlari' ? 'selectedMenu' : ''}>
                      <button
                        type="button" 
                        className={`menu-link ${ currentPage === "surucu-tanimlari" ? "color-white" : ""}`} 
                        onClick={(event) => this.goTo(event,'surucu-tanimlari')} 
                        onMouseOver={(event, image) => this.onButtonMouseOver(event,'surucutanimlari')}
                        onMouseOut={(event, image) => this.onButtonMouseOut(event,'surucutanimlari')}
                      >
                        <img  className="menu-img" src={require(`../../assets/images/${this.state.surucutanimlari}.png`)} alt={defaultPages.surucutanimlari} />
                        <span className="menu-text">{defaultPages.surucutanimlari}</span>
                      </button>
                    </li>  
                  }  
                  {
                    <li className={currentPage === 'kullanici-tanimlari' ? 'selectedMenu' : ''}>
                      <button
                        type="button" 
                        className={`menu-link ${ currentPage === "kullanici-tanimlari" ? "color-white" : ""}`} 
                        onClick={(event) => this.goTo(event,'kullanici-tanimlari')} 
                        onMouseOver={(event, image) => this.onButtonMouseOver(event,'kullanicitanimlari')}
                        onMouseOut={(event, image) => this.onButtonMouseOut(event,'kullanicitanimlari')}
                      >
                        <img  className="menu-img" src={require(`../../assets/images/${this.state.kullanicitanimlari}.png`)} alt={defaultPages.kullanicitanimlari} />
                        <span className="menu-text">{defaultPages.kullanicitanimlari}</span>
                      </button>
                    </li>  
                  }
                  {
                    <li className={currentPage === 'guzergah-tanimlari' ? 'selectedMenu' : ''}>
                      <button
                        type="button" 
                        className={`menu-link ${ currentPage === "guzergah-tanimlari" ? "color-white" : ""}`} 
                        onClick={(event) => this.goTo(event,'guzergah-tanimlari')} 
                        onMouseOver={(event, image) => this.onButtonMouseOver(event,'guzergahtanimlari')}
                        onMouseOut={(event, image) => this.onButtonMouseOut(event,'guzergahtanimlari')}
                      >
                        <img  className="menu-img" src={require(`../../assets/images/${this.state.guzergahtanimlari}.png`)} alt={defaultPages.guzergahtanimlari} />
                        <span className="menu-text">{defaultPages.guzergahtanimlari}</span>
                      </button>
                    </li>  
                  } 
                  {
                    <li className={currentPage === 'zamanlama-tanimlari' ? 'selectedMenu' : ''}>
                      <button
                        type="button" 
                        className={`menu-link ${ currentPage === "zamanlama-tanimlari" ? "color-white" : ""}`} 
                        onClick={(event) => this.goTo(event,'zamanlama-tanimlari')} 
                        onMouseOver={(event, image) => this.onButtonMouseOver(event,'zamanlamatanimlari')}
                        onMouseOut={(event, image) => this.onButtonMouseOut(event,'zamanlamatanimlari')}
                      >
                        <img  className="menu-img" src={require(`../../assets/images/${this.state.zamanlamatanimlari}.png`)} alt={defaultPages.zamanlamatanimlari} />
                        <span className="menu-text">{defaultPages.zamanlamatanimlari}</span>
                      </button>
                    </li>  
                  }                                           
                  </ul>
                : this.props.user.UserRoles.includes("SUMANAGER") === true
                ?
                <ul className="leftMenuID">
                {
                  <li>
                    <button
                      type="button" 
                      className={`menu-link`} 
                      onClick={(event) => this.goTo(event,'raporlar')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'geri')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'geri')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.geri}.png`)} alt="Geri" />
                      <span className="menu-text">Geri</span>
                    </button>
                  </li>  
                }                   
                {
                  <li className={currentPage === 'arac-tanimlari' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "arac-tanimlari" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'arac-tanimlari')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'aractanimlari')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'aractanimlari')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.aractanimlari}.png`)} alt={defaultPages.aractanimlari} />
                      <span className="menu-text">{defaultPages.aractanimlari}</span>
                    </button>
                  </li>  
                }    
                {
                  <li className={currentPage === 'surucu-tanimlari' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "surucu-tanimlari" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'surucu-tanimlari')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'surucutanimlari')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'surucutanimlari')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.surucutanimlari}.png`)} alt={defaultPages.surucutanimlari} />
                      <span className="menu-text">{defaultPages.surucutanimlari}</span>
                    </button>
                  </li>  
                }      
                                 
                </ul>                  
                :
                  <div></div>
              
            : this.props.user.UserRoles.includes("SUADMIN") === true
              ? // IF SUADMIN
                <ul className="leftMenuID">
                {
                  <li className={currentPage === 'yolcu-islemleri' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "yolcu-islemleri" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'yolcu-islemleri')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'yolcuislemleri')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'yolcuislemleri')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.yolcuislemleri}.png`)} alt={defaultPages.yolcuislemleri} />
                      <span className="menu-text">{defaultPages.yolcuislemleri}</span>
                    </button>
                  </li>  
                }     

                {
                  <li className={currentPage === 'tanimlamalar' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "tanimlamalar" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'arac-tanimlari')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'tanimlamalar')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'tanimlamalar')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.tanimlamalar}.png`)} alt={defaultPages.tanimlamalar} />
                      <span className="menu-text">{defaultPages.tanimlamalar}</span>
                    </button>
                  </li>  
                }     

                {
                  <li className={currentPage === 'servis-islemleri' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "servis-islemleri" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'servis-islemleri')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'servisislemleri')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'servisislemleri')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.servisislemleri}.png`)} alt={defaultPages.servisislemleri} />
                      <span className="menu-text">{defaultPages.servisislemleri}</span>
                    </button>
                  </li>  
                }                  
                
                
                {
                  <li className={currentPage === 'raporlar' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "raporlar" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'raporlar')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'raporlar')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'raporlar')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.raporlar}.png`)} alt={defaultPages.raporlar} />
                      <span className="menu-text">{defaultPages.raporlar}</span>
                    </button>
                  </li>  
                }                    
              </ul>
                  
              : this.props.user.UserRoles.includes("SUMANAGER") === true
              ? // IF SUMANAGER
                <ul className="leftMenuID">
                {
                  <li className={currentPage === 'tanimlamalar' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "tanimlamalar" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'arac-tanimlari')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'tanimlamalar')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'tanimlamalar')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.tanimlamalar}.png`)} alt={defaultPages.tanimlamalar} />
                      <span className="menu-text">{defaultPages.tanimlamalar}</span>
                    </button>
                  </li>  
                }     

                {
                  <li className={currentPage === 'servis-islemleri' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "servis-islemleri" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'servis-islemleri')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'servisislemleri')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'servisislemleri')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.servisislemleri}.png`)} alt={defaultPages.servisislemleri} />
                      <span className="menu-text">{defaultPages.servisislemleri}</span>
                    </button>
                  </li>  
                }                         
                {
                  <li className={currentPage === 'raporlar' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "raporlar" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'raporlar')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'raporlar')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'raporlar')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.raporlar}.png`)} alt={defaultPages.raporlar} />
                      <span className="menu-text">{defaultPages.raporlar}</span>
                    </button>
                  </li>  
                }                    
              </ul>
              : // OTHER NORMAL USERs -> Yolcu ve Suruculer
                <ul className="leftMenuID">
                {
                  <li className={currentPage === 'servis-bilgisi' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "servis-bilgisi" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'servisbilgisi')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'servisbilgisi')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'servisbilgisi')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.servisbilgisi}.png`)} alt={defaultPages.servisbilgisi} />
                      <span className="menu-text">{defaultPages.servisbilgisi}</span>
                    </button>
                  </li>
                }
                {
                  <li className={currentPage === 'servisler' ? 'selectedMenu' : ''}>
                    <button
                      type="button" 
                      className={`menu-link ${ currentPage === "servisler" ? "color-white" : ""}`} 
                      onClick={(event) => this.goTo(event,'servisler')} 
                      onMouseOver={(event, image) => this.onButtonMouseOver(event,'servisler')}
                      onMouseOut={(event, image) => this.onButtonMouseOut(event,'servisler')}
                    >
                      <img  className="menu-img" src={require(`../../assets/images/${this.state.servisler}.png`)} alt={defaultPages.servisler} />
                      <span className="menu-text">{defaultPages.servisler}</span>
                    </button>
                  </li>
                }        
              </ul>
  
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.login.User,
    isProcessFinished: state.login.isProcessFinished
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NavMenu));