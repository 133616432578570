import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import './popup-modal.styles.scss';

class PopupModal extends React.Component {



  render(){
    return(
      <Modal
      {...this.props}
      size="lg"
      backdrop="static"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      titlemodal={this.props.titlemodal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {this.props.titlemodal}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {this.props.children}
      </Modal.Body>
      <Modal.Footer>
       {
         this.props.isaddress === "true" ?
         <div className="d-flex modalFooterWrapper">
          <div className="p-2">
              <Button onClick={this.props.onClick()} variant="warning">Yeni</Button>
          </div>
          
          <div className="ml-auto p-2">
            <Button onClick={this.props.onHide}>İptal</Button>
          </div>
       </div>
        :
        <div>
          <Button className="margin-right-5" onClick={this.props.onClick()} variant="success">Kaydet</Button>
          <Button onClick={this.props.onHide}>İptal</Button>
        </div>
       }
      </Modal.Footer>
    </Modal>
    );
  }
}


export default PopupModal;