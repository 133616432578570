import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Select from 'react-select';
import { showWarningLayer,customShowLoadingLayer, customHideLoadingLayer, serviceGroupBy,getDateFromName,generateServiceIdFromName } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
import { ProjectActionTypes } from '../../redux/project/project.types';
import ServiceChangeButton from '../service-change-button/service-change-button';
import axios from 'axios';
import './active-services-mobile.styles.scss';

class ActiveServicesMobile extends React.Component {
  constructor(){
    super();

    this.state = {
      loading: false,
      firstLoading:false,
      activeServices: [],
      currentService:[],
      serviceDates:[],
      selectedServiceID: null,
      selectedOption: null
    }

  }

  componentDidMount(){
    if (this.props.user.UserRoles.includes("SUDRIVER")){
      this.getServicesDriver();
    }else{    
      this.getServices();
    }
  }

  getServices = () => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services`,{ "uid": this.props.user.UserID, "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        const services = res.data.data;
        
        //let serviceDates =  serviceGroupBy(services,'SERVICEDATE');
        let serviceDates = services.reduce((service, currentItem) => {
          let key = currentItem.SERVICENAME.split("  ")[0];
          (service[key] = service[key] || []).push(currentItem);
          return service;
        }, {});
        

        //console.log(serviceDates);
        let serviceOptions = [];
        serviceDates = Object.keys(serviceDates);
        let i = 0;
        let selectedOption;
          for(let sd of serviceDates){
            
            let temp = { "value": sd, "label": sd };
            if (i === 0 ) {
              if (this.state.selectedOption === null){
                selectedOption = {};
                selectedOption = temp;
              }else{
                selectedOption = this.state.selectedOption;
              }
            }
            serviceOptions.push(temp);
            i++;
          }
  
       
          


        let currentService = services.filter(item => item.SERVICENAME.split("  ")[0] === selectedOption.value);
        
        this.setState({...this.state, loading:false,firstLoading:false, activeServices: services,serviceDates: serviceOptions,currentService:currentService,selectedOption:selectedOption });
        customHideLoadingLayer();
      }     
    })
  }

  handleOptionChange = (selectedOption) => {
    //nselectedOption);
    const tempServices = this.state.activeServices;
    let currentService = tempServices.filter(item => item.SERVICENAME.split("  ")[0] === selectedOption.value);
    this.setState({...this.state, selectedOption:selectedOption, currentService: currentService });      
  }
  handleOptionChangeDriver = (selectedOption) => {
    //nselectedOption);
    const tempServices = this.state.activeServices;
    let currentService = tempServices.filter(item => item.SERVICEDATE === selectedOption.value);
    this.setState({...this.state, selectedOption:selectedOption, currentService: currentService });      
  }      

  goTo = async (event,serviceid,servicename) => {
    event.preventDefault();
    const data = { SERVICEID: serviceid, SERVICENAME:servicename};
    return this.props.history.push('/servis-degistir',data);    
  }   


  getServicesDriver = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-driver-active-services`,{ "uid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        let serviceDates =  serviceGroupBy(services,'SERVICEDATE');
        let serviceOptions = [];
        let selectedOption;
        serviceDates = Object.keys(serviceDates);
        let i = 0;
        
        for(let sd of serviceDates){
          
          let temp = { "value": sd, "label": sd };
          if (i === 0 ) {
            if (this.state.selectedOption === null){
              selectedOption = {};
              selectedOption = temp;
            }else{
              selectedOption = this.state.selectedOption;
            }
          }
          serviceOptions.push(temp);
          i++;
        }
          
        let currentService = services.filter(item => item.SERVICEDATE === selectedOption.value);
        this.setState({...this.state, loading:false, activeServices: services,serviceDates: serviceOptions,currentService:currentService,selectedOption:selectedOption });
      }     
    })
  }  


  handleSwitchChange = async (checked,serviceid) => {
    //this.props.setLoading(true);
    customShowLoadingLayer();
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/";
    if (checked){
      dataUrl+="make-reservation";
    }else{
      dataUrl+="remove-reservation";
    }
    
    const response = await axios.post(dataUrl,{ "uid": this.props.user.UserID, "sid": this.props.user.SessionID, "serid": serviceid},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        // let warningMessageText = "";
        // if (checked){
        //   warningMessageText = "Rezervasyon yapıldı.";
        // }else{
        //   warningMessageText = "Rezervasyon iptal edildi.";
        // }
        // this.props.onWarning({warningMessageType:'done', warningMessageText: warningMessageText});
        // showWarningLayer();
        //this.props.setLoading(false);
        this.getServices();
      }else{
        let warnmessage = "";
        if (response.data.message === "DP_Limit") {
          warnmessage = "Seçmiş olduğunuz servisin kapatesi dolmuştur. Lütfen başka bir gün ve saat seçiniz.";
        }else{
          warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        }
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();
        //this.props.setLoading(false);
        this.getServices();
        //this.forceUpdate();

      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      //this.props.setLoading(false);
      this.getServices();
      //this.forceUpdate();
    }
  }
  


  render(){
    //console.log(this.state.activeServices);
    return(
      <div>
      {
        this.props.user.UserRoles.includes("SUDRIVER") === true ?
          
        <div className="serviceDateSelectWrapper">
          <Select
            placeholder="Lütfen servis gün / saat seçiniz."
            value={this.state.selectedOption}
            onChange={this.handleOptionChangeDriver}
            options={this.state.serviceDates}
            getOptionLabel={({label}) => label}
            getOptionValue={({value}) => value}             
          />        
        </div>
          :
        (
        <div className="serviceDateSelectWrapper">
          <Select
            placeholder="Lütfen servis gün / saat seçiniz."
            value={this.state.selectedOption}
            onChange={this.handleOptionChange}
            options={this.state.serviceDates}
            getOptionLabel={({label}) => label}
            getOptionValue={({value}) => value}             
          />        
        </div>          
        )
      }
      {
        this.props.user.UserRoles.includes("SUDRIVER") ? 
          this.state.loading ? <LoadingIndicator/> :
          this.state.currentService.length > 0 ? (
            this.state.currentService.map(({SERVICEID, ...otherServiceProps},index) => (
              <div key={SERVICEID} className="serviceRow">
                <div className="header">
                  <h6 className="su-color">{otherServiceProps.SERVICEDATE}</h6>
                  <h1 className="serviceTitle">{otherServiceProps.SERVICENAME}</h1>
                  <p>
                    <span className="serviceInfoTitle">Servis Yönü</span><br/>
                    <span className="serviceInfoContent">{otherServiceProps.ROUTETYPE === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"}</span>
                  </p>
                  <p>
                    <span className="serviceInfoTitle">Plaka No</span><br/>
                    <span className="serviceInfoContent">{otherServiceProps.BUSPLATE}</span>

                  </p>
                  <p>
                    <span className="serviceInfoTitle">Kapasite</span><br/>
                    <span className="serviceInfoContent">{otherServiceProps.CAPACITY}</span>

                  </p>
              

                </div>
                <div className="container">
                  <div className="footerP">
                    <button type="button" className="btn btn-warning btn-rota-detay" onClick={() => { this.props.history.push('/surucu-yolcu-listesi',{SERVICEIDX: SERVICEID, SERVICENAME: otherServiceProps.SERVICENAME, SERVICEDATE: otherServiceProps.SERVICEDATE}); }}>Yolcu Listesi</button>            
                  </div>
                </div>
              </div>
              )
            )
          ) : 
            <p>Gösterilecek servis yok.</p>
        :
        this.state.firstLoading ? <LoadingIndicator/> :
        this.state.currentService.length > 0 ? (
          this.state.currentService.map(({SERVICEID, ...otherServiceProps},index) => (

            <div key={SERVICEID} className="serviceRow">
              <div className="header">
                <h1 className="serviceTitle">{otherServiceProps.SERVICENAME}</h1>
                <p>
                  <span className="serviceInfoTitle">Servis Yönü</span><br/>
                  <span className="serviceInfoContent">{otherServiceProps.ROUTETYPE === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"}</span>
                </p>
                <p>
                  <span className="serviceInfoTitle">Durum</span><br/>
                  <span className="serviceInfoContent">
                  {
                      otherServiceProps.ACTIONSTAT === "1" ? "Rezervasyon Yapıldı." :
                      otherServiceProps.ACTIONSTAT === "2" ? "Rezervasyon İptal Edildi." :
                        otherServiceProps.ACTIONSTAT > 2 ? "Kullanıcı Geldi" : "Kullanıcı Gelmiyor" 
                  }
                  </span>

                </p>
                <p>
                  <span className="serviceInfoTitle">Kapasite</span><br/>
                  <span className="serviceInfoContent">{otherServiceProps.CAPACITY}</span>

                </p>
            

              </div>
              <div className="container">
                <div className="footerP">
                  <BootstrapSwitchButton 
                    checked={otherServiceProps.ACTIONSTAT === "1" ? true : false}
                    onlabel={otherServiceProps.SERVICESTAT === "0" ? "Geliyorum" : "Servis Kapalı"} 
                    offlabel={otherServiceProps.SERVICESTAT === "0" ? "Gelmiyorum" : "Servis Kapalı"}
                    onstyle={otherServiceProps.SERVICESTAT === "0" ? "success" : "light"}
                    offstyle={otherServiceProps.SERVICESTAT === "0" ? "danger" : "light"} 
                    width="130" 
                    disabled={otherServiceProps.SERVICESTAT === "0" ? false : true}
                    onChange={(checked) => this.handleSwitchChange(checked,SERVICEID)}
                  /> 
                  {
                    getDateFromName(otherServiceProps.SERVICENAME) !== false && <ServiceChangeButton classes="btn-change-reservation" onClick={(event) => this.goTo(event,SERVICEID,otherServiceProps.SERVICENAME)} >Değiştir</ServiceChangeButton>
                  }                                  
                </div>
              </div>
            </div>
            )
          )
          
        ) : 
          <p>Gösterilecek servis yok.</p>        
      }
 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ActiveServicesMobile));