import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import OtherPassengers from '../../components/other-passengers/other-passengers.component';
import MainLogo from '../../components/main-logo/main-logo.component';
import TopHeader from '../../components/header/header.component';
import MobileHeader from '../../components/mobile-header/mobile-header.component';
import ContentWrapper from '../../components/content-wrapper/content-wrapper.component';
import NavMenu from '../../components/nav-menu/nav-menu.component';
import MainCopyright from '../../components/main-copyright/main-copyright.component';
import { ProjectActionTypes } from '../../redux/project/project.types';


import '../../assets/styles/edms-main.scss';
import '../../assets/styles/edms-overrides.scss';
import './diger-yolcular.styles.scss';



class DigerYolcular extends React.Component {
  constructor(props){
    super(props);
    this.title = "Servis Bilgisi > Diğer Yolcular";
  }

  

  componentDidMount() {
    if (this.props.isLoggedIn === true) {
      const bodyElement = document.querySelector('body');
      bodyElement.classList.add('noBodyImage');
      //console.log(this.props.user);
      this.props.setCurrent('servis-bilgisi');
      //console.log(this.props.location);
    }
  }
 

  render(){
    if (this.props.isLoggedIn === false) {
      return <Redirect push to="/" />;
    }      
    return(
      <div className="middleID">
        <MediaQuery minWidth={768}>
          <div className="leftID">
            <MainLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
            <NavMenu currentPage={this.props.currentPage} /> 
            <MainCopyright companyName="DocPlant" />
          </div>
          <div className="rightID">
            <TopHeader breadcrumbTitle={this.title} />
            <ContentWrapper classname="otherPassengersMainWrapper">
              <OtherPassengers />
            </ContentWrapper>
          </div>
          <div id="jqueryconfirm"></div>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className="mobileWrapper">
            

            <MobileHeader />
            <ContentWrapper classname="otherPassengersMainWrapper">
              {
                <OtherPassengers/>
              }
            </ContentWrapper>
          </div>
          <div id="jqueryconfirm"></div>
        </MediaQuery>

     </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    currentPage: state.project.currentPage,
    warningMessageShow: state.project.warningMessageShow,
    warningMessageType: state.project.warningMessageType,
    warningMessageText: state.project.warningMessageText,
    user: state.login.User
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(DigerYolcular);