import { ProjectActionTypes  } from './project.types';

const INITIAL_STATE = {
  warningMessageShow:false,
  warningMessageType:'fail',
  warningMessageText:'',
  currentPage: '',
  menu: '',
  breadcrumbTitle: '',
  pageload:false
}

const projectReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case ProjectActionTypes.SET_DEFAULT:
      return INITIAL_STATE

    case ProjectActionTypes.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.value
      }
    case ProjectActionTypes.PAGE_LOAD:
      return {
        ...state,
        pageload: action.pageload
      }
  

    case ProjectActionTypes.SET_PAGE_LOADING:
      return {
        ...state,
        isLoading: action.value
      }

    case ProjectActionTypes.SET_WARNING_MESSAGE:
      return {
        ...state,
        warningMessageType: action.payload.warningMessageType,
        warningMessageText: action.payload.warningMessageText        
      }

    case ProjectActionTypes.SET_MENU:
      return {
        ...state,
        menu: action.value
      }

    case ProjectActionTypes.SET_CURRENT:
      return{
        ...state,
        currentPage: action.value
      }

    case ProjectActionTypes.SET_BREADCRUMB_TITLE:
      return {
        ...state,
        breadcrumbTitle: action.value
      }
  

    default:
      return state;
  }
}

export default projectReducer;