import React from 'react';

import './mobile-logo.styles.scss';

const MobileLogo = ({logoName,appName}) => (
  <div className="mobileLogoWrapperID">
      <img className="mobileLogoID" src={require(`../../assets/images/${logoName}`)} alt={appName} />
  </div> 
);

export default MobileLogo;