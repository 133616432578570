import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
import { showWarningLayer, customShowLoadingLayer, customHideLoadingLayer } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import { getDateFromName,generateServiceIdFromName } from '../../utils/utils';
import axios from 'axios';
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import './service-change.styles.scss';
import CustomButton from '../custom-button/custom-button.component';
import ServiceChangeButton from '../service-change-button/service-change-button';

//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action}>
        <p className="text-center passwordInfo"><b>{text}</b></p>
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class ServiceChange extends React.Component {
  constructor(props){
    super(props);

    if (props.activeUserID !== null && props.activeUserID !== undefined){
      //console.log(props.activeUserID);
      
    }else{
      
    }

    if (props.serviceId === null || props.serviceId === undefined || props.serviceId === ""){
      this.props.logOut();
      return;
    }

    const serviceName = generateServiceIdFromName(props.serviceName);

    this.state = {
      loading: false,
      firstLoading:false,
      activeServices: [],
      selectedServiceID: null,
      oldServiceId: props.serviceId,
      oldServiceName: serviceName,
      completed:false
    }

  }

  componentDidMount(){
      
      this.getServices();
  }

  getServices = () => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
    axios.post(`https://azr04.infotraq.com/servisradarsu/passenger-services-for-move`,{ "sruid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID, "srsname": this.state.oldServiceName },{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        //console.log(services);
        this.setState({...this.state, loading:false,firstLoading:false, activeServices: services });
        customHideLoadingLayer();
      }     
    })
  }
  
 
  handleServiceChange = async (oldServiceId,newServiceId) => {

    if (oldServiceId === newServiceId){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: "Seçtiğiniz servis farklı olmak zorundadır. Lütfen başka servis seçiniz."});
      showWarningLayer();
      return;
    }

    //this.props.setLoading(true);
    customShowLoadingLayer();
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/passenger-move-own-service";
    //return true;
    const response = await axios.post(dataUrl,{ "uid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID, "oldServiceId": oldServiceId, "newServiceId": newServiceId},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        let warningMessageText = "Servis taşıma başarıyla gerçekleşti.";
        this.props.onWarning({warningMessageType:'done', warningMessageText: warningMessageText});
        showWarningLayer();
        this.props.setLoading(false);
        customHideLoadingLayer();    
        this.setState({
          ...this.state,
          completed:true
        });    
      }else{
        let warnmessage = "";
        if (response.data.message === "DP_Limit") {
          warnmessage = "Seçmiş olduğunuz servisin kapatesi dolmuştur. Lütfen başka bir gün ve saat seçiniz.";
        }else{
          warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        }
        this.props.setLoading(false);
        customHideLoadingLayer();    
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();

      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      this.props.setLoading(false);
      customHideLoadingLayer();
      this.forceUpdate();
    }
  }
  
  goTo = async (event) => {
    event.preventDefault();
    const data = { };
    return this.props.history.push('/servisler',data);    
  }   


  render(){
    //console.log(this.state.activeServices);
    return(
      <div className="servisTableWrapper">
      {
          this.state.firstLoading ? <LoadingIndicator/> :
          this.state.activeServices.length > 0 ? (
            this.state.completed === true ? (
              <Fragment>
                <p className="text-center">Servis taşıma işleminiz tamamlandı.</p>
                <ServiceChangeButton classes="service-change-button-max" onClick={(event) => this.goTo(event)}>Servislere Geri Dön</ServiceChangeButton>
              </Fragment>
            ):
            <div className="servisTable">
            <Table className="tableServisler" responsive>
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>Servis Adı</th>
                  <th className="text-center">Aksiyon </th>
                </tr>
              </thead>
              <tbody>
                {
                  
                this.state.activeServices.map(({serviceid, ...otherServiceProps},index) => (
                  <tr key={index+1}>
                      <td>{index+1}</td>
                      <td className={serviceid === this.state.oldServiceId && 'selected-service'}>{otherServiceProps.servicename} {serviceid === this.state.oldServiceId && ' (Değiştirelecek Servis)' }</td>

                      <td className="text-center">
                      {
                        (
                          <Fragment>
                            {
                              serviceid !== this.state.oldServiceId &&
                                getDateFromName(otherServiceProps.servicename) !== false && <ServiceChangeButton classes="btn-change-reservation" onClick={confirmDialog(true,'changepassword','Seçilen servise rezervasyonunuz taşınacaktır. Devam etmek istiyor musunuz ?',() => this.handleServiceChange(this.state.oldServiceId,serviceid))}>Seç</ServiceChangeButton>
                            }
                            
                          </Fragment>
                          )

                      }
                      </td>
                    </tr>
                  )
                )
                }
              </tbody>
            </Table>
            </div>
          ) : 
          <p>Gösterilecek servis yok.</p>    
    
      }
 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    serviceRow: state.passenger.serviceRow
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setCurrentService: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE, payload: payload}),
    setCurrentServiceID: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE_ID, payload: payload}),
    setCurrentServiceName: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE_NAME, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ServiceChange));