import React from 'react';
import ReactDOM from 'react-dom';
import { ContextMenuTrigger } from "react-contextmenu";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import tr from 'date-fns/locale/tr';
import PopupModal from '../../components/popup-modal/popup-modal.component';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import ReactTooltip from "react-tooltip";
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import { showWarningLayer, showWarningLayerPopup, hideWarningLayerPopup, generateID } from '../../utils/utils';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css';
import './users.styles.scss';
import '../../assets/styles/react-bootstrap-table-all.min.css';
import '../../assets/styles/react-contextmenu.css';

//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action}>
        <p className="text-center passwordInfo"><b>{text}</b></p>
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class Users extends React.Component {
  constructor(props){
    super(props);
    this.guid = generateID();
    this.excelData = [];
    this.state = {
      loading: false,
      loadingPopup:false,
      firstLoading:true,
      loadingModalNew: false,
      loadingModalEdit:true,
      selectedUserID: null,
      selectedUserData:null,
      modalNewKullanici:false,
      modalEditKullanici:false,
      isFilterDisable:true,
      isRowSelected: false,
      statusOption: {label: 'Sistem Yöneticisi', value: 1},
      currentUser:{
        USERNAME: '',
        PASSWORD: '',
        EMAIL: '',
        DISPLAYNAME: '',
        PHONENUMBER: '',
        IDNO: '',
        USERID:''
      },
      columnFilterData:{}      
    }

  }


  componentDidMount(){
    this.getUsers();
  }
  handleNewUser = () => {
    this.props.setLoadingPopup(false);
    this.setState({...this.state, modalNewKullanici:true});    
  }

  handleEditUser = () => {
    this.props.setLoadingPopup(false);
    if (this.state.selectedUserData === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }        

    this.setState({
      ...this.state,
      modalEditKullanici: true
    });
    
  }

  insertUser = async () => {
    this.props.setLoadingPopup(true);
    let username = this.username.value;  
    let password = this.password.value;  
    let repassword = this.repassword.value;
    let email = this.email.value;  
    let displayname = this.displayname.value;
    let phonenumber = this.phone.value;
    let idno = this.idno.value;
    let usertype =  this.state.statusOption.value;

    if (username === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (displayname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ad / soyad alanını kontrol edin.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }    

    if (password === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanını kontrol edin.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (repassword === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre tekrar alanını kontrol edin.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    } 
    
    if (password !== repassword){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanları uyumsuz.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;      
    }
    

    if (email === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta alanını kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }    

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-user`,
      {
        "sid": this.props.user.SessionID,
        "username": username,
        "displayname":displayname,
        "password":password,
        "email":email,
        "phonenumber":phonenumber,
        "idno":idno,
        "usertype":usertype
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Username"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Displayname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ad / soyad alanını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Password"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanlarını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Email"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta alanını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Kullanıcı eklendi.'});
        showWarningLayer();
        this.props.setLoading(true);
        this.getUsers();
        this.setState({...this.state,modalNewKullanici:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }
  updateUser = async () => {
    this.props.setLoadingPopup(true);
    let userid = this.state.selectedUserData.USERID;
    let username = this.username.value;  
    let password = this.password.value;  
    let repassword = this.repassword.value;
    let email = this.email.value;  
    let displayname = this.displayname.value;
    let phonenumber = this.phone.value;
    let idno = this.idno.value;

    if (userid === "" || userid === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (username === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (displayname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ad / soyad alanını kontrol edin.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }    
 

    if (password !== repassword){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanları uyumsuz.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;      
    }

    
    if (password === ""){
      password = this.state.selectedUserData.PASSWORD;
    }

    if (email === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta alanını kontrol edin.'});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }    

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-user`,
      {
        "sid": this.props.user.SessionID,
        "username": username,
        "displayname":displayname,
        "password":password,
        "email":email,
        "phonenumber":phonenumber,
        "idno":idno,
        "userid":userid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Username"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Displayname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ad / soyad alanını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Password"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanlarını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Email"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta alanını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Kullanıcı güncellendi.'});
        showWarningLayer();
        this.props.setLoading(true);
        this.getUsers();
        this.setState({...this.state,modalEditKullanici:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }  

  deleteUser = async () => {
    this.props.setLoading(true);
    let userid = this.state.selectedUserData.USERID;
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/delete-user`,
      {
        "sid": this.props.user.SessionID,
        "userid":userid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Userid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Kullanıcı silindi.'});
        this.getUsers();
        showWarningLayer();
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }


  handleFilterSearch = (value) => {
    try{
      this.refs.displaynameCol.applyFilter(value);
    }catch(ex){}
    
  }

  getUsers = () => {
    this.setState({
      ...this.state,
      isFilterDisable:true,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-users`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let users;
        try{
          users = res.data.data;
          if (users === null || users === undefined){
            users = [];
          }
        }catch(ex){
          users = [];
        }
        
        this.props.setExcelData(users);
        this.setState({...this.state,isFilterDisable:false,firstLoading:false, loading:false});
        this.props.setUsers(users);
        this.props.setLoading(false);
      }     
    })
  }

  handleRowSelect = (row, isSelected, e) => {
    this.setState(
      {
      ...this.state,
      selectedUserData: row,
      isRowSelected: isSelected
      }
      );
  }    

  handleDeleteUser = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }    

    confirmDialog(true,'changepassword','Seçilen kullanıcıyı silmek istediğinize emin misiniz ?',() => this.deleteUser())();    

  }  

  handleStatusChange = statusOption => {
    this.setState(
      { statusOption });
  };   

  afterColumnFilter = (filterConds, result) => {
    //console.log(filterConds);
    const tempData = this.props.excelData;
    if (JSON.stringify(tempData) !== JSON.stringify(result)){
      this.props.setExcelData(result);
    }

    if (JSON.stringify(this.state.columnFilterData) !== JSON.stringify(filterConds)){    
      this.setState({
        ...this.state,
        columnFilterData: filterConds
      });
    }
  }

  render(){
    const durumOptions = [
      { value: 0, label: 'Servis Şirketi Yöneticisi' },
      { value: 1, label: 'Sistem Yöneticisi' }
    ];  
    //const editGuid = generateID();
    //let tempDate = formatNow(new Date(),"YYYY-MM-DD");
    //const excelFileName = tempDate;
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 50,
      noDataText: 'Gösterilecek kayıt yok',
      afterColumnFilter: this.afterColumnFilter,
    };    
    const selectRow = {
      mode: 'radio',  // multi select
      clickToSelect: true,
      className: 'row-select-class',
      bgColor: '#004288',
      Color:'white',
      onSelect: this.handleRowSelect,
      selected: this.state.selectedUserData !== null ? [this.state.selectedUserData.USERID] : []      
    };   
    const footerData = [
      [
        {
          label: '',
          columnIndex: 1,
          align: 'left',
          formatter: (tableData) => {
            //let total = tableData.length;
            let total = this.props.excelData.length;
            return (
              <strong>Toplam : { total } kullanıcı</strong>
            );
          }
          
        }
      ]
    ];    

    return(
      <div className="passengersPageWrapper">
      <div className="card card-body bg-light content-toolbar">
      <div className="d-flex">
          <div className="p-2">
            <button type="button" className="btn btn-sm btn-general btn-light p-2 margin-left-5 userbuttons minibuttons" data-tip="Kullanıcı Ekle" onClick={() => this.handleNewUser()}><i className="fas fa-plus"></i></button>
            <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Kullanıcı Düzelt" onClick={() => this.handleEditUser()}><i className="fas fa-edit"></i> </button>
            <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Kullanıcı Sil" onClick={() => this.handleDeleteUser()}><i className="fas fa-times"></i> </button>
          </div>
          <div className="ml-auto p-2">
            <div className="d-flex">
               <label className="filterLabelYolcuIslemleri">Filtrele :</label><input id="editFilter" type="text" disabled={this.state.isFilterDisable} className="form-control edit editFilter margin-right-5" placeholder="Kullanıcı Adı veya Soyadı" onChange={(event) => this.handleFilterSearch(event.target.value)}  />
            </div>              
          </div>
      </div>   
      </div>       
        <div className="SplitterPanelLeft">
        <ContextMenuTrigger id="submenu1" holdToDisplay={1000}>            
        {
          this.state.firstLoading ? <LoadingIndicator /> :
          this.props.users.length > 0 ? (
            <BootstrapTable data={ this.props.users } container className='user-list kullanici-tanimlari-table' hover options={options}  selectRow={ selectRow } footerData={ footerData } footer pagination >
              <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
              <TableHeaderColumn dataField='DISPLAYNAME' width='auto' ref='displaynameCol' className="displayCol" filter={ { type: 'TextFilter', delay: 1000}}  dataSort={ true } headerAlign='center'>Ad / Soyad</TableHeaderColumn>
              <TableHeaderColumn dataField='USERNAME' width='180px' dataSort={ true } dataAlign='center' headerAlign='center'>Kullanıcı Adı</TableHeaderColumn>
              <TableHeaderColumn dataField='EMAIL' width='180px' dataSort={ true } dataAlign='center'>E-Posta</TableHeaderColumn>
              <TableHeaderColumn dataField='PHONENUMBER' width='200px' ref='phonenumberCol' className="displayCol" dataSort={ true } headerAlign='center'>Telefon Numarası</TableHeaderColumn>
              <TableHeaderColumn dataField='IDNO' width='200px'  ref='idnoCol' className="displayCol"  dataSort={ true } headerAlign='center'>Sicil No</TableHeaderColumn>
              <TableHeaderColumn dataField='USERROLE' width='250px'  ref='idnoCol' className="displayCol"  dataSort={ true } headerAlign='center' dataAlign='center'>Rol Tanımı</TableHeaderColumn>
            </BootstrapTable>          
          ) : 
          <p className="padding15">Gösterilecek kayıt yok.</p>
        }              
        </ContextMenuTrigger>        
                        
          {
            <table id="emp-kullanici-tanimlari" className="excel-table">  
            <tbody>             
              <tr>
                <td><b>Ad / Soyad {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.DISPLAYNAME !== undefined && '*'}</b></td>
                <td><b>Kullanıcı Adı</b></td>
                <td><b>E-Posta</b></td>
                <td><b>Telefon Numarası</b></td>
                <td><b>Sicil No</b></td>
                <td><b>Rol Tanımı</b></td>
              </tr>                                   
                { 
                  this.props.excelData.map((p, index) => {  
                      return (
                      <tr key={index}>  
                        <td >{p.DISPLAYNAME}</td>  
                        <td >{p.USERNAME}</td>  
                        <td >{p.EMAIL}</td>  
                        <td >{p.PHONENUMBER}</td>  
                        <td >{p.IDNO}</td>  
                        <td >{p.USERROLE}</td>  
                      </tr>  
                      )
                  })  
                }  
                <tr>
                <td><b>Toplam</b></td>
                <td><b>{this.props.excelData.length}</b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>  
              </tbody>  
              </table>  
              
          }          
                  


        </div>
          <PopupModal
              show={this.state.modalNewKullanici}
              onHide={() => this.setState({...this.state,modalNewKullanici:false})}
              titlemodal={`Yeni Kullanıcı Ekle`}
              onClick={()=> this.insertUser}
            >
            
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen kullanıcı bilgilerini giriniz.</p>
            <form className="address-form" name="user-form">
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`username_${this.guid}`}>Kullanıcı Adı : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`username_${this.guid}`}  ref={(ref)=>{ this.username = ref;}} placeholder="Kullanıcı Adı zorunlu"  required />
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor={`displayname_${this.guid}`}>Ad / Soyad : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`displayname_${this.guid}`}  ref={(ref)=>{ this.displayname = ref;}} placeholder="Kullanıcı Adı zorunlu"  required  />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`password_${this.guid}`}>Şifre : <span className="requiredFieldMark">*</span></label>
                <div className="clearfix"></div>
                <input type="password" id={`password_${this.guid}`} name={`password_${this.guid}`} ref={(ref)=>{ this.password = ref;}} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Şifre" alt="Lütfen şifre alanını kontrol ediniz."  required />
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor={`repassword_${this.guid}`}>Şifre Tekrar : <span className="requiredFieldMark">*</span></label>
                <div className="clearfix"></div>
                <input type="password" id={`repassword_${this.guid}`} name={`repassword_${this.guid}`} ref={(ref)=>{ this.repassword = ref;}} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Şifre tekrar" alt="Lütfen şifre alanını kontrol ediniz."  required />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`email_${this.guid}`}>E-Posta Adresi : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`email_${this.guid}`}  ref={(ref)=>{ this.email = ref;}} placeholder="E-Posta adresi zorunlu"  required />
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor={`phone_${this.guid}`}>Telefon Numarası :</label>
                <input type="text" className="form-control" id={`phone_${this.guid}`}  ref={(ref)=>{ this.phone = ref;}} placeholder="Telefon Numarası"  />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`idno_${this.guid}`}>Sicil No :</label>
                <input type="text" className="form-control" id={`idno_${this.guid}`}  ref={(ref)=>{ this.idno = ref;}} placeholder="Sicil No"  />
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor={`usertype_${this.guid}`}>Kullanıcı Tipi :</label>
                <Select id={`usertype_${this.guid}`} className="edit max-width-element" placeholder="Kullanıcı Tipi" options={durumOptions} value={this.state.statusOption} onChange={this.handleStatusChange} />

              </div>
            </div>

            </form>                

            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
          </PopupModal> 
          
          <PopupModal
            show={this.state.modalEditKullanici}
            onHide={() => this.setState({...this.state,modalEditKullanici:false})}
            titlemodal={`Kullanıcı Düzelt - ${this.state.selectedUserData === null ? '' : this.state.selectedUserData.DISPLAYNAME }`}
            onClick={()=> this.updateUser}
            state={this.state}
          
          >
        
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen kullanıcı bilgilerini giriniz.</p>
            <form className="address-form" name="driver-form">
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`username_${this.guid}`}>Kullanıcı Adı : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`username_${this.guid}`}  ref={(ref)=>{ this.username = ref;}} placeholder="Kullanıcı Adı zorunlu" defaultValue={this.state.selectedUserData === null ? "" : this.state.selectedUserData.USERNAME}  required />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor={`displayname_${this.guid}`}>Ad / Soyad : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`displayname_${this.guid}`}  ref={(ref)=>{ this.displayname = ref;}} placeholder="Ad / Soyad zorunlu" defaultValue={this.state.selectedUserData === null ? "" : this.state.selectedUserData.DISPLAYNAME}  required  />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`password_${this.guid}`}>Şifre :</label>
                <div className="clearfix"></div>
                <input type="password" id={`password_${this.guid}`} name={`password_${this.guid}`} ref={(ref)=>{ this.password = ref;}} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Şifre" alt="Lütfen şifre alanını kontrol ediniz."  required />
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor={`repassword_${this.guid}`}>Şifre Tekrar :</label>
                <div className="clearfix"></div>
                <input type="password" id={`repassword_${this.guid}`} name={`repassword_${this.guid}`} ref={(ref)=>{ this.repassword = ref;}} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Şifre tekrar" alt="Lütfen şifre alanını kontrol ediniz."  required />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`email_${this.guid}`}>E-Posta Adresi : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`email_${this.guid}`}  ref={(ref)=>{ this.email = ref;}} placeholder="E-Posta adresi zorunlu" defaultValue={this.state.selectedUserData === null ? "" : this.state.selectedUserData.EMAIL}  required />
              </div>
              <div className="col-md-6 mb-6">
                <label htmlFor={`phone_${this.guid}`}>Telefon Numarası :</label>
                <input type="text" className="form-control" id={`phone_${this.guid}`}  ref={(ref)=>{ this.phone = ref;}} placeholder="Telefon Numarası" defaultValue={this.state.selectedUserData === null ? "" : this.state.selectedUserData.PHONENUMBER}  />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`idno_${this.guid}`}>Sicil No :</label>
                <input type="text" className="form-control" id={`idno_${this.guid}`}  ref={(ref)=>{ this.idno = ref;}} placeholder="Sicil No" defaultValue={this.state.selectedUserData === null ? "" : this.state.selectedUserData.IDNO}  />
              </div>
            </div>
            </form>                
            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
        </PopupModal>           
      
      

            <ReactTooltip /> 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    users: state.definitions.users,
    excelData: state.definitions.excelData,
    showLoading:state.login.showLoading,
    showLoadingPopup:state.login.showLoadingPopup,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setUsers: (users) => dispatch({ type: DefinitionsActionTypes.SET_USERS, users: users}),
    setExcelData: (excelData) => dispatch({ type: DefinitionsActionTypes.SET_EXCEL_DATA, excelData: excelData})
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Users));