import React from 'react';

import './loading-layer.styles.scss';

const LoadingLayer = ({showLoading,...otherProps}) => {
  let loadType = "";
  if (otherProps.loadingtype === null || otherProps.loadingtype === undefined || otherProps.loadingtype === "")
  {
    loadType = "normal";
  }else{
    loadType = "modal";
  }
  return(
    loadType === "normal" ? 
    <div className={showLoading === true ? "loadingWrapper show": "loadingWrapper hide"}>
      <div className="cssload-whirlpool"></div>
    </div>
    :
    <div className={showLoading === true ? "loadingWrapper loadingWrapperModal show": "loadingWrapperModal hide"}>
      <div className="cssload-whirlpool"></div>
    </div>    
  );
}

export default LoadingLayer;