import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Button } from 'react-bootstrap';
import MainLogo from '../../components/main-logo/main-logo.component';
import TopHeader from '../../components/header/header.component';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import tr from 'date-fns/locale/tr';
import MobileHeader from '../../components/mobile-header/mobile-header.component';
import ContentWrapper from '../../components/content-wrapper/content-wrapper.component';
import PopupModal from '../../components/popup-modal/popup-modal.component';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import WarningLayerPopup from '../../components/warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../../components/loading-layer/loading-layer.component';

import NavMenu from '../../components/nav-menu/nav-menu.component';
import MainCopyright from '../../components/main-copyright/main-copyright.component';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { LoginActionTypes } from '../../redux/login/login.types';
import * as actionCreators from '../../redux/login/login.actions';
import { showWarningLayer, showWarningLayerPopup, parseDate, hideWarningLayerPopup } from '../../utils/utils';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import '../../assets/styles/edms-main.scss';
import '../../assets/styles/edms-overrides.scss';
import './duzelt-yolcu.styles.scss';
import '../../assets/styles/bootstrap-checkbox.scss';

class DuzeltYolcu extends React.Component {
  constructor(props){
    super(props);
    //console.log(props);
    this.title = "Yolcu İşlemleri > Yolcu Düzelt";
    this.formId = this.generateID();
    this.username = "username_"+this.generateID();
    this.fullname = "fullname_"+this.generateID();
    this.password = "password_"+this.generateID();
    this.repassword = "repassword_"+this.generateID();
    this.email = "email_"+this.generateID();
    this.phone = "phone_"+this.generateID();
    this.idno = "idno_"+this.generateID();
    this.birim = "birim_"+this.generateID();
    this.gorevi = "gorevi_"+this.generateID();
    this.calismasekli = "calismasekli_"+this.generateID();
    this.faturali = "faturali_"+this.generateID();
    this.giristarih = "giristarih_"+this.generateID();
    this.cikistarih = "cikistarih_"+this.generateID();
    this.durum = "durum_"+this.generateID();
    this.notlar = "notlar_"+this.generateID();
    
    this.new_addressname = "addressname_"+this.generateID();
    this.new_address1 = "address1_"+this.generateID();
    this.new_address2 = "address2_"+this.generateID();
    this.new_semt = "semt_"+this.generateID();
    this.new_ilce = "ilce_"+this.generateID();
    this.new_postakodu = "postakodu_"+this.generateID();
    this.new_locationx = "locationx_"+this.generateID();
    this.new_locationy = "locationy_"+this.generateID();
    this.new_locationz = "locationz_"+this.generateID();
    this.new_formAddress = "formAddress_"+this.generateID();

    this.edit_addressname = "addressname_"+this.generateID();
    this.edit_address1 = "address1_"+this.generateID();
    this.edit_address2 = "address2_"+this.generateID();
    this.edit_semt = "semt_"+this.generateID();
    this.edit_ilce = "ilce_"+this.generateID();
    this.edit_postakodu = "postakodu_"+this.generateID();
    this.edit_locationx = "locationx_"+this.generateID();
    this.edit_locationy = "locationy_"+this.generateID();
    this.edit_locationz = "locationz_"+this.generateID();
    this.edit_formAddress = "formAddress_"+this.generateID();    

    this.state = {
      loading: false,
      loadingPopup:false,
      userAddress:[],
      currentAddress:{
        ADDRESSNAME: '',
        ADDRESS1: '',
        ADDRESS2: '',
        SEMT: '',
        ILCE: '',
        POSTAKODU: '',
        LOCATIONX: '',
        LOCATIONY: '',
        LOCATIONZ: '',
        PADRID: ''
      },      
      girisTarih: this.props.location.state.selectedUserData.ISEGIRISTARIHI === "" ? null : parseDate(this.props.location.state.selectedUserData.ISEGIRISTARIHI),
      cikisTarih: this.props.location.state.selectedUserData.ISTENAYRILMATARIHI === "" ? null : parseDate(this.props.location.state.selectedUserData.ISTENAYRILMATARIHI),
      formData: {
        birim: this.props.location.state.selectedUserData.BIRIM,
        calismasekli: this.props.location.state.selectedUserData.CALISMASEKLI,
        fullname: this.props.location.state.selectedUserData.DISPLAYNAME,
        email: this.props.location.state.selectedUserData.EMAIL,
        faturali: this.props.location.state.selectedUserData.FATURALIMI,
        gorevi: this.props.location.state.selectedUserData.GOREVI,
        idno: this.props.location.state.selectedUserData.IDNO,
        durum: this.props.location.state.selectedUserData.ISACTIVE,
        giristarih: this.props.location.state.selectedUserData.ISEGIRISTARIHI === "" ? null : this.props.location.state.selectedUserData.ISEGIRISTARIHI,
        cikistarih: this.props.location.state.selectedUserData.ISTENAYRILMATARIHI === "" ? null : this.props.location.state.selectedUserData.ISTENAYRILMATARIHI,
        notlar: this.props.location.state.selectedUserData.NOTES,
        phone: this.props.location.state.selectedUserData.PHONENUMBER,
        userid: this.props.location.state.selectedUserData.USERID,
        username: this.props.location.state.selectedUserData.USERNAME
      },
      statusOption: {label: this.props.location.state.selectedUserData.ISACTIVE === "1" ? 'Aktif' : 'Pasif', value: this.props.location.state.selectedUserData.ISACTIVE === "1" ? 1 : 0},
      faturali: this.props.location.state.selectedUserData.FATURALIMI === "1" ? true : false,
      currentUser: null,
      modalYeniAdres:false,
      modalDuzeltAdres:false,
      warningMessageShow: false,
      warningMessageType: "",
      warningMessageText: "",      

    }
  }

  componentDidMount() {
    if (this.props.isLoggedIn === true){
      const bodyElement = document.querySelector('body');
      bodyElement.classList.add('noBodyImage');
      this.props.setCurrent('yolcu-islemleri');
      this.setState({
        ...this.state,
        userAddress:[],
        loadingPopup:true
      });      
      this.props.setLoadingPopup(true);
      this.getPassengerAddress(this.state.formData.userid);

    }
  }

  getPassengerAddress = async (userid,type = "normal") => {
    let url = '';
    if (type === "select"){
      url = "https://azr04.infotraq.com/servisradarsu/get-passenger-address-select";
    }else{
      url = "https://azr04.infotraq.com/servisradarsu/get-passenger-address";
    }
    const res = await axios.post(url,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
        this.props.setLoadingPopup(false);
      }
    } else{
      let address;
      try{
        address = res.data.data;
        if (address === null || address === undefined){
          address = [];
        }
      }catch(ex){
        address = [];
      }
      this.props.setLoadingPopup(false);
      this.setState({...this.state, userAddress: address, loadingPopup:false });
    } 
  }  


  handleChangeFaturali = (value) => {
    this.setState({
      ...this.state,
      faturali: value
    });

  }

  handleGlobalChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        ...this.state,
        formData: {
          ...this.state.formData,
          [name.split("_")[0]]:value
        }
      }
    )
  }

  handleGirisTarihChange = (date) => {
    this.setState({
      ...this.state,
      girisTarih: date
    })
  }

  handleCikisTarihChange = (date) => {
    this.setState({
      ...this.state,
      cikisTarih: date
    })
  }

  handleStatusChange = statusOption => {
    this.setState(
      { statusOption }
    );
    
  };  

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.setLoading(true);
    const form = event.target;
    const data = new FormData(form);
    let jsonPost = {};
    let pass = "";
    let repass = "";
    for (let name of data.keys()) {
      const input = form.elements[name];
      let temp_caption = input.name.split("_")[0];
      let temp_value = input.value;
      jsonPost[temp_caption] = temp_value;
      if (input.required){
        if (input.type === "email"){
          if (input.value === ""){
            this.props.onWarning({warningMessageType:'fail', warningMessageText: input.alt});
            input.focus();
            showWarningLayer();
            this.props.setLoading(false);
            return false;
          }
        }else{
          if (input.value === ""){
            this.props.onWarning({warningMessageType:'fail', warningMessageText: input.alt});
            input.focus();
            showWarningLayer();
            this.props.setLoading(false);
            return false;
          }
        }
        
      }
      
      if (input.type === "password"){
        if (temp_caption === "password"){
          pass = input.value;
        }else if(temp_caption === "repassword"){
          repass = input.value;
          if (pass !== repass){
            this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Şifre alanları aynı değil.'});
            input.focus();
            showWarningLayer();
            this.props.setLoading(false);
            return false;
          }
        }
      }


    }   
    jsonPost.faturali = this.state.faturali === false ? "0" : "1";
    jsonPost.sid = this.props.user.SessionID;
    jsonPost.userid = this.props.location.state.selectedUserData.USERID;
    
    axios.post(`https://azr04.infotraq.com/servisradarsu/update-passenger`,jsonPost,{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      }else if (res.data.message === "DP_Username"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Fullname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ad / soyad alanını kontrol edin.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Password"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanını kontrol edin.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else{
        if (res.data.message === "DP_Done"){
          this.props.setLoading(false);
          this.props.history.push('/yolcu-islemleri');
          this.props.onWarning({warningMessageType:'done', warningMessageText: 'Yolcu güncellendi.'});
          showWarningLayer();
        }else{
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          this.props.setLoading(false);
        }
      }     
    })    
  }


  updateAddress = async () => {
    this.props.setLoadingPopup(true);
    let addressname = this.addressname.value;  
    let address1 = this.address1.value;  
    let address2 = this.address2.value;  
    let semt = this.semt.value;  
    let ilce = this.ilce.value;  
    let postakodu = this.postakodu.value;  
    let locationx = this.locationx.value;  
    let locationy = this.locationy.value;  
    let locationz = this.locationz.value;  

    if (addressname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(true);
      return false;
    }

    if (address1 === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(true);
      return false;
    }


    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "userid": this.state.formData.userid,
        "padrid": this.state.currentAddress.PADRID,
        "addressname": addressname,
        "address1":address1,
        "address2":address2,
        "semt":semt,
        "ilce":ilce,
        "postakodu":postakodu,
        "locationx":locationx,
        "locationy":locationy,
        "locationz":locationz
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Padrid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Addressname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Address1"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz'});
        showWarningLayer();
        this.props.setLoadingPopup(false);
        return false;
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres güncellendi.'});
        showWarningLayer();
        this.getPassengerAddress(this.state.formData.userid);
        this.setState({...this.state,modalDuzeltAdres:false});
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }

  deleteAddress = async (padrid,event) => {
    event.preventDefault();
    this.props.setLoading(true);

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/delete-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "padrid":padrid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Padrid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres silindi.'});
        this.getPassengerAddress(this.state.formData.userid);
        showWarningLayer();
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  } 
  
  insertAddress = async () => {
    this.props.setLoadingPopup(true);
    let addressname = this.addressname.value;  
    let address1 = this.address1.value;  
    let address2 = this.address2.value;  
    let semt = this.semt.value;  
    let ilce = this.ilce.value;  
    let postakodu = this.postakodu.value;  
    let locationx = this.locationx.value;  
    let locationy = this.locationy.value;  
    let locationz = this.locationz.value;  

    if (addressname === ""){
      //this.setState({...this.state,warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz. '})
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (address1 === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }


    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "userid": this.state.formData.userid,
        "addressname": addressname,
        "address1":address1,
        "address2":address2,
        "semt":semt,
        "ilce":ilce,
        "postakodu":postakodu,
        "locationx":locationx,
        "locationy":locationy,
        "locationz":locationz
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoading(false);       
      }else if (res.data.message === "DP_Addressname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Address1"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres eklendi.'});
        showWarningLayer();
        this.getPassengerAddress(this.state.formData.userid);
        this.setState({...this.state,modalYeniAdres:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }  

  handleEditAddress = (otherAddressProps,event) => {
    event.preventDefault();
    this.setState({...this.state, modalDuzeltAdres:true, currentAddress: otherAddressProps});        
  } 

  handleNewAddress = (event) => {
    event.preventDefault();
    this.setState(
      {...this.state,
        currentAddress:{
          ADDRESSNAME: '',
          ADDRESS1: '',
          ADDRESS2: '',
          SEMT: '',
          ILCE: '',
          POSTAKODU: '',
          LOCATIONX: '',
          LOCATIONY: '',
          LOCATIONZ: '',
          PADRID: ''
        },      
        modalYeniAdres:true});    
  }  




  goBack = () => {
    this.props.history.push('/yolcu-islemleri',this.props.location.state);
  }  

  generateID = () => {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16); //eslint-disable-line
    });
    return uuid;
  }  

  onlyNumbers = (e) => {
    const re = /^-?\d*[.,]?\d*$/;
    const inputName = e.target.name.split("_")[0].toUpperCase();
    
    if (e.target.value === '' || re.test(e.target.value)) {
      //console.log(inputName);
      this.setState({
        ...this.state,
        currentAddress:{
          ...this.state.currentAddress,
          [inputName]: e.target.value
        }
      })

    }else{
      return false;
    }    
  }



  render(){
    const durumOptions = [
      { value: 1, label: 'Aktif' },
      { value: 0, label: 'Pasif' }
    ]    
    if (this.props.isLoggedIn === true && this.props.user.UserRoles.includes("SUADMIN")) {
      return(
        <div className="middleID">
          <MediaQuery minWidth={768}>
            <div className="leftID">
              <MainLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
              <NavMenu currentPage={this.props.currentPage} /> 
              <MainCopyright companyName="DocPlant" />
            </div>
            <div className="rightID">
              <TopHeader breadcrumbTitle={this.title} />
              <ContentWrapper>
              
                <div className="card card-body bg-light content-toolbar">
                  <div className="d-flex">
                      <div className="d-flex p-2">
                        <h5 className="toolbarTitle su-color">Düzelt Yolcu &gt; {this.props.location.state.selectedUserData.DISPLAYNAME}</h5>
                      </div>
                      <div className="ml-auto p-2">
                          <div className="d-flex">
                            <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>                
                            <button type="button" className="btn btn-general margin-left-5 userbuttons btn-su margin-left-10" onClick={() => { this.form.dispatchEvent(new Event('submit'))}}><i className="fas fa-save"></i> Kaydet</button>                
                          </div>
                      </div>
                  </div>   
                </div>    
                <div className="contentWrapperBlock">
                  <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen yolcu bilgilerini giriniz. Yeni şifre girilmezse, şifre güncellenmez.</p>
                  <form name={this.formId} autoComplete="off" onSubmit={this.handleSubmit}  ref={ (ref) => { this.form = ref; } }>
                    <div className="row">
                        <div className="col-3">
                            <label htmlFor={this.username} className="window-label">Kullanıcı Adı :<span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                            <input type="text" id={this.username} name={this.username} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen kullanıcı adı giriniz." alt="Lütfen kullanıcı adı alanını kontrol ediniz." value={this.state.formData.username} onChange={(event) => this.handleGlobalChange(event) } required />
                        </div>
                    </div>  
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor={this.fullname} className="window-label">Ad / Soyad : <span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                        <input type="text" id={this.fullname} name={this.fullname} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen ad / soyad giriniz." alt="Lütfen ad / soyad alanını kontrol ediniz." value={this.state.formData.fullname} onChange={(event) => this.handleGlobalChange(event) } required />
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.password} className="window-label">Şifre :</label>
                        </div>
                        <div className="col-7">
                        <input type="password" id={this.password} name={this.password} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen şifre giriniz." alt="Lütfen şifre alanını kontrol ediniz." onChange={(event) => this.handleGlobalChange(event) } />
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.repassword} className="window-label">Şifre Tekrar :</label>
                        </div>
                        <div className="col-7">
                        <input type="password" id={this.repassword} name={this.repassword} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen şifre tekrar giriniz." alt="Lütfen şifre tekrar alanını kontrol ediniz." onChange={(event) => this.handleGlobalChange(event) }  />
                        </div>
                    </div>    
                    <div className="clearfix"></div>                                        
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.email} className="window-label">E-posta : <span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                          <input type="email" id={this.email} name={this.email} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen e-posta adresi giriniz." alt="Lütfen e-posta alanını kontrol ediniz." value={this.state.formData.email} onChange={(event) => this.handleGlobalChange(event) }  required/>
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.phone} className="window-label">Telefon Numarası :</label>
                        </div>
                        <div className="col-7">
                          <input type="tel" id={this.phone} name={this.phone} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen telefon numarası giriniz." value={this.state.formData.phone} onChange={(event) => this.handleGlobalChange(event) } />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.idno} className="window-label">Sicil Numarası :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.idno} name={this.idno} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen sicil numarası giriniz." value={this.state.formData.idno} onChange={(event) => this.handleGlobalChange(event) } />
                        </div>
                    </div> 
                    <div className="clearfix"></div>  
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.birim} className="window-label">Birim :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.birim} name={this.birim} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen birim giriniz." value={this.state.formData.birim} onChange={(event) => this.handleGlobalChange(event) } />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.gorevi} className="window-label">Görevi :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.gorevi} name={this.gorevi} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen görevi giriniz." value={this.state.formData.gorevi} onChange={(event) => this.handleGlobalChange(event) }  />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.calismasekli} className="window-label">Çalışma Şekli :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.calismasekli} name={this.calismasekli} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control edit max-width-element" placeholder="Lütfen çalışma şekli giriniz." value={this.state.formData.calismasekli} onChange={(event) => this.handleGlobalChange(event) }  />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor="faturali" className="window-label">Faturalı mı ? :</label>
                        </div>
                        <div className="col-7">
                          <div className="checkbox">
                                <label className="checkbox-bootstrap checkbox-lg">                                        
                                    <input id="faturali" name="faturali" type="checkbox" checked={this.state.faturali} onChange={(event) => this.handleChangeFaturali(event.target.checked)} /> 
                                    <span className="checkbox-placeholder formcheck"></span>           
                                </label>
                          </div>                        
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor={this.giristarih} className="window-label">İşe Giriş Tarihi :</label>
                        </div>
                        <div className="col-7">
                          <DatePicker 
                            id={this.giristarih}
                            name={this.giristarih}
                            className="form-control edit max-width-element"
                            selected={this.state.girisTarih} 
                            onChange={this.handleGirisTarihChange} 
                            locale={tr}
                            placeholderText="Lütfen işe giriş tarihi giriniz."
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor={this.cikistarih} className="window-label">İşten Çıkış Tarihi :</label>
                        </div>
                        <div className="col-7">
                          <DatePicker 
                            id={this.cikistarih}
                            name={this.cikistarih}
                            className="form-control edit max-width-element"
                            selected={this.state.cikisTarih} 
                            onChange={this.handleCikisTarihChange} 
                            locale={tr}
                            placeholderText="Lütfen işten ayrılma tarihi giriniz."
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.durum} className="window-label">Durum :</label>
                        </div>
                        <div className="col-7">
                          <Select id={this.durum} name={this.durum} className="edit max-width-element" placeholder="Durum" options={durumOptions} defaultValue={{label: 'Aktif', value: 1}} value={this.state.statusOption} onChange={this.handleStatusChange} />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.notlar} className="window-label">Notlar :</label>
                        </div>
                        <div className="col-7">
                            <textarea id={this.notlar} name={this.notlar} autoCorrect="off" spellCheck="false" autoComplete="off" className="form-control textbox max-width-element" placeholder="" value={this.state.formData.notlar} onChange={(event) => this.handleGlobalChange(event) }></textarea>
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3 adreslerCol">
                            <label className="window-label">Adresler </label>
                            <Button type="button" className="btn-adresyeni" onClick={(event)=> this.handleNewAddress(event)} variant="warning">Yeni</Button>
                        </div>
                        <div className="col-7">
                          <div className="row addressRow">
                          {
                            this.state.loadingPopup === true ? <LoadingIndicator/> :
                            this.state.userAddress.length > 0 ? (
                                this.state.userAddress.map(({PASSENGERID, ...otherAddressProps},index) => (
                                  <div key={index} className="col-md-6">
                                      <div className="bg-white card addresses-item mb-4 border border-light shadow">
                                          <div className="gold-members p-4">
                                              <div className="media">
                                                  <div className="media-body">
                                                      <h6 className="mb-1 text-secondary">{otherAddressProps.ADDRESSNAME}</h6>
                                                      <p className="text-black address-line">{otherAddressProps.ADDRESS1}</p>
                                                      <p className="text-black address-line">{otherAddressProps.ADDRESS2}</p>
                                                      <p className="text-black">{otherAddressProps.SEMT}, {otherAddressProps.ILCE} {otherAddressProps.POSTAKODU}</p>
                                                      <p className="text-black"><b>X:</b> <span className="addressx">{otherAddressProps.LOCATIONX}</span> | <b>Y:</b> <span className="addressy">{otherAddressProps.LOCATIONY}</span> | <b>Z:</b> <span className="addressz">{otherAddressProps.LOCATIONZ}</span> </p>
                                                      <p className="mb-0 text-black font-weight-bold"><button type="button" className="btn btn-light" onClick={(event) => this.handleEditAddress(otherAddressProps,event)}><i className="icofont-ui-edit"></i> Düzelt</button> <button type="button" className="btn btn-light text-danger" onClick={(event) => this.deleteAddress(otherAddressProps.PADRID,event)}><i className="icofont-ui-delete" ></i> Sil</button></p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>                   
                                )                    
                              )
                            ):
                            <p className="adresKayitYok">Kayıtlı adres yok.</p>
                          }
                          </div>
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                  </form>
                </div>
                <PopupModal
                  show={this.state.modalYeniAdres}
                  onHide={() => this.setState({...this.state,modalYeniAdres:false})}
                  titlemodal={`Yeni Adres Ekle - ${this.state.formData.fullname}`}
                  onClick={()=> this.insertAddress}
                >
              
              <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen adres bilgilerini giriniz.</p>
              <form id={this.new_formAddress} autoComplete="off" className="address-form" name={this.new_formAddress}>
              <div className="form-row address-row">
                <div className="col-md-12 mb-12">
                  <label htmlFor={this.new_addressname}>Adres adı :</label>
                  <input type="text" className="form-control" id={this.new_addressname} autoCorrect="off" spellCheck="false" autoComplete="off"  ref={(ref)=>{ this.addressname = ref;}} placeholder="Adres adı zorunlu"  required />
              </div>
              </div>
              <div className="form-row address-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor={this.new_address1}>Adres satır 1 :</label>
                  <input type="text" className="form-control" id={this.new_address1} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.address1 = ref;}}  placeholder="Adres satır 1 zorunlu" required />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor={this.new_address2}>Adres satır 2 :</label>
                  <input type="text" className="form-control" id={this.new_address2} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.address2 = ref;}} placeholder="Adres satır 2" />
                </div>
              </div>
              <div className="form-row address-row">
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.new_semt}>Semt :</label>
                  <input type="text" className="form-control" id={this.new_semt} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.semt = ref;}} placeholder="Semt"  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.new_ilce}>İlçe :</label>
                  <input type="text" className="form-control" id={this.new_ilce} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.ilce = ref;}} placeholder="İlçe" />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.new_postakodu}>Posta Kodu :</label>
                  <input type="text" className="form-control" id={this.new_postakodu} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.postakodu = ref;}} placeholder="Posta Kodu"  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.new_locationx}>Lokasyon X :</label>
                  <input type="text" className="form-control" id={this.new_locationx} name={this.new_locationx} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.locationx = ref;}} placeholder="Lokasyon X" value={this.state.currentAddress.LOCATIONX}  onChange={this.onlyNumbers} />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.new_locationy}>Lokasyon Y :</label>
                  <input type="text" className="form-control" id={this.new_locationy} name={this.new_locationy} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.locationy = ref;}} placeholder="Lokasyon Y" value={this.state.currentAddress.LOCATIONY}  onChange={this.onlyNumbers}  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.new_locationz}>Lokasyon Z :</label>
                  <input type="text" className="form-control" id={this.new_locationz} name={this.new_locationz} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.locationz = ref;}} placeholder="Lokasyon Z" value={this.state.currentAddress.LOCATIONZ}  onChange={this.onlyNumbers}  />
                </div>
              </div>              
  
              </form>                
              <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessageText} warningType={this.props.warningMessageType} />
              <LoadingLayer showLoading={this.props.showLoadingPopup} />
              
                
            </PopupModal> 
            
              <PopupModal
                show={this.state.modalDuzeltAdres}
                onHide={() => this.setState({...this.state,modalDuzeltAdres:false})}
                titlemodal={`Adres Düzelt - ${this.state.currentAddress.ADDRESSNAME === null ? '' : this.state.currentAddress.ADDRESSNAME }`}
                onClick={()=> this.updateAddress}
                state={this.state}
                
              >
              
              <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen adres bilgilerini giriniz.</p>
              <form id={this.edit_formAddress} className="address-form" name={this.edit_formAddress}>
              <div className="form-row address-row">
                <div className="col-md-12 mb-12">
                  <label htmlFor={this.edit_addressname}>Adres adı :</label>
                  <input type="text" className="form-control" id={this.edit_addressname} autoCorrect="off" spellCheck="false" autoComplete="off"  ref={(ref)=>{ this.addressname = ref;}} placeholder="Adres adı zorunlu" defaultValue={this.state.currentAddress.ADDRESSNAME}  required />
              </div>
              </div>
              <div className="form-row address-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor={this.edit_address1}>Adres satır 1 :</label>
                  <input type="text" className="form-control" id={this.edit_address1} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.address1 = ref;}}  placeholder="Adres satır 1 zorunlu" defaultValue={this.state.currentAddress.ADDRESS1} required />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor={this.edit_address2}>Adres satır 2 :</label>
                  <input type="text" className="form-control" id={this.edit_address2} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.address2 = ref;}} placeholder="Adres satır 2" defaultValue={this.state.currentAddress.ADDRESS2}  />
                </div>
              </div>
              <div className="form-row address-row">
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.edit_semt}>Semt :</label>
                  <input type="text" className="form-control" id={this.edit_semt} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.semt = ref;}} placeholder="Semt" defaultValue={this.state.currentAddress.SEMT} />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.edit_ilce}>İlçe :</label>
                  <input type="text" className="form-control" id={this.edit_ilce} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.ilce = ref;}} placeholder="İlçe" defaultValue={this.state.currentAddress.ILCE}  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.edit_postakodu}>Posta Kodu :</label>
                  <input type="text" className="form-control" id={this.edit_postakodu} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.postakodu = ref;}} placeholder="Posta Kodu" defaultValue={this.state.currentAddress.POSTAKODU}   />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.edit_locationx}>Lokasyon X :</label>
                  <input type="text" className="form-control" id={this.edit_locationx} name={this.edit_locationx} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.locationx = ref;}} placeholder="Lokasyon X" defaultValue={this.state.currentAddress.LOCATIONX} value={this.state.currentAddress.LOCATIONX}  onChange={this.onlyNumbers}  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.edit_locationy}>Lokasyon Y :</label>
                  <input type="text" className="form-control" id={this.edit_locationy} name={this.edit_locationy} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.locationy = ref;}} placeholder="Lokasyon Y" defaultValue={this.state.currentAddress.LOCATIONY} value={this.state.currentAddress.LOCATIONY}  onChange={this.onlyNumbers}  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor={this.edit_locationz}>Lokasyon Z :</label>
                  <input type="text" className="form-control" id={this.edit_locationz} name={this.edit_locationz} autoCorrect="off" spellCheck="false" autoComplete="off" ref={(ref)=>{ this.locationz = ref;}} placeholder="Lokasyon Z" defaultValue={this.state.currentAddress.LOCATIONZ} value={this.state.currentAddress.LOCATIONZ}  onChange={this.onlyNumbers}  />
                </div>
              </div>              
              </form>                
              <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessageText} warningType={this.props.warningMessageType} />
              <LoadingLayer showLoading={this.props.showLoadingPopup} />
              
            </PopupModal>           
              </ContentWrapper>
            </div>
            <div id="jqueryconfirm"></div>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div className="mobileWrapper">
              <MobileHeader />
              <ContentWrapper>
                Mobil görünüm henüz desteklenmiyor.
              </ContentWrapper>
            </div>
            <div id="jqueryconfirm"></div>
          </MediaQuery>
  
       </div>
      );      
    } else{
      return <Redirect push to="/servis-bilgisi" />;
    }     


  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    currentPage: state.project.currentPage,
    warningMessageShow: state.project.warningMessageShow,
    warningMessageType: state.project.warningMessageType,
    warningMessageText: state.project.warningMessageText,
    showLoadingPopup:state.login.showLoadingPopup,
    user: state.login.User,
    breadcrumbTitle: state.project.breadcrumbTitle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value }),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setBreadcrumbTitle: (value) => dispatch({type: ProjectActionTypes.SET_BREADCRUMB_TITLE, value: value }),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(DuzeltYolcu);