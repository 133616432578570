import React from 'react';

import './spacer.styles.scss';

const Spacer = () => (
  <div>
    <div className="clearfix"></div>
    <div className="freeSpace"></div>
  </div>
);

export default Spacer