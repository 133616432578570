import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
//import { showWarningLayer } from '../../utils/utils';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import axios from 'axios';

import './other-passengers.styles.scss';

class OtherPassengers extends React.Component {
  constructor(props){
    super(props);
    //console.log(this.props.location);
    this.ServiceID = this.props.location.state.SERVICEID;
    this.ServiceName = this.props.location.state.SERVICENAME;
    this.state = {
      loading: false,
      serviceInfo: [],
      selectedServiceID: null,
      passengers: [],
      selectedPassengerID: null
    }
  }

  componentDidMount(){
    this.getPassengersAll();
  }

  getPassengersAll = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-passengers-others`,{ "sid": this.props.user.SessionID,"srid":this.ServiceID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let passengers;
        try{
          passengers = res.data.data;
          if (passengers === null || passengers === undefined){
            passengers = [];
          }
        }catch(ex){
          passengers = [];
        }
        
        this.setState({...this.state, passengers: passengers, loading:false });
      }     
    })
  }


  goBack = () => {
    this.props.history.push('/servis-bilgisi');
  }   


  render(){
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 300,
      noDataText: 'Gösterilecek kayıt yok'
    };    

    //console.log(this.state.activeServices);
    return(
      <div className="otherPassengerContainer">
      {
        <div className="card card-body bg-light content-toolbar">
          <div className="d-flex">
              <div className="p-2 otherPassengerTitleWrapper">
                <h5 className="otherPassengerTitle">{this.ServiceName}</h5>
              </div>
              <div className="ml-auto p-2">
                <div className="d-flex">
                  <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>
                </div>              
              </div>
          </div>   
        </div> 
      }
      <div>
      {
        this.state.loading ? <LoadingIndicator /> :
        this.state.passengers.length > 0 ? (
          <BootstrapTable data={ this.state.passengers } containerClass='passengers-list-others' striped hover options={options} pagination>
            <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
            <TableHeaderColumn dataField='DISPLAYNAME' width="250px" dataSort={ true }>Ad / Soyad</TableHeaderColumn>
            <TableHeaderColumn dataField='IDNO' width="100px" dataSort={ true }>Sicil No</TableHeaderColumn>
            <TableHeaderColumn dataField='EMAIL' width="300px" dataSort={ true }>E-Posta</TableHeaderColumn>
            <TableHeaderColumn dataField='PHONENUMBER' width="200px" dataSort={ true }>Telefon Numarası</TableHeaderColumn>
          </BootstrapTable>          
        ) : 
        <p>Gösterilecek kayıt yok.</p>
      }

      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(OtherPassengers));