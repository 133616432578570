import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import Routes from '../../components/routes/routes.component';
import MainLogo from '../../components/main-logo/main-logo.component';
import TopHeader from '../../components/header/header.component';
import MobileHeader from '../../components/mobile-header/mobile-header.component';
import ContentWrapper from '../../components/content-wrapper/content-wrapper.component';
import NavMenu from '../../components/nav-menu/nav-menu.component';
import MainCopyright from '../../components/main-copyright/main-copyright.component';
import { ProjectActionTypes } from '../../redux/project/project.types';


import '../../assets/styles/edms-main.scss';
import '../../assets/styles/edms-overrides.scss';
import './rota-tanimlari.styles.scss';

class RotaTanimlari extends React.Component {
  constructor(props){
    super(props);
    this.title = "Yolcu İşlemleri > Rota Tanımları ("+this.props.location.state.selectedUserData.DISPLAYNAME+")";
    //console.log(this.props.location.state);
  }

  componentDidMount() {
    if (this.props.isLoggedIn === true){
      const bodyElement = document.querySelector('body');
      bodyElement.classList.add('noBodyImage');
      //console.log(this.props.user);
      //this.props.setBreadcrumbTitle(this.title);
      this.props.setCurrent('yolcu-islemleri');
    }
  }



 
  render(){
    if (this.props.isLoggedIn === true && this.props.user.UserRoles.includes("SUADMIN")) {
      return(
        <div className="middleID">
          <MediaQuery minWidth={768}>
            <div className="leftID">
              <MainLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
              <NavMenu currentPage={this.props.currentPage} /> 
              <MainCopyright companyName="DocPlant" />
            </div>
            <div className="rightID">
              <TopHeader breadcrumbTitle={this.title} />
              <ContentWrapper>
                <Routes pagedata={this.props.location.state} />
              </ContentWrapper>
            </div>
            <div id="jqueryconfirm"></div>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div className="mobileWrapper">
              <MobileHeader />
              <ContentWrapper>
                Mobil görünüm henüz desteklenmiyor.
              </ContentWrapper>
            </div>
            <div id="jqueryconfirm"></div>
          </MediaQuery>
  
       </div>
      );      
    } else{
      return <Redirect push to="/servis-bilgisi" />;
    }     


  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    currentPage: state.project.currentPage,
    warningMessageShow: state.project.warningMessageShow,
    warningMessageType: state.project.warningMessageType,
    warningMessageText: state.project.warningMessageText,
    user: state.login.User,
    breadcrumbTitle: state.project.breadcrumbTitle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value }),
    setBreadcrumbTitle: (value) => dispatch({type: ProjectActionTypes.SET_BREADCRUMB_TITLE, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(RotaTanimlari);