import React from 'react';
import ReactDOM from 'react-dom';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import PopupModal from '../../components/popup-modal/popup-modal.component';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import { showWarningLayer, showWarningLayerPopup, hideWarningLayerPopup, formatNow } from '../../utils/utils';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css';
import './passengers.styles.scss';
import '../../assets/styles/react-bootstrap-table-all.min.css';
import '../../assets/styles/react-contextmenu.css';

//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action}>
        <p className="text-center passwordInfo"><b>{text}</b></p>
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class Passengers extends React.Component {
  constructor(props){
    super(props);
    this.excelData = [];
    this.state = {
      loading: false,
      loadingPopup:false,
      loadingModal: false,
      loadingModalx:true,
      selectedPassengerID: null,
      modalShow: false,
      modalNewUserShow:false,
      modalEditUserShow:false,
      modalRotaTasima:false,
      modalServisTasima:false,
      modalAdresTanimlari:false,
      modalRotaTanimlari:false,
      modalYeniAdres:false,
      modalDuzeltAdres:false,
      activeRouteDisable:true,
      activeServiceDisable:true,
      userRotes:[],
      userAddress:[],
      userRouteAddress:[],
      selectedOptionService:null,
      selectedOption: null,
      selectedOptionActiveRoute: null,
      selectedOptionActiveService: null,
      selectedOptionAddress:null,
      activeRoutes:[],
      activeServices:[],
      selectedUserID:null,
      selectedUser:"",
      selectedUserData: this.props.location.state !== null && this.props.location.state !== undefined ? this.props.location.state.selectedUserData : null,
      isRowSelected: this.props.location.state !== null && this.props.location.state !== undefined ? true : false,
      rotaData:[],
      servisData:[],
      tabIndex: this.props.location.state !== null && this.props.location.state !== undefined ? this.props.location.state.tabIndex : 0,
      tabDisabled:true,
      isFilterDisable:true,
      currentAddress:{
        ADDRESSNAME: '',
        ADDRESS1: '',
        ADDRESS2: '',
        SEMT: '',
        ILCE: '',
        POSTAKODU: '',
        LOCATIONX: '',
        LOCATIONY: '',
        LOCATIONZ: '',
        PADRID: ''
      },
      columnFilterData:this.props.location.state !== null && this.props.location.state !== undefined ? this.props.location.state.columnFilterData : {}
    }

  }

  // shouldComponentUpdate(nextProps, nextState){
  //   //return this.state.columnFilterData !== nextState.columnFilterData;
  // }

  componentDidMount(){
    let ptype = "";
    if (this.state.tabIndex === 0){
      ptype = "active";
    }else{
      ptype = "deactive";
    }    
    this.getPassengers(ptype);
  }

  insertAddress = async () => {
    this.props.setLoadingPopup(true);
    let addressname = this.addressname.value;  
    let address1 = this.address1.value;  
    let address2 = this.address2.value;  
    let semt = this.semt.value;  
    let ilce = this.ilce.value;  
    let postakodu = this.postakodu.value;  
    let locationx = this.locationx.value;  
    let locationy = this.locationy.value;  
    let locationz = this.locationz.value;  

    if (addressname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (address1 === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }


    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "userid": this.state.selectedUserID,
        "addressname": addressname,
        "address1":address1,
        "address2":address2,
        "semt":semt,
        "ilce":ilce,
        "postakodu":postakodu,
        "locationx":locationx,
        "locationy":locationy,
        "locationz":locationz
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Addressname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Address1"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres eklendi.'});
        showWarningLayerPopup();
        this.getPassengerAddress(this.state.selectedUserID);
        this.setState({...this.state,modalYeniAdres:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }

  handleGlobalAddressChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        ...this.state,
        currentAddress: {
          ...this.state.currentAddress,
          [name.toUpperCase()]:value
        }
      }
    )
  }  


  updateAddress = async () => {
    this.props.setLoading(true);
    let addressname = this.addressname.value;  
    let address1 = this.address1.value;  
    let address2 = this.address2.value;  
    let semt = this.semt.value;  
    let ilce = this.ilce.value;  
    let postakodu = this.postakodu.value;  
    let locationx = this.locationx.value;  
    let locationy = this.locationy.value;  
    let locationz = this.locationz.value;  

    if (addressname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz. '});
      showWarningLayer();
      this.props.setLoading(true);
      return false;
    }

    if (address1 === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz. '});
      showWarningLayer();
      this.props.setLoading(true);
      return false;
    }


    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "userid": this.state.selectedUserID,
        "padrid": this.state.currentAddress.PADRID,
        "addressname": addressname,
        "address1":address1,
        "address2":address2,
        "semt":semt,
        "ilce":ilce,
        "postakodu":postakodu,
        "locationx":locationx,
        "locationy":locationy,
        "locationz":locationz
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Padrid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Addressname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Address1"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres güncellendi.'});
        showWarningLayer();
        this.getPassengerAddress(this.state.selectedUserID);
        this.setState({...this.state,modalDuzeltAdres:false});
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }

  deleteAddress = async (padrid) => {
    this.props.setLoading(true);

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/delete-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "padrid":padrid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Padrid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres silindi.'});
        this.getPassengerAddress(this.state.selectedUserData.USERID);
        showWarningLayer();
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }


  handleFilterSearch = (value) => {
    try{
      this.refs.displaynameCol.applyFilter(value);
    }catch(ex){}
    
  }
  
  //...
  handleChangeUserRoute = async (selectedOption) => {
    this.props.setLoadingPopup(true);
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-userotherroutes`,{ "sid": this.props.user.SessionID, "uid": this.state.selectedUserID,"rid": selectedOption.value},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let routes;
      try{
        routes = res.data.data;
        if (routes === null || routes === undefined){
          routes = [];
        }
      }catch(ex){
        routes = [];
      }
      this.props.setLoadingPopup(false);
      this.setState({...this.state, loading:false, activeRoutes: routes,activeRouteDisable:false,selectedOptionActiveRoute:null, selectedOption });
    } 
  };

//...
  handleChangeUserService = async (selectedOptionService) => {
    this.props.setLoadingPopup(true);
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-active-services`,{ "sid": this.props.user.SessionID, "srdate": selectedOptionService.srdate,"srtime": selectedOptionService.srtime},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let services;
      try{
        services = res.data.data;
        if (services === null || services === undefined){
          services = [];
        }
      }catch(ex){
        services = [];
      }
      this.props.setLoadingPopup(false);
      this.setState({...this.state, loading:false, activeServices: services,activeServiceDisable:false, selectedOptionService });
    } 
  };



  handleChangeActiveRoute = selectedOptionActiveRoute => {
    this.setState(
      { ...this.state, selectedOptionActiveRoute }
    );
  };

  handleChangeActiveService = selectedOptionActiveService => {
    this.setState(
      { ...this.state, selectedOptionActiveService }
    );
  };  

  handleChangeAddress = selectedOptionAddress => {
    this.setState(
      { ...this.state, selectedOptionAddress }
    );
  };  

  getPassengers = (ptype = "active") => {
    this.props.setProcessFinished(true);
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-${ptype}-passengers`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let passengers;
        try{
          passengers = res.data.data;
          if (passengers === null || passengers === undefined){
            passengers = [];
          }
        }catch(ex){
          passengers = [];
        }
        
        this.props.setExcelData(passengers);
        this.setState({...this.state, loading:false, tabDisabled:false, isFilterDisable: false });
        this.props.setPassenger(passengers);
        this.props.setProcessFinished(false);
      }     
    })
  }

  getUserRoutes = async (userid) => {
    
    // this.setState({
    //   ...this.state,
    //   loadingModal:true
    // });

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-userroutes`,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let routes;
      try{
        routes = res.data.data;
        if (routes === null || routes === undefined){
          routes = [];
        }
      }catch(ex){
        routes = [];
      }

      this.props.setLoadingPopup(false);
      this.setState({...this.state, userRotes: routes });
    } 
  }

  moveRouteService = async () => {
    this.props.setLoadingPopup(true);
    const srid = this.state.selectedOption === null || this.state.selectedOption.value === "" ? null : this.state.selectedOption.value;
    const trid = this.state.selectedOptionActiveRoute === null || this.state.selectedOptionActiveRoute.value === "" ? null : this.state.selectedOptionActiveRoute.value;
    if (srid === null || srid === undefined || srid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı rotası seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (trid === null || trid === undefined || trid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen aktif rota seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/move-user-route`,{ "sid": this.props.user.SessionID, "uid": this.state.selectedUserID,"srid":srid, "trid":trid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      this.props.setLoadingPopup(false);
      this.setState({...this.state,modalShow:false});
      this.props.onWarning({warningMessageType:'success', warningMessageText: 'Rota değiştirildi. '});
      showWarningLayer();
      this.props.setLoading(false);
      
    }     
  }

  moveRoute = async () => {
    this.props.setLoadingPopup(true);
    const srid = this.state.selectedOption === null || this.state.selectedOption.value === "" ? null : this.state.selectedOption.value;
    const trid = this.state.selectedOptionActiveRoute === null || this.state.selectedOptionActiveRoute.value === "" ? null : this.state.selectedOptionActiveRoute.value;
    if (srid === null || srid === undefined || srid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı rotası seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (trid === null || trid === undefined || trid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen aktif rota seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/move-passenger-route`,{ "sid": this.props.user.SessionID, "uid": this.state.selectedUserID,"srid":srid, "trid":trid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
        this.props.setLoadingPopup(false);
      }else if (res.data.message === "DP_Userid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz. '});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.setState({...this.state,modalRotaTasima:false});
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Rota taşındı. '});
        showWarningLayer();
        this.props.setLoadingPopup(false);
      }else{
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
        showWarningLayer();
        this.props.setLoadingPopup(false);
      }
    }     
  }

  moveService = async () => {
    let oldid,oldbusid,newid,newbusid,padrid;
    try{
      oldid = this.state.selectedOptionService.serviceid;
      oldbusid = this.state.selectedOptionService.busid;
      newid = this.state.selectedOptionActiveService.serviceid;
      newbusid = this.state.selectedOptionActiveService.busid;
      padrid = this.state.selectedOptionAddress.value;
    }catch(ex){
      oldid = ""
      oldbusid = "";
      newid = "";
      newbusid = "";
      padrid = "";
      
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen seçim yapıp, tekrar deneyiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }
    this.props.setLoadingPopup(true);
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/move-passenger-service`,{ "sid": this.props.user.SessionID, "uid": this.state.selectedUserID,"oldid":oldid, "oldbusid":oldbusid, "newid": newid, "newbusid": newbusid,"padrid":padrid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Userid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz. '});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.setState({...this.state,modalServisTasima:false});
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Servis taşındı. '});
        showWarningLayer();
      }else{
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }
    }     
  }


  getPassengerAddress = async (userid,type = "normal") => {
    let url = '';
    if (type === "select"){
      url = "https://azr04.infotraq.com/servisradarsu/get-passenger-address-select";
    }else{
      url = "https://azr04.infotraq.com/servisradarsu/get-passenger-address";
    }
    const res = await axios.post(url,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
        this.props.setLoadingPopup(false);
      }
    } else{
      let address;
      try{
        address = res.data.data;
        if (address === null || address === undefined){
          address = [];
        }
      }catch(ex){
        address = [];
      }
      this.props.setLoadingPopup(false);
      this.setState({...this.state, userAddress: address, loadingPopup:false });
    } 
  }  

  getUserServices = async (userid) => {
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-active-passenger-services`,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let services;
      try{
        services = res.data.data;
        if (services === null || services === undefined){
          services = [];
        }
      }catch(ex){
        services = [];
      }
      this.props.setLoadingPopup(false);
      this.setState({...this.state, userServices: services });
      this.getPassengerAddress(this.state.selectedUserData.USERID,'select');
    } 
  }


  handleRowSelect = (row, isSelected, e) => {
    this.setState(
      {
      ...this.state,
      selectedUserData: row,
      isRowSelected: isSelected
      }
      );
  }  

  goTo = (page,currentData) => {
    if (page === "kullanici-servisleri"){
      this.props.history.push('/yolcu-islemleri-kullanici-servisleri',currentData);
    }else if (page === "yolcu-ekle"){
      this.props.history.push('/yolcu-islemleri-yolcu-ekle',currentData);
    }else if (page ==="yolcu-duzelt"){
      this.props.history.push('/yolcu-islemleri-yolcu-duzelt',currentData);
    }else if (page ==="rota-tanimlari"){
      this.props.history.push('/rota-tanimlari',currentData);
    }
  } 

  handleNewUser = () => {
    this.goTo('yolcu-ekle',null);               
  }

  handleEditUser = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }    
    this.goTo('yolcu-duzelt',{
      selectedUserData: this.state.selectedUserData,
      columnFilterData: this.state.columnFilterData,
      tabIndex: this.state.tabIndex
    });     
  }

  handleDeleteUser = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }    

    confirmDialog(true,'changepassword','Kullanıcıyı silmek istediğinize emin misiniz ?',() => this.deleteUser())();    

  }

  deleteUser = () => {
    this.props.setLoading(true);
    axios.post(`https://azr04.infotraq.com/servisradarsu/delete-passenger`,{ "sid": this.props.user.SessionID, "uid": this.state.selectedUserData.USERID },{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }else if (res.data.message === "DP_Username"){
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin.'});
          showWarningLayer();
          this.props.setLoading(false);
        }else if (res.data.message === "DP_Fullname"){
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ad / soyad alanını kontrol edin.'});
          showWarningLayer();
          this.props.setLoading(false);
        }else if (res.data.message === "DP_Password"){
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanını kontrol edin.'});
          showWarningLayer();
          this.props.setLoading(false);
        }else{
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          this.props.setLoading(false);
        }
      } else{
        if (res.data.message === "DP_Done"){
          this.props.setLoading(false);
          if (this.state.selectedUserData.ISACTIVE === "1"){
            this.getPassengers("active");
          }else{
            this.getPassengers("deactive");
          }
          //this.state.selectedUserData.
          this.props.onWarning({warningMessageType:'done', warningMessageText: 'Yolcu silindi.'});
          showWarningLayer();
        }else{
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          this.props.setLoading(false);
        }
        
      }     
    }) 
  }


  resetPassword = () => {
    this.props.setLoading(true);
    axios.post(`https://azr04.infotraq.com/servisradarsu/reset-password`,{ "sid": this.props.user.SessionID, "uid": this.state.selectedUserData.USERID, "passx":this.state.selectedUserData.PASSWORDX, "email": this.state.selectedUserData.EMAIL },{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }else if (res.data.message === "DP_Userid"){
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçin edin.'});
          showWarningLayer();
          this.props.setLoading(false);
        }else{
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          this.props.setLoading(false);
        }
      } else{
        if (res.data.message === "DP_Done"){
          this.props.setLoading(false);
          // if (this.state.selectedUserData.ISACTIVE === "1"){
          //   this.getPassengers("active");
          // }else{
          //   this.getPassengers("deactive");
          // }
          //this.state.selectedUserData.
          this.props.onWarning({warningMessageType:'done', warningMessageText: 'Yolcu şifresi sıfırlandı.'});
          showWarningLayer();
        }else{
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          this.props.setLoading(false);
        }
        
      }     
    })    

    
  }

  handleResetPassword = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }    
        
    confirmDialog(true,'changepassword','Kullanıcı şifresi sıfırlanacak. Emin misiniz ?',() => this.resetPassword())();
  }

  handleNewAddress = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayerPopup();
      return;      
    }    
    if (this.state.selectedUserData !== null){
      this.setState({...this.state, selectedUserID: this.state.selectedUserData.USERID, selectedUser: this.state.selectedUserData.DISPLAYNAME, modalYeniAdres:true});    
    }
  }

  handleEditAddress = (otherAddressProps) => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayerPopup();
      return;      
    }    
    if (this.state.selectedUserData !== null){
      this.setState({...this.state, selectedUserID: this.state.selectedUserData.USERID, selectedUser: this.state.selectedUserData.DISPLAYNAME, modalDuzeltAdres:true, currentAddress: otherAddressProps});    
    }
  }  

  handleMoveRouteService = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }    
    if (this.state.selectedUserData !== null){
      this.props.setLoadingPopup(true);
      this.getUserRoutes(this.state.selectedUserData.USERID); 
      this.setState({...this.state, selectedUserID: this.state.selectedUserData.USERID, selectedUser: this.state.selectedUserData.DISPLAYNAME, selectedOptionActiveRoute:null,activeRoutes:[],activeRouteDisable:true, modalShow:true});    
    }
  }

  handleMoveRoute = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }    

    if (this.state.selectedUserData !== null){
      this.props.setLoadingPopup(true);
      this.getUserRoutes(this.state.selectedUserData.USERID); 
      this.setState({...this.state, selectedUserID: this.state.selectedUserData.USERID, selectedUser: this.state.selectedUserData.DISPLAYNAME,selectedOptionActiveRoute:null,activeRoutes:[],activeRouteDisable:true, modalRotaTasima:true});    
    }    



  }

  handleMoveService = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }     
    
    if (this.state.selectedUserData !== null){
      this.setState({...this.state,userAddress: []});
      this.props.setLoadingPopup(true);
      this.getUserServices(this.state.selectedUserData.USERID); 
      this.setState({...this.state, selectedUserID: this.state.selectedUserData.USERID, selectedUser: this.state.selectedUserData.DISPLAYNAME,selectedOptionActiveService:null, activeServices:[], activeServiceDisable:true,userAddress:[], selectedOptionAddress:null, modalServisTasima:true});    
    }      
    
  }

  handleAddress = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }   

    if (this.state.selectedUserData !== null){
      this.setState({
        ...this.state,
        userAddress:[],
        loadingPopup:true
      });      
      this.props.setLoadingPopup(true);
      this.getPassengerAddress(this.state.selectedUserData.USERID);
      this.setState({...this.state, selectedUserID: this.state.selectedUserData.USERID, selectedUser: this.state.selectedUserData.DISPLAYNAME, modalAdresTanimlari:true});    
    }     
    
    
  }

  handleRoutes = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }    
    this.goTo('rota-tanimlari',{
      selectedUserData: this.state.selectedUserData,
      columnFilterData: this.state.columnFilterData,
      tabIndex: this.state.tabIndex
    });     

    //this.goTo('rota-tanimlari',this.state.selectedUserData);     
  }

  handleUserServices = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı seçiniz.'});
      showWarningLayer();
      return;      
    }
    this.goTo('kullanici-servisleri',{
      selectedUserData: this.state.selectedUserData,
      columnFilterData: this.state.columnFilterData,
      tabIndex: this.state.tabIndex
    });        
    //this.goTo('kullanici-servisleri',this.state.selectedUserData);      
  }





  onDualChangeRota = (dualSelectedRota) => {
    this.setState({...this.state, dualSelectedRota});
  }

  onDualChangeServis = (dualSelectedServis) => {
    this.setState({...this.state, dualSelectedServis});
  }

  onTabSelect = (tabIndex) => {
    this.props.setPassenger([]);
    if (tabIndex === 0){
      this.getPassengers('active');
    }else{
      this.getPassengers('deactive');
    }
    this.setState({...this.state, loading:true, tabIndex , tabDisabled:true, isRowSelected:false, isFilterDisable:true });
    
  }

  //Column Templates
  suColumnType = (cell, row, type) => {
    if (type === "faturalimi"){
      return cell === "1" ? '<span class="badge badge-success columnInfo">Evet</span>' : '<span class="badge badge-primary columnInfo">Hayır</span>'
    }else if (type === "isactive"){
      return cell === "1" ? '<span class="badge badge-success columnInfo">Aktif</span>' : '<span class="badge badge-danger columnInfo">Pasif</span>'
    }else if (type === "vardiyami"){
      return cell === "1" ? '<span class="badge badge-success columnInfo">Evet</span>' : '<span class="badge badge-primary columnInfo">Hayır</span>'
    }

  }  

  afterColumnFilter = (filterConds, result) => {
    //console.log(filterConds);
    const tempData = this.props.excelData;
    if (JSON.stringify(tempData) !== JSON.stringify(result)){
      this.props.setExcelData(result);
    }

    if (JSON.stringify(this.state.columnFilterData) !== JSON.stringify(filterConds)){    
      this.setState({
        ...this.state,
        columnFilterData: filterConds
      });
    }
  }

  render(){
    let tempDate = formatNow(new Date(),"YYYY-MM-DD");
    const excelFileName = tempDate;
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 50,
      noDataText: 'Gösterilecek kayıt yok',
      afterColumnFilter: this.afterColumnFilter,
    };    
    const selectRow = {
      mode: 'radio',  // multi select
      clickToSelect: true,
      className: 'row-select-class',
      bgColor: '#004288',
      Color:'white',
      onSelect: this.handleRowSelect,
      selected: this.state.selectedUserData !== null ? [this.state.selectedUserData.USERID] : []      
    };   
    const footerData = [
      [
        {
          label: '',
          columnIndex: 2,
          align: 'left',
          formatter: (tableData) => {
            //let total = tableData.length;
            let total = this.props.excelData.length;
            return (
              <strong>Toplam : { total } kişi</strong>
            );
          }
          
        }
      ]
    ];    
    const faturaliMiTypes = {
      0: 'Hayır',
      1: 'Evet'
    };

    const vardiyaMiTypes = {
      0: 'Hayır',
      1: 'Evet'
    }; 

    const isActiveTypes = {
      0: 'Pasif',
      1: 'Aktif'
    };    
    
    return(
      <div className="passengersPageWrapper">
        <div className="SplitterPanelLeft">
          <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.onTabSelect(tabIndex)}>
            <TabList>
              <Tab disabled={this.state.loading === true ? true : this.state.tabDisabled}><b>Aktif Kullanıcılar</b></Tab>
              <Tab disabled={this.state.loading === true ? true : this.state.tabDisabled}><b>Aktif Olmayan Kullanıcılar</b></Tab>
              <div className={`tabToolbar ${this.state.tabDisabled ? ' hidden' : ''}`}>
                <div className="p-2">
                  <button type="button" className="btn btn-sm btn-general btn-light p-2 margin-left-5 userbuttons minibuttons" data-tip="Yeni Kullanıcı" onClick={() => this.handleNewUser()}><i className="fas fa-user-plus"></i></button>
                  <button type="button" className="btn btn-sm btn-general btn-light p-2 margin-left-5 userbuttons minibuttons " data-tip="Kullanıcı Düzelt" onClick={() => this.handleEditUser()}><i className="fas fa-user-edit"></i> </button>
                  <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Kullanıcı Sil" onClick={() => this.handleDeleteUser()}><i className="fas fa-user-times"></i> </button>
                  <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons" data-tip="Kullanıcı Şifresi Sıfırla" onClick={() => this.handleResetPassword()}><i className="fas fa-key"></i></button>
                  <div className="toolbar-sep borderRight"></div> 
                  <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons colorOrange" data-tip="Güzergah Değişikliği" onClick={() => this.handleMoveRouteService()}><i className="fas fa-route"></i></button>
                  <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons colorOrange" data-tip="Günlük Servis Değişikliği" onClick={() => this.handleMoveService()}><i className="fas fa-bus-alt"></i></button>
                  <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons colorOrange" data-tip="Rota Tanımları" onClick={() => this.handleRoutes()}><i className="fas fa-map"></i></button>
                  <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons colorOrange" data-tip="Kullanıcı Servisleri" onClick={() => this.handleUserServices()}><i className="fas fa-bus"></i></button>
                  <ReactHTMLTableToExcel  
                      className={`btn btn-sm btn-general btn-light margin-left-5 minibuttons colorGreen`}  
                      table="emp-yolcu-islemleri"
                      filename={`${excelFileName} ${this.state.tabIndex === 0 ? 'Aktif Kullanıcılar' : 'Aktif Olmayan Kullanıcılar'}`}  
                      sheet={excelFileName + this.state.tabIndex === 0 ? 'Aktif Kullanıcılar' : 'Aktif Olmayan Kullanıcılar'}  
                      buttonText="XLS"
                      data-tip="Excel'e Aktar"
                    />                    
                </div>
              </div>

            </TabList>
      
            <TabPanel> 
            <ContextMenuTrigger id="submenu1" holdToDisplay={1000}>            
            {
              this.state.loading ? <LoadingIndicator /> :
              this.props.passengers.length > 0 ? (
                <BootstrapTable data={ this.props.passengers } container className='passengers-list yolcu-islemleri-table' hover options={options}  selectRow={ selectRow } footerData={ footerData } footer pagination >
                  <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='USERNAME' width='300px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.USERNAME === undefined ? '' : this.state.columnFilterData.USERNAME.value} }  dataSort={ true } headerAlign='center'>Kullanıcı Adı</TableHeaderColumn>
                  <TableHeaderColumn dataField='DISPLAYNAME' width='300px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.DISPLAYNAME === undefined ? '' : this.state.columnFilterData.DISPLAYNAME.value } } dataSort={ true } headerAlign='center' >Ad / Soyad</TableHeaderColumn>
                  <TableHeaderColumn dataField='EMAIL' width='300px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.EMAIL === undefined ? '' : this.state.columnFilterData.EMAIL.value } } dataSort={ true } headerAlign='center'>E-Posta</TableHeaderColumn>
                  <TableHeaderColumn dataField='PHONENUMBER' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Telefon Numarası</TableHeaderColumn>
                  <TableHeaderColumn dataField='IDNO' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.IDNO === undefined ? '' : this.state.columnFilterData.IDNO.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Sicil No</TableHeaderColumn>
                  <TableHeaderColumn dataField='BIRIM' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.BIRIM === undefined ? '' : this.state.columnFilterData.BIRIM.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Birim</TableHeaderColumn>
                  <TableHeaderColumn dataField='GOREVI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.GOREVI === undefined ? '' : this.state.columnFilterData.GOREVI.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Görevi</TableHeaderColumn>
                  <TableHeaderColumn dataField='CALISMASEKLI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.CALISMASEKLI === undefined ? '' : this.state.columnFilterData.CALISMASEKLI.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Çalışma Şekli</TableHeaderColumn>
                  <TableHeaderColumn dataField='FATURALIMI' width='130px' dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ faturaliMiTypes } filter={ { type: 'SelectFilter', options: faturaliMiTypes, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' :Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.FATURALIMI === undefined ? '' : this.state.columnFilterData.FATURALIMI } } dataFormat={(cell,row) => this.suColumnType(cell,row,'faturalimi') }>Faturalı Mı</TableHeaderColumn>
                  <TableHeaderColumn dataField='VARDIYAMI' width='130px' dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ vardiyaMiTypes } filter={ { type: 'SelectFilter', options: vardiyaMiTypes, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' :Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.VARDIYAMI === undefined ? '' : this.state.columnFilterData.VARDIYAMI } } dataFormat={(cell,row) => this.suColumnType(cell,row,'vardiyami') }>Vardiya Mı</TableHeaderColumn>
                  <TableHeaderColumn dataField='ISEGIRISTARIHI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' :Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.ISEGIRISTARIHI === undefined ? '' : this.state.columnFilterData.ISEGIRISTARIHI.value } } dataAlign='center' headerAlign='center'  dataSort={ true }>İşe Giriş Tarihi</TableHeaderColumn>
                  <TableHeaderColumn dataField='ISTENAYRILMATARIHI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.ISTENAYRILMATARIHI === undefined ? '' : this.state.columnFilterData.ISTENAYRILMATARIHI.value } } dataAlign='center' headerAlign='center'  dataSort={ true }>İşten Ayrılma Tarihi</TableHeaderColumn>
                  <TableHeaderColumn dataField='ISACTIVE' width='100px' dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ isActiveTypes } filter={ { type: 'SelectFilter', options: isActiveTypes, defaultValue: this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.ISACTIVE === undefined ? '' : this.state.columnFilterData.ISACTIVE.value } } dataFormat={(cell,row) => this.suColumnType(cell,row,'isactive') }>Durum</TableHeaderColumn>
                  <TableHeaderColumn dataField='NOTES' width='200px' dataSort={ true }>Notlar</TableHeaderColumn>
              </BootstrapTable>          
              ) : 
              <p className="padding15">Gösterilecek kayıt yok.</p>
            }              
            </ContextMenuTrigger>
            </TabPanel>
            
            <TabPanel>
            <ContextMenuTrigger id="submenu2" holdToDisplay={1000}>   
            {
              this.state.loading ? <LoadingIndicator /> :
              this.props.passengers.length > 0 ? (
                <BootstrapTable data={ this.props.passengers } container className='passengers-list yolcu-islemleri-table' hover options={options} pagination selectRow={ selectRow } footerData={ footerData } footer >
                  <TableHeaderColumn dataField='USERID' isKey={true} hidden>ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='USERNAME' width='300px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.USERNAME === undefined ? '' : this.state.columnFilterData.USERNAME.value} }  dataSort={ true } headerAlign='center'>Kullanıcı Adı</TableHeaderColumn>
                  <TableHeaderColumn dataField='DISPLAYNAME' width='300px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.DISPLAYNAME === undefined ? '' : this.state.columnFilterData.DISPLAYNAME.value } } dataSort={ true } headerAlign='center' >Ad / Soyad</TableHeaderColumn>
                  <TableHeaderColumn dataField='EMAIL' width='300px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.EMAIL === undefined ? '' : this.state.columnFilterData.EMAIL.value } } dataSort={ true } headerAlign='center'>E-Posta</TableHeaderColumn>
                  <TableHeaderColumn dataField='PHONENUMBER' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Telefon Numarası</TableHeaderColumn>
                  <TableHeaderColumn dataField='IDNO' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.IDNO === undefined ? '' : this.state.columnFilterData.IDNO.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Sicil No</TableHeaderColumn>
                  <TableHeaderColumn dataField='BIRIM' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.BIRIM === undefined ? '' : this.state.columnFilterData.BIRIM.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Birim</TableHeaderColumn>
                  <TableHeaderColumn dataField='GOREVI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.GOREVI === undefined ? '' : this.state.columnFilterData.GOREVI.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Görevi</TableHeaderColumn>
                  <TableHeaderColumn dataField='CALISMASEKLI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.CALISMASEKLI === undefined ? '' : this.state.columnFilterData.CALISMASEKLI.value } } dataSort={ true } dataAlign='center' headerAlign='center'>Çalışma Şekli</TableHeaderColumn>
                  <TableHeaderColumn dataField='FATURALIMI' width='130px' dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ faturaliMiTypes } filter={ { type: 'SelectFilter', options: faturaliMiTypes, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' :Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.FATURALIMI === undefined ? '' : this.state.columnFilterData.FATURALIMI } } dataFormat={(cell,row) => this.suColumnType(cell,row,'faturalimi') }>Faturalı Mı</TableHeaderColumn>
                  <TableHeaderColumn dataField='VARDIYAMI' width='130px' dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ vardiyaMiTypes } filter={ { type: 'SelectFilter', options: vardiyaMiTypes, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' :Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.VARDIYAMI === undefined ? '' : this.state.columnFilterData.VARDIYAMI } } dataFormat={(cell,row) => this.suColumnType(cell,row,'vardiyami') }>Vardiya Mı</TableHeaderColumn>
                  <TableHeaderColumn dataField='ISEGIRISTARIHI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' :Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.ISEGIRISTARIHI === undefined ? '' : this.state.columnFilterData.ISEGIRISTARIHI.value } } dataAlign='center' headerAlign='center'  dataSort={ true }>İşe Giriş Tarihi</TableHeaderColumn>
                  <TableHeaderColumn dataField='ISTENAYRILMATARIHI' width='200px' filter={ { type: 'TextFilter', delay: 1000, defaultValue:this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.ISTENAYRILMATARIHI === undefined ? '' : this.state.columnFilterData.ISTENAYRILMATARIHI.value } } dataAlign='center' headerAlign='center'  dataSort={ true }>İşten Ayrılma Tarihi</TableHeaderColumn>
                  <TableHeaderColumn dataField='ISACTIVE' width='100px' dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ isActiveTypes } filter={ { type: 'SelectFilter', options: isActiveTypes, defaultValue: this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.ISACTIVE === undefined ? '' : this.state.columnFilterData.ISACTIVE.value } } dataFormat={(cell,row) => this.suColumnType(cell,row,'isactive') }>Durum</TableHeaderColumn>
                  <TableHeaderColumn dataField='NOTES' width='200px' dataSort={ true }>Notlar</TableHeaderColumn>
              </BootstrapTable>          
              ) : 
              <p className="padding15">Gösterilecek kayıt yok.</p>
            }              
            </ContextMenuTrigger>
            </TabPanel>       
          </Tabs>
          <ContextMenu id="submenu1">
            <MenuItem onClick={() => this.handleEditUser()}>
              <i className="fas fa-user-edit"></i> <span className="contextMenuText">Kullanıcı Düzelt</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleDeleteUser()}>
              <i className="fas fa-user-times"></i> <span className="contextMenuText">Kullanıcı Sil</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleResetPassword()}>
              <i className="fas fa-key"></i> <span className="contextMenuText">Kullanıcı Şifresi Sıfırla</span>
            </MenuItem>
            <MenuItem divider />
            <MenuItem onClick={() => this.handleMoveRouteService()}>
              <i className="fas fa-route"></i> <span className="contextMenuText">Güzergah Değişikliği</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleMoveService()}>
              <i className="fas fa-bus-alt"></i> <span className="contextMenuText">Günlük Servis Değişikliği</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleRoutes()}>
              <i className="fas fa-map"></i> <span className="contextMenuText">Rota Tanımları</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleUserServices()}>
              <i className="fas fa-bus"></i> <span className="contextMenuText">Kullanıcı Servisleri</span>
            </MenuItem>

          </ContextMenu>
          <ContextMenu id="submenu2">
            <MenuItem onClick={() => this.handleEditUser()}>
              <i className="fas fa-user-edit"></i> <span className="contextMenuText">Kullanıcı Düzelt</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleDeleteUser()}>
              <i className="fas fa-user-times"></i> <span className="contextMenuText">Kullanıcı Sil</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleResetPassword()}>
              <i className="fas fa-key"></i> <span className="contextMenuText">Kullanıcı Şifresi Sıfırla</span>
            </MenuItem>
            <MenuItem divider />
            <MenuItem onClick={() => this.handleMoveRouteService()}>
              <i className="fas fa-route"></i> <span className="contextMenuText">Güzergah Değişikliği</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleMoveService()}>
              <i className="fas fa-bus-alt"></i> <span className="contextMenuText">Günlük Servis Değişikliği</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleRoutes()}>
              <i className="fas fa-map"></i> <span className="contextMenuText">Rota Tanımları</span>
            </MenuItem>
            <MenuItem onClick={() => this.handleUserServices()}>
              <i className="fas fa-bus"></i> <span className="contextMenuText">Kullanıcı Servisleri</span>
            </MenuItem>
          </ContextMenu>                               
          {
            <table id="emp-yolcu-islemleri" className="excel-table">  
            <tbody>             
              <tr>
                <td><b>Kullanıcı Adı {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.USERNAME !== undefined && '*'}</b></td>
                <td><b>Ad / Soyad {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.DISPLAYNAME !== undefined && '*'}</b></td>
                <td><b>E-Posta {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.EMAIL !== undefined && '*'}</b></td>
                <td><b>Telefon Numarası</b></td>
                <td><b>Sicil No {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.IDNO !== undefined && '*'}</b></td>
                <td><b>Birim {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.BIRIM !== undefined && '*'}</b></td>
                <td><b>Görevi {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.GOREVI !== undefined && '*'}</b></td>
                <td><b>Çalışma Şekli {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.CALISMASEKLI !== undefined && '*'}</b></td>
                <td><b>Faturalı Mı ? {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.FATURALIMI !== undefined && '*'}</b></td>
                <td><b>Vardiya Mı ? {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.VARDIYAMI !== undefined && '*'}</b></td>
                <td><b>İşe Giriş Tarihi {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.ISEGIRISTARIHI !== undefined && '*'}</b></td>
                <td><b>İşten Ayrılma Tarihi {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.ISTENAYRILMATARIHI !== undefined && '*'}</b></td>
                <td><b>Durum {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' :this.state.columnFilterData.ISACTIVE !== undefined && '*'}</b></td>
                <td><b>Notlar</b></td>
              </tr>                                   
                { 
                  this.props.excelData.map((p, index) => {  
                      return (
                      <tr key={index}>  
                        <td >{p.USERNAME}</td>  
                        <td >{p.DISPLAYNAME}</td>  
                        <td >{p.EMAIL}</td>  
                        <td >{p.PHONENUMBER}</td>  
                        <td >{p.IDNO}</td>  
                        <td >{p.BIRIM}</td>  
                        <td >{p.GOREVI}</td>  
                        <td >{p.CALISMASEKLI}</td>  
                        <td >{p.FATURALIMI === "0" ? "Hayır" : "Evet" }</td>  
                        <td >{p.VARDIYAMI === "0" ? "Hayır" : "Evet" }</td>  
                        <td >{p.USERNAME}</td>  
                        <td >{p.ISEGIRISTARIHI}</td>  
                        <td >{p.ISTENAYRILMATARIHI}</td>  
                        <td >{p.ISACTIVE === "1" ? "Aktif" : "Pasif"}</td>  
                        <td >{p.NOTES}</td>  
                      </tr>  
                      )
                  })  
                }  
                <tr>
                <td><b>Toplam</b></td>
                <td><b>{this.props.excelData.length}</b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>  
              </tbody>  
              </table>  
              
          }          
                  


        </div>
            <PopupModal
                show={this.state.modalShow}
                onHide={() => this.setState({...this.state,modalShow:false})}
                titlemodal={`Güzergah Değişikliği - ${this.state.selectedUser}`}
                onClick={()=> this.moveRouteService}>
                <p className="rotalabel">Kullanıcı Rotaları</p> 
                <Select
                  placeholder="Rota seçiniz"
                  value={this.selectedOption}
                  onChange={this.handleChangeUserRoute}
                  options={this.state.userRotes}
                />
                <br/>
                <p className="rotalabel">Aktif Rotalar</p> 
                <Select
                  placeholder="Rota seçiniz"
                  value={this.selectedOptionActiveRoute}
                  options={this.state.activeRoutes}
                  onChange={this.handleChangeActiveRoute}
                  isDisabled={this.state.activeRouteDisable}
                />
              <LoadingLayer showLoading={this.props.showLoadingPopup} />
              <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
            </PopupModal>      
            <PopupModal
                show={this.state.modalRotaTasima}
                onHide={() => this.setState({...this.state,modalRotaTasima:false})}
                titlemodal={`Rota Taşıma - ${this.state.selectedUser}`}
                onClick={()=> this.moveRoute}>
                <p className="rotalabel">Kullanıcı Rotaları</p> 
                <Select
                  placeholder="Rota seçiniz"
                  value={this.selectedOption}
                  onChange={this.handleChangeUserRoute}
                  options={this.state.userRotes}
                />
                <br/>
                <p className="rotalabel">Aktif Rotalar</p> 
                <Select
                  placeholder="Rota seçiniz"
                  value={this.selectedOptionActiveRoute}
                  options={this.state.activeRoutes}
                  onChange={this.handleChangeActiveRoute}
                  isDisabled={this.state.activeRouteDisable}
                />
                <LoadingLayer showLoading={this.props.showLoadingPopup} />
                <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
            </PopupModal>      
            <PopupModal
              show={this.state.modalServisTasima}
              onHide={() => this.setState({...this.state,modalServisTasima:false})}
              titlemodal={`Günlük Servis Değişikliği - ${this.state.selectedUser}`}
              onClick={()=> this.moveService}>
              <p className="rotalabel">Kullanıcı Servisleri</p> 
              <Select
                placeholder="Servis seçiniz"
                value={this.selectedOptionService}
                onChange={this.handleChangeUserService}
                options={this.state.userServices}
              />
              <br/>
              <p className="rotalabel">Tüm Servisler</p> 
              <Select
                placeholder="Servis seçiniz"
                value={this.selectedOptionActiveService}
                options={this.state.activeServices}
                onChange={this.handleChangeActiveService}
                isDisabled={this.state.activeServiceDisable}
              />
              <br/>
              <p className="rotalabel">Yolcu Adresi</p> 
              <Select
                placeholder="Adres seçiniz"
                value={this.state.selectedOptionAddress}
                options={this.state.userAddress}
                onChange={this.handleChangeAddress}
                
              />
              <LoadingLayer showLoading={this.props.showLoadingPopup} />
              <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
            </PopupModal>            
            <PopupModal
              show={this.state.modalAdresTanimlari}
              onHide={() => this.setState({...this.state,modalAdresTanimlari:false})}
              titlemodal={`Adres Tanımları - ${this.state.selectedUser}`}
              onClick={()=> this.handleNewAddress}
              isaddress="true"
              >
              <div className="row addressRow">
              {
                this.state.loadingPopup === true ? <LoadingIndicator/> :
                this.state.userAddress.length > 0 ? (
                  
                    this.state.userAddress.map(({PASSENGERID, ...otherAddressProps},index) => (
                      <div key={index} className="col-md-6">
                          <div className="bg-white card addresses-item mb-4 border border-light shadow">
                              <div className="gold-members p-4">
                                  <div className="media">
                                      <div className="media-body">
                                          <h6 className="mb-1 text-secondary">{otherAddressProps.ADDRESSNAME}</h6>
                                          <p className="text-black address-line">{otherAddressProps.ADDRESS1}</p>
                                          <p className="text-black address-line">{otherAddressProps.ADDRESS2}</p>
                                          <p className="text-black">{otherAddressProps.SEMT}, {otherAddressProps.ILCE} {otherAddressProps.POSTAKODU}</p>
                                          <p className="text-black"><b>X:</b> <span className="addressx">{otherAddressProps.LOCATIONX}</span> | <b>Y:</b> <span className="addressy">{otherAddressProps.LOCATIONY}</span> | <b>Z:</b> <span className="addressz">{otherAddressProps.LOCATIONZ}</span> </p>
                                          <p className="mb-0 text-black font-weight-bold"><button className="btn btn-light" onClick={() => this.handleEditAddress(otherAddressProps)}><i className="icofont-ui-edit"></i> Düzelt</button> <button className="btn btn-light text-danger" onClick={() => this.deleteAddress(otherAddressProps.PADRID)}><i className="icofont-ui-delete" ></i> Sil</button></p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>                   
                    )                    
                  )
                ):
                <p className="modalNoRecord">Gösterilecek kayıt yok.</p>
              }

   
                                          
              </div>
              <LoadingLayer showLoading={this.props.showLoadingPopup} />
              <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
            </PopupModal>

            <PopupModal
              show={this.state.modalYeniAdres}
              onHide={() => this.setState({...this.state,modalYeniAdres:false})}
              titlemodal={`Yeni Adres Ekle - ${this.state.selectedUser}`}
              onClick={()=> this.insertAddress}
            >
            
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen adres bilgilerini giriniz.</p>
            <form className="address-form" name="address-form">
            <div className="form-row address-row">
              <div className="col-md-12 mb-12">
                <label htmlFor="addressname">Adres adı :</label>
                <input type="text" className="form-control" id="addressname"  ref={(ref)=>{ this.addressname = ref;}} placeholder="Adres adı zorunlu"  required />
            </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="address1">Adres satır 1 :</label>
                <input type="text" className="form-control" id="address1" ref={(ref)=>{ this.address1 = ref;}}  placeholder="Adres satır 1 zorunlu" required />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="address2">Adres satır 2 :</label>
                <input type="text" className="form-control" id="address2" ref={(ref)=>{ this.address2 = ref;}} placeholder="Adres satır 2" />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="semt">Semt :</label>
                <input type="text" className="form-control" id="semt" ref={(ref)=>{ this.semt = ref;}} placeholder="Semt"  />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="ilce">İlçe :</label>
                <input type="text" className="form-control" id="ilce" ref={(ref)=>{ this.ilce = ref;}} placeholder="İlçe" />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="postakodu">Posta Kodu :</label>
                <input type="text" className="form-control" id="postakodu" ref={(ref)=>{ this.postakodu = ref;}} placeholder="Posta Kodu"  />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="locationx">Lokasyon X :</label>
                <input type="text" className="form-control" id="locationx" ref={(ref)=>{ this.locationx = ref;}} placeholder="Lokasyon X" />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="locationy">Lokasyon Y :</label>
                <input type="text" className="form-control" id="locationy" ref={(ref)=>{ this.locationy = ref;}} placeholder="Lokasyon Y" />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="locationz">Lokasyon Z :</label>
                <input type="text" className="form-control" id="locationz" ref={(ref)=>{ this.locationz = ref;}} placeholder="Lokasyon Z" />
              </div>
            </div>              

            </form>                

            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
          </PopupModal> 
          
            <PopupModal
              show={this.state.modalDuzeltAdres}
              onHide={() => this.setState({...this.state,modalDuzeltAdres:false})}
              titlemodal={`Adres Düzelt - ${this.state.currentAddress.ADDRESSNAME === null ? '' : this.state.currentAddress.ADDRESSNAME }`}
              onClick={()=> this.updateAddress}
              state={this.state}
              
            >
            
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen adres bilgilerini giriniz.</p>
            <form className="address-form" name="address-form">
            <div className="form-row address-row">
              <div className="col-md-12 mb-12">
                <label htmlFor="addressname">Adres adı :</label>
                <input type="text" className="form-control" id="addressname"  ref={(ref)=>{ this.addressname = ref;}} placeholder="Adres adı zorunlu" defaultValue={this.state.currentAddress.ADDRESSNAME}  required />
            </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-3">
                <label htmlFor="address1">Adres satır 1 :</label>
                <input type="text" className="form-control" id="address1" ref={(ref)=>{ this.address1 = ref;}}  placeholder="Adres satır 1 zorunlu" defaultValue={this.state.currentAddress.ADDRESS1} required />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="address2">Adres satır 2 :</label>
                <input type="text" className="form-control" id="address2" ref={(ref)=>{ this.address2 = ref;}} placeholder="Adres satır 2" defaultValue={this.state.currentAddress.ADDRESS2}  />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="semt">Semt :</label>
                <input type="text" className="form-control" id="semt" ref={(ref)=>{ this.semt = ref;}} placeholder="Semt" defaultValue={this.state.currentAddress.SEMT} />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="ilce">İlçe :</label>
                <input type="text" className="form-control" id="ilce" ref={(ref)=>{ this.ilce = ref;}} placeholder="İlçe" defaultValue={this.state.currentAddress.ILCE}  />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="postakodu">Posta Kodu :</label>
                <input type="text" className="form-control" id="postakodu" ref={(ref)=>{ this.postakodu = ref;}} placeholder="Posta Kodu" defaultValue={this.state.currentAddress.POSTAKODU}   />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="locationx">Lokasyon X :</label>
                <input type="text" className="form-control" id="locationx" ref={(ref)=>{ this.locationx = ref;}} placeholder="Lokasyon X" defaultValue={this.state.currentAddress.LOCATIONX}  />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="locationy">Lokasyon Y :</label>
                <input type="text" className="form-control" id="locationy" ref={(ref)=>{ this.locationy = ref;}} placeholder="Lokasyon Y" defaultValue={this.state.currentAddress.LOCATIONY}  />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="locationz">Lokasyon Z :</label>
                <input type="text" className="form-control" id="locationz" ref={(ref)=>{ this.locationz = ref;}} placeholder="Lokasyon Z" defaultValue={this.state.currentAddress.LOCATIONZ}  />
              </div>
            </div>              
            </form>                
            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
          </PopupModal>           
          

            <ReactTooltip /> 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    isProcessFinished: state.login.isProcessFinished,
    error: state.login.error,
    passengers: state.passenger.passengers,
    formaddress: state.passenger.formAddress,
    showLoadingPopup:state.login.showLoadingPopup,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    excelData: state.passenger.excelData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setProcessFinished: (isProcessFinished) => dispatch({ type: LoginActionTypes.SET_PROCESS_FINISHED, isProcessFinished: isProcessFinished}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setPassenger: (passengers) => dispatch({ type: PassengerActionTypes.SET_PASSENGERS, passengers: passengers}),
    setAddress: (payload) => dispatch({ type: PassengerActionTypes.SET_ADDRESS_FORM, payload: payload}),
    setExcelData: (excelData) => dispatch({ type: PassengerActionTypes.SET_EXCEL_DATA, excelData: excelData})
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Passengers));