import React from 'react';
import './warning-layer.styles.scss';

const WarningLayer = ({warningType, text, hideWarning}) => (
    <div className="warningLayer warning-hide">
        <div className="warningInfo">
            <img className="warningImg" src={require(`../../assets/images/${warningType === 'success' ? 'success.png' : 'warning.png'}`)} alt="" />
            <div className="warningText new-line">{text}</div>
        </div>
        <button type="button" onClick={hideWarning}> <img className="closeImg" src={require(`../../assets/images/warning-close.png`)} title="close" alt="close" /></button>
    </div>
);

export default WarningLayer;