import React from 'react';

import './content-wrapper.styles.scss';

const ContentWrapper = (props) => {
  return(
    <div className={`contentWrapper ${props.classname}`}>
      {props.children}
    </div>
  )
}

export default ContentWrapper;