import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
//import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { showWarningLayer, customShowLoadingLayer, customHideLoadingLayer,showWarningLayerCustom } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import axios from 'axios';

import './service-info.styles.scss';

class ServiceInfo extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      firstLoading:false,
      serviceInfo: [],
      driverInfo: null,
      passengers: [],
      selectedServiceID: null
    }

  }

  componentDidMount(){
    if (this.props.user.UserRoles.includes("SUDRIVER")){
      this.getServiceInfoDriver();
    }else{
      this.getServiceInfo();
    }
  }

  getServiceInfo = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-nextservice-details`,{ "uid": this.props.user.UserID, "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let serviceDetails;
        try{
          serviceDetails = res.data.data;
          if (serviceDetails === null || serviceDetails === undefined){
            serviceDetails = [];
          }
        }catch(ex){
          serviceDetails = [];
        }
        
        //console.log(serviceDetails);
        //console.log(services);
        this.setState({...this.state, loading:false, serviceInfo: serviceDetails });

        let warnmessage = "";
        warnmessage = 'DİKKAT \n Rezervasyonunuz olması halinde güzergah değişikliğinizi "Servisler / Rezervasyonlar" bölümünden yapabilirsiniz.';
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayerCustom();
        customHideLoadingLayer(); 

      }     
    })
  }

  getServiceInfoDriver = () => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-driver-service-details`,{ "uid": this.props.user.UserID, "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let serviceDetails,passengers;
        try{
          serviceDetails = res.data.data;
          if (serviceDetails === null || serviceDetails === undefined){
            serviceDetails = null;
          }
        }catch(ex){
          serviceDetails = null;
        }
        
        try{
          passengers = res.data.passengers;
          if (passengers === null || passengers === undefined){
            passengers = [];
          }
        }catch(ex){
          passengers = [];
        }        

        this.setState({...this.state, loading:false, driverInfo: serviceDetails, passengers: passengers });
        customHideLoadingLayer();        
      }     
    })
  }


  handleSwitchChange = async (checked,serviceid,passengerid) => {
    customShowLoadingLayer();
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/";
    if (checked){
      dataUrl+="passenger-service-in";
    }else{
      dataUrl+="passenger-service-in-cancel";
    }
    
    const response = await axios.post(dataUrl,{ "pid": passengerid, "sid": this.props.user.SessionID, "serid": serviceid},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        //this.props.setLoading(false);
        this.getServiceInfoDriver();
      }else{
        let warnmessage = "";
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();
        customHideLoadingLayer(); 
        //this.props.setLoading(false);
        this.forceUpdate();
      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      //this.props.setLoading(false);
      this.forceUpdate();
    }


    // let dataUrl = "https://azr04.infotraq.com/servisradarsu/";
    // if (checked){
    //   dataUrl+="passenger-service-in";
    // }else{
    //   dataUrl+="passenger-service-in-cancel";
    // }

    // axios.post(dataUrl,{"pid": passengerid, "sid": this.props.user.SessionID, "serid": serviceid},{crossDomain: true})
    // .then(res => {
    //   if (res.data.type === "success"){
    //     this.getServiceInfoDriver();
    //   } else{
    //     let warnmessage = "";
    //     warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
    //     this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
    //     showWarningLayer();
    //   }     
    // })    



  }


  goTo = (event,serviceid,servicename) => {
    event.preventDefault();
    const data = { SERVICEID: serviceid, SERVICENAME:servicename};
    //this.props.setLoading(true);
    return this.props.history.push('/diger-yolcular',data);    
  }  


  render(){
    let tempLink = "";
    let showMap = true;
    if (this.state.driverInfo !== null){
      try{
        tempLink = this.state.driverInfo.SERVICELINK.split("?");
        tempLink = tempLink[1].split("&");
        tempLink = tempLink[0].split("=");      
      }catch(ex){
        showMap = false;
      }
    }
    return(
      <div>
    {
      this.props.user.UserRoles.includes("SUDRIVER") ?
      <div>
        {
          this.state.firstLoading ? <LoadingIndicator/> :
          this.state.driverInfo !== null ? (
            <div className="container">
            <div className="row-fluid">
            {
              showMap === true &&
              <div className="span8">
                <iframe title="routeDetails" width="100%" height="350" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://www.google.com/maps/d/embed?mid=${tempLink[1]}`} />
              </div>
            }
                <div className="span8">
                  {
                    showMap === true &&
                    <span className="bottom-line d-block mx-auto mt-3 mb-4"></span>
                  }
                  
                  <div className="serviceDetailsInfo">
                    <h4 className="text-center su-color">{this.state.driverInfo.SERVICEDATE}</h4>
                    <h5 className="text-center">{this.state.driverInfo.SERVICENAME}</h5>
                    {
                      showMap === true &&
                      <div className="d-flex justify-content-center">
                        <a href={this.state.driverInfo.SERVICELINK} rel="noopener noreferrer" target="_blank" className="btn btn-primary btn-diger-yolcular margin-bottom-15">Haritayı Aç</a>
                      </div>
                    }
                  </div>
                  {

                    this.state.passengers.length > 0 ? (
                      <Table className="tableServisler" responsive>
                        <thead>
                          <tr>
                            <th>Yolcu Adı</th>
                            <th className="text-center">Sicil No</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            
                          this.state.passengers.map(({YOLCU,SICILNO,...otherServiceProps},index) => (
                            <tr key={index}>
                                <td>{YOLCU}</td>
                                <td className="text-center">{SICILNO}</td>
                                {
                                // <td className="text-center">
                                
                                //   // <BootstrapSwitchButton 
                                //   //   checked={otherServiceProps.ACTIONSTAT === "3" ? true : false}
                                //   //   onlabel={"Geldi"} 
                                //   //   offlabel={"Gelmedi"}
                                //   //   onstyle={"success"}
                                //   //   offstyle={"danger"} 
                                //   //   onChange={(checked) => this.handleSwitchChange(checked,otherServiceProps.SERVICEID,otherServiceProps.PASSENGERID)}
                                //   // />  
                                
                                // </td>
                                }
                            </tr>
                            )
                          )
                          }
                        </tbody>
                      </Table>
                    ) : 
                    <p className="text-center">Serviste yolcu bulunmuyor.</p>                              
                  }

                </div>
            </div>
            
        </div>
          ) : 
          <div>
            <p>Gösterilecek servis yok.</p>
            <button onClick={() => this.props.history.push('/servisler')} className="btn btn-sulink">Servisler</button>        
          </div>    
        }          


      </div>
      :
        this.state.loading ? <LoadingIndicator/> :
        this.state.serviceInfo.length > 0 ? (
              this.state.serviceInfo.map(({SERVICENAME,SERVICEDATE,BUSPLATE,BUSDRIVER,DRIVERPHONE,SERVICESTAT,ROUTETYPE,SERVICELINK,SERVICEID},index) => {
                let showMapX = true;
                let tempLink = "";
                try{
                  tempLink = SERVICELINK.split("?");
                  tempLink = tempLink[1].split("&");
                  tempLink = tempLink[0].split("=");
                }catch(ex){
                  showMapX = false;
                } 
                return(
                  <div key={SERVICEID} className="container">
                    <div className="row-fluid">
                    {
                      showMapX === true &&
                      <div className="span8">
                        <iframe title="routeDetails" width="100%" height="350" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://www.google.com/maps/d/embed?mid=${tempLink[1]}`} />
                      </div>
                    }
                    {
                      showMapX === true &&                    
                      <span className="bottom-line d-block mx-auto mt-3 mb-4"></span>
                    }
                      <div className="serviceDetailsInfo">
                        <h4 className="text-center su-color">{SERVICEDATE}</h4>
                        <h2 className="text-center">{SERVICENAME}</h2>
                        
                        <div className="row my-2 mx-auto">
                        <div className="col-6 text-right border-right border-dark border-dark-su">
                            <strong>Servis Yönü</strong>
                        </div>
                        <div className="col-6 pl-4">
                          {ROUTETYPE === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"}
                        </div>
                      </div>
                      <div className="row my-2 mx-auto">
                        <div className="col-6 text-right border-right border-dark border-dark-su">
                            <strong>Servis Plaka</strong>
                        </div>
                        <div className="col-6 pl-4">
                            {BUSPLATE}
                        </div>
                      </div>
                      <div className="row my-2 mx-auto">
                        <div className="col-6 text-right border-right border-dark border-dark-su">
                            <strong>Sürücü Adı</strong>
                        </div>
                        <div className="col-6 pl-4">
                            {BUSDRIVER}
                        </div>
                      </div>
                      <div className="row my-2 mx-auto">
                        <div className="col-6 text-right border-right border-dark border-dark-su">
                            <strong>Sürücü Telefon</strong>
                        </div>
                        <div className="col-6 pl-4">
                            <a href={`tel:+90${DRIVERPHONE}`}>+90{DRIVERPHONE}</a>
                        </div>
                      </div>                      
                      <div className="d-flex justify-content-center">
                      {
                        showMapX === true &&
                        <a href={SERVICELINK} rel="noopener noreferrer" target="_blank" className="btn btn-primary btn-diger-yolcular">Haritayı Aç</a>
                      }
                        <button type="button" className="btn btn-primary btn-diger-yolcular" onClick={(event) => this.goTo(event,SERVICEID,SERVICENAME)}>Yolcu Listesi</button>
                      </div>
                    </div>
                    </div>
                    
                </div>
                )
              }
              )
        ) : 
        <div>
          <p>Gösterilecek servis yok.</p>
          <button onClick={() => this.props.history.push('/servisler')} className="btn btn-sulink">Servisler</button>        
        </div>

    }

      
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ServiceInfo));