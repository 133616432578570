import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import { LoginActionTypes } from '../../redux/login/login.types';
import { showWarningLayer, hideWarningLayer } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { LoginActionTypes } from '../../redux/login/login.types';
import MobileLogo from '../../components/mobile-logo/mobile-logo.component';
import axios from 'axios';
import KendoMenu from '../kendo/kendo-menu/kendo-menu';
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';


import './mobile.header.styles.scss';

//JQUERY CONFIRM DIALOG BOX
const changePasswordDialog = (open = false, type = 'confirm', title = 'Change Password', changeUserPassword,setpass,setrepass) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} title={title} action={changeUserPassword}>
          <p>Lütfen yeni şifrenizi tanımlayınız. </p>
          <ul id="PasswordWindowID" className="PasswordWindowID">
            <li>
                <input type="password" className="editpass" name="editPass" placeholder="Yeni Şifre" onChange={(event)=> setpass(event.target.value)} />
            </li>
            <li>
                <input type="password" className="editpass" name="editRePass" placeholder="Yeni Şifre Tekrar" onChange={(event)=> setrepass(event.target.value)} />
            </li>
          </ul>        
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class MobileHeader extends React.Component {

  handleLogout = (event) => {
    //console.log(event);
    this.props.setLoading(true);
    this.props.onWarning({warningMessageType:'success', warningMessageText: 'Çıkış yapıldı. Yönlendiriliyorsunuz.'});
    showWarningLayer();
    setTimeout(() => {
      this.props.logOut();
      this.props.setDefault();
      const bodyElement = document.querySelector('body');
      bodyElement.classList.remove('noBodyImage');
      hideWarningLayer();
    },3000);
    
  }

  changePassword = async () => {
    //console.log(this.props.user);
    const pass = this.props.user.Pass;
    const repass = this.props.user.RePass;

    if (pass === null || pass === ''){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanını kontrol edin.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    }    

    if (repass === null || repass === ''){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre tekrar alanını kontrol edin.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    }    

    if (pass !== repass){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Şifre alanları aynı değil.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    } 
    
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/change-password";
    
    const response = await axios.post(dataUrl,{ "uid": this.props.user.UserID, "sid": this.props.user.SessionID, "pass": pass, "repass":repass},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        let warningMessageText = "Şifreniz değiştirildi.";
        this.props.onWarning({warningMessageType:'done', warningMessageText: warningMessageText});
        showWarningLayer();
        this.props.setLoading(false);
        return;
      }else{
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
        return false;  
      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      this.props.setLoading(false);
      return false;
    }
    
    
  }


  render(){
    return(
      
      <div className="mobileTopLayer">
          <div className="breadcrumbLayer float-left">
            <MobileLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
          </div>
          <div className="menuWrapper float-right">
            <KendoMenu 
                methods={{ //name of method and array of arguments to pass to method

              }}
              //Right now, always updates
              events={{ //name of event, and callback
                  close:function(){},
                  select:function(){},
                  open:function(){}
              }}
              //updates if array is different from initial mount
              unbindEvents={[ //name of event to unbind, string
                  
              ]}
              //updates if array is different from initial mount
              triggerEvents={[ //name of event to trigger, string
                
              ]}>            
            
              <ul className="mobileMenu">
                  <li>
                      <i className="fas fa-bars mobileMenuIcon"></i>
                      <ul>
                        {
                          <li disabled="disabled">
                            <span className="su-color"><strong>{this.props.user.DisplayName}</strong> </span>
                          </li>                      
                        }

                          {
                            this.props.user.UserRoles.includes("SUADMIN") === false &&
                            <li onClick={() => this.props.history.push('/servis-bilgisi')} className="border-sep">
                              Servis Bilgisi
                            </li>                      
                          }
                          {
                            this.props.user.UserRoles.includes("SUADMIN") === false &&
                            <li onClick={() => this.props.history.push('/servisler')} className="border-sep">
                              Servisler / Rezervasyonlar
                            </li>             
                          }
                          <li onClick={() => changePasswordDialog(true,'changepassword','Şifre Değiştir',this.changePassword,this.props.setPass,this.props.setRePass)()} className="border-sep">
                              Şifre Değiştir
                          </li>                      
                          <li onClick={(event) => this.handleLogout(event)}>
                              Çıkış
                          </li>
                      </ul>
                  </li>
              </ul>
            </KendoMenu>
          </div> 
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.login.isLoading,
    user: state.login.User,
    currentPage: state.project.currentPage    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setPass: (pass) => dispatch({ type: LoginActionTypes.SET_PASS, value: pass}),
    setRePass: (repass) => dispatch({ type: LoginActionTypes.SET_REPASS, value: repass})
    //changeUserPassword:(user)
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MobileHeader));



