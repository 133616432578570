import React from 'react';
import './login-logo.styles.scss';

const LoginLogo = ({logoName, appName}) => (
  <div className="logoWrapperID">
      <img className="logoID" src={require(`../../assets/images/${logoName}`)} alt={appName} />
      <div className="tmpId"></div>
  </div>  
);

export default LoginLogo;