import React from 'react';

import './login-welcome.styles.scss';


const LoginWelcome = ({welcomeText}) => (
    <div className="welcomeWrapper">
        <h1 className="welcomeMessage">{welcomeText}</h1>
    </div>  
);

export default LoginWelcome;