import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
import { showWarningLayer, customShowLoadingLayer, customHideLoadingLayer } from '../../utils/utils';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import { getDateFromName,generateServiceIdFromName } from '../../utils/utils';
import axios from 'axios';

import './active-services.styles.scss';
import CustomButton from '../custom-button/custom-button.component';
import ServiceChangeButton from '../service-change-button/service-change-button';

class ActiveServices extends React.Component {
  constructor(props){
    super(props);

    if (props.activeUserID !== null && props.activeUserID !== undefined){
      //console.log(props.activeUserID);
    }else{
      
    }

    this.state = {
      loading: false,
      firstLoading:false,
      activeServices: [],
      selectedServiceID: null
    }

  }

  componentDidMount(){
    if (this.props.user.UserRoles.includes("SUDRIVER")){
      this.getServicesDriver();
    }else{    
      this.getServices();
    }
  }

  getServices = () => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services`,{ "uid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID },{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        //console.log(services);
        this.setState({...this.state, loading:false,firstLoading:false, activeServices: services });
        customHideLoadingLayer();
      }     
    })
  }
  
  getServicesDriver = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-driver-active-services`,{ "uid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        
        //console.log(services);
        //console.log(services);
        this.setState({...this.state, loading:false, activeServices: services });
      }     
    })
  }

  handleSwitchChange = async (checked,serviceid) => {
    //this.props.setLoading(true);
    customShowLoadingLayer();
    let dataUrl = "https://azr04.infotraq.com/servisradarsu/";
    if (checked){
      dataUrl+="make-reservation";
    }else{
      dataUrl+="remove-reservation";
    }
    //return true;
    const response = await axios.post(dataUrl,{ "uid": this.props.activeUserID === undefined ? this.props.user.UserID : this.props.activeUserID, "sid": this.props.user.SessionID, "serid": serviceid},{crossDomain: true});
    if (response.status === 200){
      if (response.data.type === "success"){
        // let warningMessageText = "";
        // if (checked){
        //   warningMessageText = "Rezervasyon yapıldı.";
        // }else{
        //   warningMessageText = "Rezervasyon iptal edildi.";
        // }
        //this.props.onWarning({warningMessageType:'done', warningMessageText: warningMessageText});
        //showWarningLayer();
        //this.props.setLoading(false);
        //this.forceUpdate();
        this.getServices();
      }else{
        let warnmessage = "";
        if (response.data.message === "DP_Limit") {
          warnmessage = "Seçmiş olduğunuz servisin kapatesi dolmuştur. Lütfen başka bir gün ve saat seçiniz.";
        }else{
          warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        }
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();
        //this.props.setLoading(false);
        //this.forceUpdate();
      }
    }else{
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir sorun oluştu. Lütfen tekrar deneyiniz.'});
      showWarningLayer();
      //this.props.setLoading(false);
      this.forceUpdate();
    }
  }
  
  goTo = async (event,serviceid,servicename) => {
    event.preventDefault();
    const data = { SERVICEID: serviceid, SERVICENAME:servicename};
    return this.props.history.push('/servis-degistir',data);    
  }   


  render(){
    //console.log(this.state.activeServices);
    return(
      <div className="servisTableWrapper">
      {
        this.props.user.UserRoles.includes("SUDRIVER") ? 
          this.state.loading ? <LoadingIndicator/> :
          this.state.activeServices.length > 0 ? (
            <div className="servisSurucuTable">
              <Table className="tableServisler" responsive>
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>Servis Adı</th>
                  <th className="text-center">Servis Yönü</th>
                  <th className="text-center">Plaka No</th>
                  <th className="text-center">Kapasite</th>
                  <th className="text-center">Yolcu Listesi </th>
                </tr>
              </thead>
              <tbody>
                {
                this.state.activeServices.map(({SERVICEID, ...otherServiceProps},index) => (
                  <tr key={index}>
                      <td>{index+1}</td>
                      <td>{otherServiceProps.SERVICEDATE} {otherServiceProps.SERVICENAME}</td>
                      <td className="text-center">{otherServiceProps.ROUTETYPE === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"}</td>
                      <td className="text-center">{otherServiceProps.BUSPLATE}</td>
                      <td className="text-center">{otherServiceProps.CAPACITY}</td>
                      <td className="text-center">
                      <button type="button" className="btn btn-warning btn-rota-detay" onClick={() => { this.props.history.push('/surucu-yolcu-listesi',{SERVICEIDX: SERVICEID, SERVICENAME: otherServiceProps.SERVICENAME, SERVICEDATE: otherServiceProps.SERVICEDATE}); }}>Yolcu Listesi</button>
                      </td>
                    </tr>
                  )
                )
                }
              </tbody>
            </Table>
            </div>
            ) : 
          <p>Gösterilecek servis yok.</p>
        :
          this.state.firstLoading ? <LoadingIndicator/> :
          this.state.activeServices.length > 0 ? (
            <div className="servisTable">
            <Table className="tableServisler" responsive>
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>Servis Adı</th>
                  <th className="text-center">Servis Yönü</th>
                  <th className="text-center">Durum</th>
                  <th className="text-center">Kapasite</th>
                  <th className="text-center">Rezervasyon Durumu </th>
                </tr>
              </thead>
              <tbody>
                {
                  
                this.state.activeServices.map(({SERVICEID, ...otherServiceProps},index) => (
                  <tr key={SERVICEID}>
                      <td>{index+1}</td>
                      <td>{otherServiceProps.SERVICENAME}</td>
                      <td className="text-center">{otherServiceProps.ROUTETYPE === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"}</td>
                      <td className="text-center">{
                        otherServiceProps.ACTIONSTAT === "1" ? "Rezervasyon Yapıldı." :
                          otherServiceProps.ACTIONSTAT === "2" ? "Rezervasyon İptal Edildi." :
                            otherServiceProps.ACTIONSTAT > 2 ? "Kullanıcı Geldi" : "Kullanıcı Gelmiyor" }
                      </td>
                      <td className="text-center">{otherServiceProps.CAPACITY}</td>
                      <td className="text-center">
                      {
                        this.props.user.UserRoles.includes("SUADMIN") === true ?
                        <BootstrapSwitchButton 
                          checked={otherServiceProps.ACTIONSTAT === "1" || otherServiceProps.ACTIONSTAT > 2 ? true : false}
                          onlabel="Geliyor"
                          offlabel="Gelmiyor"
                          onstyle="success"
                          offstyle={"danger"} 
                          width="130" 
                          disabled={otherServiceProps.SERVICESTAT > 2 ? true : false}
                          onChange={(checked) => this.handleSwitchChange(checked,SERVICEID)}
                        />  
                        :
                        (
                        <Fragment>
                          <BootstrapSwitchButton 
                              checked={otherServiceProps.ACTIONSTAT === "1" ? true : false}
                              onlabel={otherServiceProps.SERVICESTAT === "0" ? "Geliyorum" : "Servis Kapalı"} 
                              offlabel={otherServiceProps.SERVICESTAT === "0" ? "Gelmiyorum" : "Servis Kapalı"}
                              onstyle={otherServiceProps.SERVICESTAT === "0" ? "success" : "light"}
                              offstyle={otherServiceProps.SERVICESTAT === "0" ? "danger" : "light"} 
                              width="130" 
                              disabled={otherServiceProps.SERVICESTAT === "0" ? false : true}
                              onChange={(checked) => this.handleSwitchChange(checked,SERVICEID)}
                          />    
                          {
                            getDateFromName(otherServiceProps.SERVICENAME) !== false && <ServiceChangeButton classes="btn-change-reservation" onClick={(event) => this.goTo(event,SERVICEID,otherServiceProps.SERVICENAME)} >Değiştir</ServiceChangeButton>
                          }
                          
                        </Fragment>
                        )

                      }
                      </td>
                    </tr>
                  )
                )
                }
              </tbody>
            </Table>
            </div>
          ) : 
          <p>Gösterilecek servis yok.</p>        
      }
 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    serviceRow: state.passenger.serviceRow
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setCurrentService: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE, payload: payload}),
    setCurrentServiceID: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE_ID, payload: payload}),
    setCurrentServiceName: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE_NAME, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ActiveServices));