import React from 'react';
import ReactDOM from 'react-dom';
import { ContextMenuTrigger } from "react-contextmenu";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import tr from 'date-fns/locale/tr';
import PopupModal from '../../components/popup-modal/popup-modal.component';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import ReactTooltip from "react-tooltip";
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import { showWarningLayer, showWarningLayerPopup, hideWarningLayerPopup, generateID } from '../../utils/utils';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css';
import './busroutes.styles.scss';
import '../../assets/styles/react-bootstrap-table-all.min.css';
import '../../assets/styles/react-contextmenu.css';

//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action}>
        <p className="text-center passwordInfo"><b>{text}</b></p>
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class BusRoutes extends React.Component {
  constructor(props){
    super(props);
    this.guid = generateID();
    this.excelData = [];
    this.state = {
      loading: false,
      loadingPopup:false,
      firstLoading:true,
      loadingModalNew: false,
      loadingModalEdit:true,
      selectedRouteID: null,
      selectedRouteData:null,
      modalNewRoute:false,
      modalEditRoute:false,
      isFilterDisable:true,
      isRowSelected: false,
      routemap:"",
      statusOption: {label: 'Kampüse Gidiş', value: 1},
      currentSchedule:{
        ROUTENAME: '',
        ROUTELINK: '',
        ROUTETYPE: '',
        ROUTEDEFID: ''
      },
      columnFilterData:{}      
    }

  }


  componentDidMount(){
    this.getBusRoutes();
  }
  handleNewRoute = () => {
    this.props.setLoadingPopup(false);
    this.setState({...this.state,statusOption: {label: 'Kampüse Gidiş', value: 1}, modalNewRoute:true});    
  }

  handleEditRoute = () => {
    this.props.setLoadingPopup(false);
    if (this.state.selectedRouteData === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah seçiniz.'});
      showWarningLayer();
      return;      
    }        
    let showMap = false;
    let tempLink = "";
    //let routemap = "";

    if (this.state.selectedRouteData.ROUTELINK === ""){
      tempLink = "Harita linki mevcut değil.";
    }else{
      try{
        tempLink = this.state.selectedRouteData.ROUTELINK.split("?");
        tempLink = tempLink[1].split("&");
        tempLink = tempLink[0].split("=");  
        showMap = true;   
      }catch(ex){
        showMap = false;
      }
  
    }

    


    this.setState({
      ...this.state,
      statusOption: this.state.selectedRouteData !== null ? {label: this.state.selectedRouteData.ROUTETYPE === "1" ? 'Kampüse Gidiş' : 'Kampüsten Dönüş', value: this.state.selectedRouteData.ROUTETYPE === "1" ? 1 : 0} : null,
      modalEditRoute: true,
      showMap:showMap,
      routemap: tempLink[1]
    });
    
  }

  insertRoute = async () => {
    this.props.setLoadingPopup(true);
    let routename = this.routename.value;  
    let routetype =  this.state.statusOption.value;
    let routelink = this.routelink.value;

    if (routename === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah adını kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-route`,
      {
        "sid": this.props.user.SessionID,
        "routename": routename,
        "routetype":routetype,
        "routelink":routelink
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Routename"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah adını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Rota / Güzegah eklendi.'});
        showWarningLayer();
        this.props.setLoading(true);
        this.getBusRoutes();
        this.setState({...this.state,modalNewRoute:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }
  updateRoute = async () => {
    this.props.setLoadingPopup(true);
    let routeid = this.state.selectedRouteData.ROUTEID;
    let routename = this.routename.value;  
    let routetype =  this.state.statusOption.value;
    let routelink = this.routelink.value;

    if (routeid === "" || routeid === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (routename === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah adını kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-route`,
      {
        "sid": this.props.user.SessionID,
        "routename": routename,
        "routetype":routetype,
        "routelink":routelink,
        "routeid":routeid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Routename"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Rota / Güzergah güncellendi.'});
        showWarningLayer();
        this.props.setLoading(true);
        this.getBusRoutes();
        let routeData = this.state.selectedRouteData;
        routeData.ROUTENAME = routename;
        routeData.ROUTETYPE = routetype;
        routeData.ROUTELINK = routelink;
        this.setState({...this.state,selectedRouteData: routeData,modalEditRoute:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }  

  deleteRoute = async () => {
    this.props.setLoading(true);
    let routeid = this.state.selectedRouteData.ROUTEID;
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/delete-route`,
      {
        "sid": this.props.user.SessionID,
        "routeid":routeid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Routeid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Rota / güzergah silindi.'});
        this.getBusRoutes();
        showWarningLayer();
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }


  handleFilterSearch = (value) => {
    try{
      this.refs.routenameCol.applyFilter(value);
    }catch(ex){}
    
  }

  getBusRoutes = () => {
    this.setState({
      ...this.state,
      isFilterDisable:true,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-routes`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let routes;
        try{
          routes = res.data.data;
          if (routes === null || routes === undefined){
            routes = [];
          }
        }catch(ex){
          routes = [];
        }
        
        this.props.setExcelData(routes);
        this.setState({...this.state,isFilterDisable:false,firstLoading:false, loading:false});
        this.props.setBusRoutes(routes);
        this.props.setLoading(false);
      }     
    })
  }

  handleStatusChange = statusOption => {
    this.setState(
      { statusOption });
  };  

  handleRowSelect = (row, isSelected, e) => {
    this.setState(
      {
      ...this.state,
      selectedRouteData: row,
      isRowSelected: isSelected
      }
      );
  }    

  handleDeleteRoute = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota / güzergah seçiniz.'});
      showWarningLayer();
      return;      
    }    

    confirmDialog(true,'changepassword','Seçilen rota / güzergahı silmek istediğinize emin misiniz ?',() => this.deleteRoute())();    

  }  


  //Column Templates
  suColumnType = (cell, row, type) => {
    if (type === "routetype"){
      return cell === "1" ? '<span class="badge badge-primary columnInfo">Kampüse Gidiş</span>' : '<span class="badge badge-warning columnInfo">Kampüsten Dönüş</span>'
    }
  }    

  changeRouteMap = (value) => {
    this.setState({
      ...this.state,
      routemap: value
    })
  }

  afterColumnFilter = (filterConds, result) => {
    //console.log(filterConds);
    const tempData = this.props.excelData;
    if (JSON.stringify(tempData) !== JSON.stringify(result)){
      this.props.setExcelData(result);
    }

    if (JSON.stringify(this.state.columnFilterData) !== JSON.stringify(filterConds)){    
      this.setState({
        ...this.state,
        columnFilterData: filterConds
      });
    }
  }

  render(){
    const durumOptions = [
      { value: 0, label: 'Kampüsten Dönüş' },
      { value: 1, label: 'Kampüse Gidiş' }
    ];     
    //const editGuid = generateID();
    //let tempDate = formatNow(new Date(),"YYYY-MM-DD");
    //const excelFileName = tempDate;
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 50,
      noDataText: 'Gösterilecek kayıt yok',
      afterColumnFilter: this.afterColumnFilter,
    };    
    const selectRow = {
      mode: 'radio',  // multi select
      clickToSelect: true,
      className: 'row-select-class',
      bgColor: '#004288',
      Color:'white',
      onSelect: this.handleRowSelect,
      selected: this.state.selectedRouteData !== null ? [this.state.selectedRouteData.ROUTEID] : []      
    };   
    const footerData = [
      [
        {
          label: '',
          columnIndex: 1,
          align: 'left',
          formatter: (tableData) => {
            //let total = tableData.length;
            let total = this.props.excelData.length;
            return (
              <strong>Toplam : { total } rota / güzergah</strong>
            );
          }
          
        }
      ]
    ];    

    const routeTypes = {
      0: 'Kampüsten Dönüş',
      1: 'Kampüse Gidiş'
    };    
    

    return(
      <div className="passengersPageWrapper">
      <div className="card card-body bg-light content-toolbar">
      <div className="d-flex">
          <div className="p-2">
            <button type="button" className="btn btn-sm btn-general btn-light p-2 margin-left-5 userbuttons minibuttons" data-tip="Rota / Güzergah Ekle" onClick={() => this.handleNewRoute()}><i className="fas fa-plus"></i></button>
            <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Rota / Güzergah Düzelt" onClick={() => this.handleEditRoute()}><i className="fas fa-edit"></i> </button>
            <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Rota / Güzergah Sil" onClick={() => this.handleDeleteRoute()}><i className="fas fa-times"></i> </button>
          </div>
          <div className="ml-auto p-2">
            <div className="d-flex">
               <label className="filterLabelYolcuIslemleri">Filtrele :</label><input id="editFilter" type="text" disabled={this.state.isFilterDisable} className="form-control edit editFilter margin-right-5" placeholder="Rota / Güzergah Adı" onChange={(event) => this.handleFilterSearch(event.target.value)}  />
            </div>              
          </div>
      </div>   
      </div>       
        <div className="SplitterPanelLeft">
        <ContextMenuTrigger id="submenu1" holdToDisplay={1000}>            
        {
          this.state.firstLoading ? <LoadingIndicator /> :
          this.props.busroutes.length > 0 ? (
            <BootstrapTable data={ this.props.busroutes } container className='route-list guzergah-tanimlari-table' hover options={options}  selectRow={ selectRow } footerData={ footerData } footer pagination >
              <TableHeaderColumn dataField='ROUTEID' isKey={true} hidden>ID</TableHeaderColumn>
              <TableHeaderColumn dataField='ROUTENAME' width='300px' ref='routenameCol' className="displayCol" filter={ { type: 'TextFilter', delay: 1000}}  dataSort={ true } headerAlign='center'>Rota / Güzergah Adı</TableHeaderColumn>
              <TableHeaderColumn dataField='ROUTETYPE' width='200px' ref='routetypeCol' className="displayCol" dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ routeTypes } dataFormat={(cell,row) => this.suColumnType(cell,row,'routetype') }>Rota Yönü</TableHeaderColumn>
              <TableHeaderColumn dataField='ROUTELINK' width='auto' ref='routelinkCol' className="displayCol" dataSort={ true }>Rota Linki</TableHeaderColumn>
          </BootstrapTable>          
          ) : 
          <p className="padding15">Gösterilecek kayıt yok.</p>
        }              
        </ContextMenuTrigger>        
                        
          {
            <table id="emp-guzergah-tanimlari" className="excel-table">  
            <tbody>             
              <tr>
                <td><b>Rota / Güzergah Adı {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.SCHNAME !== undefined && '*'}</b></td>
                <td><b>Rota Yönü</b></td>
                <td><b>Rota Linki</b></td>
              </tr>                                   
                { 
                  this.props.excelData.map((p, index) => {  
                      return (
                      <tr key={index}>  
                        <td >{p.ROUTENAME}</td>  
                        <td >{p.ROUTETYPE === "1" ? "Kampüse Gidiş" : "Kampüsten Dönüş" }</td>  
                        <td >{p.ROUTELINK}</td>  
                      </tr>  
                      )
                  })  
                }  
                <tr>
                <td><b>Toplam</b></td>
                <td><b>{this.props.excelData.length}</b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>  
              </tbody>  
              </table>  
              
          }          
                  


        </div>
          <PopupModal
              show={this.state.modalNewRoute}
              onHide={() => this.setState({...this.state,modalNewRoute:false})}
              titlemodal={`Yeni Rota / Güzergah Ekle`}
              onClick={()=> this.insertRoute}
            >
            
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen rota / güzergah bilgilerini giriniz.</p>
            <form className="address-form" name="route-form">
              <div className="row">
                <div className="col-md-6">
                    <div className="form-row address-row">
                    <div className="col-md-12 mb-12">
                      <label htmlFor={`routename_${this.guid}`}>Rota / Güzergah Adı : <span className="requiredFieldMark">*</span></label>
                      <input type="text" className="form-control" id={`routename_${this.guid}`}  ref={(ref)=>{ this.routename = ref;}} placeholder="Rota / Güzergah Adı zorunlu"  required />
                    </div>
                  </div>
                  <div className="form-row address-row">
                    <div className="col-md-12 mb-12">
                      <label htmlFor={`routetype_${this.guid}`}>Rota Yönü :</label>
                      <Select id={`routetype_${this.guid}`} className="edit max-width-element" placeholder="Rota Yönü" options={durumOptions} value={this.state.statusOption} onChange={this.handleStatusChange} />
                    </div>
                  </div>             
                  <div className="form-row address-row">
                    <div className="col-md-12 mb-12">
                      <label htmlFor={`routelink_${this.guid}`}>Rota Linki :</label>
                      <textarea className="form-control route-link" id={`routelink_${this.guid}`}  ref={(ref)=>{ this.routelink = ref;}} placeholder="Rota Linki"></textarea>
                    </div>
                  </div>            

                </div>

              </div>                                    
            </form>                

            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
          </PopupModal> 
          
          <PopupModal
            show={this.state.modalEditRoute}
            onHide={() => this.setState({...this.state,modalEditRoute:false})}
            titlemodal={`Rota / Güzergah Düzelt - ${this.state.selectedRouteData === null ? '' : this.state.selectedRouteData.ROUTENAME }`}
            onClick={()=> this.updateRoute}
            state={this.state}
          
          >
        
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen rota / güzergah bilgilerini giriniz.</p>
            <form className="address-form" name="route-form">

            <div className="row">
              <div className="col-md-6">
                <div className="form-row address-row">
                  <div className="col-md-12 mb-12">
                    <label htmlFor={`routename_${this.guid}`}>Rota / Güzergah Adı : <span className="requiredFieldMark">*</span></label>
                    <input type="text" className="form-control" id={`routename_${this.guid}`}  ref={(ref)=>{ this.routename = ref;}} placeholder="Rota / Güzergah Adı zorunlu" defaultValue={this.state.selectedRouteData === null ? "" : this.state.selectedRouteData.ROUTENAME}   required />
                  </div>
                </div>
                <div className="form-row address-row">
                  <div className="col-md-12 mb-12">
                    <label htmlFor={`routetype_${this.guid}`}>Rota Yönü :</label>
                    <Select id={`routetype_${this.guid}`} className="edit max-width-element" placeholder="Rota Yönü" options={durumOptions} value={this.state.statusOption} onChange={this.handleStatusChange} />
                  </div>
                </div>             
                <div className="form-row address-row">
                  <div className="col-md-12 mb-12">
                    <label htmlFor={`routelink_${this.guid}`}>Rota Linki :</label>
                    <textarea className="form-control route-link" id={`routelink_${this.guid}`}  ref={(ref)=>{ this.routelink = ref;}} placeholder="Rota Linki" defaultValue={this.state.selectedRouteData === null ? "" : this.state.selectedRouteData.ROUTELINK} ></textarea>
                  </div>
                </div>        
              </div>
              
              <div className="col-md-6">
                <label>Harita :</label>
                {
                  this.state.showMap === true ?
                    <iframe title={`map_${this.guid}`} className="route-map" src={`https://www.google.com/maps/d/embed?mid=${this.state.routemap}`} />
                  :
                  <span> Harita linki mevcut değil.</span>
                }
              </div>              
            </div>

            </form>                
            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
        </PopupModal>           
      
      

            <ReactTooltip /> 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    busroutes: state.definitions.busroutes,
    excelData: state.definitions.excelData,
    showLoading:state.login.showLoading,
    showLoadingPopup:state.login.showLoadingPopup,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setBusRoutes: (busroutes) => dispatch({ type: DefinitionsActionTypes.SET_BUSROUTES, busroutes: busroutes}),
    setExcelData: (excelData) => dispatch({ type: DefinitionsActionTypes.SET_EXCEL_DATA, excelData: excelData})
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(BusRoutes));