import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'jquery-confirm';

import '../../assets/styles/jquery-confirm.min.css';
import './jquery-confirm.styles.scss';



class JqueryConfirm extends React.Component {

  renderSubtreeIntoContainer = ReactDOM.unstable_renderSubtreeIntoContainer;

  componentDidMount () {
    //console.log('componentDidMount')
    this.node = document.createElement('div');
    this.renderPortal(this.props);
  }
  UNSAFE_componentWillReceiveProps (newProps) {
    this.renderPortal(newProps);
  }
  componentWillUnmount() {
    this.removePortal();
  }
  removePortal () {
    this.node && ReactDOM.unmountComponentAtNode(this.node);
    this.node && $(this.node).remove();
    this.popup && this.popup.close();
  }
  renderPortal (props) {
    if (props.open) {
      this.portal = this.renderSubtreeIntoContainer(this, props.children, this.node);
      
      if (props.type === 'alert'){
        this.popup = $.alert({ 
          title: false, 
          theme: 'black',
          content: this.node,
          buttons: {
            buttonOk: {
              text: 'Tamam'
            }
          }          
        });
      }else if(props.type ==='changepassword'){
        this.popup = $.confirm({ 
          title: false, 
          theme: 'modern',
          content: this.node,
          buttons: {
            buttonOk: {
              text: 'Evet',
              btnClass: 'btn-green',
              keys: ['enter'],
              action: props.action
            },
            buttonCancel: {
              text: 'Hayır',
              btnClass: 'btn-danger',
              keys: ['esc'],
              action: function(){
                  
              }
            }            
          }
          
        });
      }else if(props.type ==='resetpassword'){
        this.popup = $.confirm({ 
          title: false, 
          theme: 'modern',
          content: this.node,
          buttons: {
            buttonOk: {
              text: 'Gönder',
              btnClass: 'btn-green',
              keys: ['enter'],
              action: props.action
            },
            buttonCancel: {
              text: 'İptal',
              btnClass: 'btn-danger',
              keys: ['esc'],
              action: function(){
                  
              }
            }            
          }
          
        });

      }else{
        this.popup = $.confirm({ 
          title: props.title, 
          theme: 'modern',
          content: this.node,
          buttons: {
            buttonOk: {
              text: 'Kaydet',
              btnClass: 'btn-green',
              keys: ['enter'],
              action: props.action
            },
            buttonCancel: {
              text: 'İptal',
              btnClass: 'btn-orange',
              keys: ['esc'],
              action: function(){
                  
              }
            }            
          }
          
        });
      }
    } else {
      this.popup && this.popup.close();
    }

  }
  render() {
    return null;
  }
}

export default JqueryConfirm;