import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import MainLogo from '../../components/main-logo/main-logo.component';
import TopHeader from '../../components/header/header.component';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import tr from 'date-fns/locale/tr';
import MobileHeader from '../../components/mobile-header/mobile-header.component';
import ContentWrapper from '../../components/content-wrapper/content-wrapper.component';
import NavMenu from '../../components/nav-menu/nav-menu.component';
import MainCopyright from '../../components/main-copyright/main-copyright.component';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { LoginActionTypes } from '../../redux/login/login.types';
import * as actionCreators from '../../redux/login/login.actions';
import { showWarningLayer } from '../../utils/utils';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import '../../assets/styles/edms-main.scss';
import '../../assets/styles/edms-overrides.scss';
import './yeni-yolcu.styles.scss';
import '../../assets/styles/bootstrap-checkbox.scss';

class YeniYolcu extends React.Component {
  constructor(){
    super();
    this.title = "Yolcu İşlemleri > Yolcu Ekle";
    this.formId = this.generateID();
    this.username = "username_"+this.generateID();
    this.fullname = "fullname_"+this.generateID();
    this.password = "password_"+this.generateID();
    this.repassword = "repassword_"+this.generateID();
    this.email = "email_"+this.generateID();
    this.phone = "phone_"+this.generateID();
    this.idno = "idno_"+this.generateID();
    this.birim = "birim_"+this.generateID();
    this.gorevi = "gorevi_"+this.generateID();
    this.calismasekli = "calismasekli_"+this.generateID();
    this.faturali = "faturali_"+this.generateID();
    this.giristarih = "giristarih_"+this.generateID();
    this.cikistarih = "cikistarih_"+this.generateID();
    this.durum = "durum_"+this.generateID();
    this.notlar = "notlar_"+this.generateID();

    this.state = {
      loading: false,
      girisTarih: null,
      cikisTarih: null,
      statusOption: {label: 'Aktif', value: 1},
      faturali:false,
      readOnly:true
    }
  }



  componentDidMount() {
    if (this.props.isLoggedIn === true){
      const bodyElement = document.querySelector('body');
      bodyElement.classList.add('noBodyImage');
      this.props.setCurrent('yolcu-islemleri');
    }
  }

  handleChangeFaturali = (value) => {
    this.setState({
      ...this.state,
      faturali: value
    });

  }

  handleGirisTarihChange = (date) => {
    this.setState({
      ...this.state,
      girisTarih: date
    })
  }

  handleCikisTarihChange = (date) => {
    this.setState({
      ...this.state,
      cikisTarih: date
    })
  }

  handleStatusChange = statusOption => {
    this.setState(
      { statusOption });
  };  

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.setLoading(true);
    const form = event.target;
    const data = new FormData(form);
    let jsonPost = {};
    let pass = "";
    let repass = "";
    for (let name of data.keys()) {
      const input = form.elements[name];
      let temp_caption = input.name.split("_")[0];
      let temp_value = input.value;
      jsonPost[temp_caption] = temp_value;
      if (input.required){
        if (input.type === "password"){
          if (temp_caption === "password"){
            pass = input.value;
            if (pass === ""){
              this.props.onWarning({warningMessageType:'fail', warningMessageText: input.alt});
              input.focus();
              showWarningLayer();
              this.props.setLoading(false);
              return false;
            }            
          }else if(temp_caption === "repassword"){
            repass = input.value;
            if (repass === ""){
              this.props.onWarning({warningMessageType:'fail', warningMessageText: input.alt});
              input.focus();
              showWarningLayer();
              this.props.setLoading(false);
              return false;
            }            
            if (pass !== repass){
              this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Şifre alanları aynı değil.'});
              input.focus();
              showWarningLayer();
              this.props.setLoading(false);
              return false;
            }
          }
        }

        if (input.type === "email"){
          if (input.value === ""){
            this.props.onWarning({warningMessageType:'fail', warningMessageText: input.alt});
            input.focus();
            showWarningLayer();
            this.props.setLoading(false);
            return false;
          }
        }else{
          if (input.value === ""){
            this.props.onWarning({warningMessageType:'fail', warningMessageText: input.alt});
            input.focus();
            showWarningLayer();
            this.props.setLoading(false);
            return false;
          }
        }
        
      }

    }   
    jsonPost.faturali = this.state.faturali === false ? "0" : "1";
    jsonPost.sid = this.props.user.SessionID;
    
    axios.post(`https://azr04.infotraq.com/servisradarsu/add-passenger`,jsonPost,{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }else if (res.data.message === "DP_Username"){
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adını kontrol edin.'});
          showWarningLayer();
          this.props.setLoading(false);
        }else if (res.data.message === "DP_Fullname"){
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen ad / soyad alanını kontrol edin.'});
          showWarningLayer();
          this.props.setLoading(false);
        }else if (res.data.message === "DP_Password"){
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen şifre alanını kontrol edin.'});
          showWarningLayer();
          this.props.setLoading(false);
        }else{
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          this.props.setLoading(false);
        }
      } else{
        if (res.data.message === "DP_Done"){
          this.props.setLoading(false);
          this.props.history.push('/yolcu-islemleri');
          this.props.onWarning({warningMessageType:'done', warningMessageText: 'Yolcu eklendi.'});
          showWarningLayer();
        }else{
          this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          this.props.setLoading(false);
        }
        
      }     
    })    
  }


  goBack = () => {
    this.props.history.push('/yolcu-islemleri');
  }  

  readOnlyFalse = ()=>{
    this.setState(
      {
        ...this.state,
        readOnly:false
      })
  }


 generateID = () => {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now();; //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16); //eslint-disable-line
    });
    return uuid;
  }  

  render(){
    const durumOptions = [
      { value: 1, label: 'Aktif' },
      { value: 0, label: 'Pasif' }
    ]    
    if (this.props.isLoggedIn === true && this.props.user.UserRoles.includes("SUADMIN")) {
      return(
        <div className="middleID">
          <MediaQuery minWidth={768}>
            <div className="leftID">
              <MainLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
              <NavMenu currentPage={this.props.currentPage} /> 
              <MainCopyright companyName="DocPlant" />
            </div>
            <div className="rightID">
              <TopHeader breadcrumbTitle={this.title} />
              <ContentWrapper>
              
                <div className="card card-body bg-light content-toolbar">
                  <div className="d-flex">
                      <div className="d-flex p-2">
                        <h5 className="toolbarTitle su-color">Yeni Yolcu</h5>
                      </div>
                      <div className="ml-auto p-2">
                          <div className="d-flex">
                            <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>                
                            <button type="button" className="btn btn-general margin-left-5 userbuttons btn-su margin-left-10" onClick={() => { this.form.dispatchEvent(new Event('submit'))}}><i className="fas fa-save"></i> Kaydet</button>
                          </div>
                      </div>
                  </div>   
                </div>    
                <div className="contentWrapperBlock">
                  <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen yolcu bilgilerini giriniz.</p>
                  <form name={this.formId} autoComplete="off" onSubmit={this.handleSubmit}  ref={ (ref) => { this.form = ref; } }>
                    <div className="row">
                        <div className="col-3">
                            <label htmlFor={this.username} className="window-label">Kullanıcı Adı :<span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                            <input type="text" id={this.username} name={this.username} autoCorrect="off" spellCheck="false" autoComplete="off"  className="form-control edit max-width-element" placeholder="Lütfen kullanıcı adı giriniz." alt="Lütfen kullanıcı adı alanını kontrol ediniz." required />
                        </div>
                    </div>  
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor={this.fullname} className="window-label">Ad / Soyad : <span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                        <input type="text" id={this.fullname} name={this.fullname} autoCorrect="off" spellCheck="false" autoComplete="off"  className="form-control edit max-width-element" placeholder="Lütfen ad / soyad giriniz." alt="Lütfen ad / soyad alanını kontrol ediniz." required />
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.password} className="window-label">Şifre : <span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                        <input type="password" id={this.password} name={this.password} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Lütfen şifre giriniz." alt="Lütfen şifre alanını kontrol ediniz."  required />
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.repassword} className="window-label">Şifre Tekrar : <span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                        <input type="password" id={this.repassword} name={this.repassword} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Lütfen şifre tekrar giriniz." alt="Lütfen şifre tekrar alanını kontrol ediniz." required />
                        </div>
                    </div>    
                    <div className="clearfix"></div>                                        
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.email} className="window-label">E-posta : <span className="requiredFieldMark">*</span></label>
                        </div>
                        <div className="col-7">
                          <input type="email" id={this.email} name={this.email} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Lütfen e-posta adresi giriniz." alt="Lütfen e-posta alanını kontrol ediniz."  required/>
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.phone} className="window-label">Telefon Numarası :</label>
                        </div>
                        <div className="col-7">
                          <input type="tel" id={this.phone} name={this.phone} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Lütfen telefon numarası giriniz." />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.idno} className="window-label">Sicil Numarası :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.idno} name={this.idno} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Lütfen sicil numarası giriniz." />
                        </div>
                    </div> 
                    <div className="clearfix"></div>  
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.birim} className="window-label">Birim :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.birim} name={this.birim} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Lütfen birim giriniz." />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.gorevi} className="window-label">Görevi :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.gorevi} name={this.gorevi} autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control edit max-width-element" placeholder="Lütfen görevi giriniz."  />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.calismasekli} className="window-label">Çalışma Şekli :</label>
                        </div>
                        <div className="col-7">
                          <input type="text" id={this.calismasekli} autoCorrect="off" spellCheck="false" autoComplete="off" name={this.calismasekli} className="form-control edit max-width-element" placeholder="Lütfen çalışma şekli giriniz."  />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor="faturali" className="window-label">Faturalı mı ? :</label>
                        </div>
                        <div className="col-7">
                          <div className="checkbox">
                                <label className="checkbox-bootstrap checkbox-lg">                                        
                                    <input id="faturali" name="faturali" type="checkbox" onChange={(event) => this.handleChangeFaturali(event.target.checked)} /> 
                                    <span className="checkbox-placeholder formcheck"></span>           
                                </label>
                          </div>                        
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor={this.giristarih} className="window-label">İşe Giriş Tarihi :</label>
                        </div>
                        <div className="col-7">
                          <DatePicker 
                            id={this.giristarih}
                            name={this.giristarih}
                            className="form-control edit max-width-element"
                            selected={this.state.girisTarih} 
                            onChange={this.handleGirisTarihChange} 
                            locale={tr}
                            placeholderText="Lütfen işe giriş tarihi giriniz."
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                          <label htmlFor={this.cikistarih} className="window-label">İşten Çıkış Tarihi :</label>
                        </div>
                        <div className="col-7">
                          <DatePicker 
                            id={this.cikistarih}
                            name="cikistarih"
                            className="form-control edit max-width-element"
                            selected={this.state.cikisTarih} 
                            onChange={this.handleCikisTarihChange} 
                            locale={tr}
                            placeholderText="Lütfen işten ayrılma tarihi giriniz."
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.durum} className="window-label">Durum :</label>
                        </div>
                        <div className="col-7">
                          <Select id={this.durum} name="durum" className="edit max-width-element" placeholder="Durum" options={durumOptions} defaultValue={{label: 'Aktif', value: 1}} value={this.state.statusOption} onChange={this.handleStatusChange} />
                        </div>
                    </div>   
                    <div className="clearfix"></div>
                    <div className="row" >
                        <div className="col-3">
                            <label htmlFor={this.notlar} className="window-label">Notlar :</label>
                        </div>
                        <div className="col-7">
                            <textarea id={this.notlar} name="notlar" autoCorrect="off" spellCheck="false" autoComplete="off"   className="form-control textbox max-width-element" placeholder=""></textarea>
                        </div>
                    </div>    
                    <div className="clearfix"></div>
                  </form>
                  
                </div>

              </ContentWrapper>
            </div>
            <div id="jqueryconfirm"></div>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div className="mobileWrapper">
              <MobileHeader />
              <ContentWrapper>
                Mobil görünüm henüz desteklenmiyor.
              </ContentWrapper>
            </div>
            <div id="jqueryconfirm"></div>
          </MediaQuery>
  
       </div>
      );      
    } else{
      return <Redirect push to="/servis-bilgisi" />;
    }     


  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    currentPage: state.project.currentPage,
    warningMessageShow: state.project.warningMessageShow,
    warningMessageType: state.project.warningMessageType,
    warningMessageText: state.project.warningMessageText,
    user: state.login.User,
    breadcrumbTitle: state.project.breadcrumbTitle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value }),
    setBreadcrumbTitle: (value) => dispatch({type: ProjectActionTypes.SET_BREADCRUMB_TITLE, value: value }),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(YeniYolcu);