import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import Select from 'react-select';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import axios from 'axios';

import './service-summary.scss';

import '../../assets/styles/react-bootstrap-table-all.min.css';

class ServiceSummary extends React.Component {
  constructor(props){
    super(props);
    //console.log(this.props.location.state);
    this.props.setServices([]);
    this.excelData = [];
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
    this.state = {
      loading: false,
      filters: this.props.location.state === undefined ? [] : this.props.location.state.filters,
      selectedOption: this.props.location.state === null || this.props.location.state === undefined  ? null : this.props.location.state.selectedOption,
      excelFileName: "",
      isDisableFilter:true,
      disableSaat:true,
      selectedOptionSaat: this.props.location.state === null || this.props.location.state === undefined  ? null : this.props.location.state.selectedOptionSaat,
      currentOptionTarihValue: "",
      currentOptionSaatValue: ""

    }

  }

  componentDidMount(){
    if(this.props.location.state === undefined){
      this.getServiceSummaryDates();
    }
    if (this.props.location.state !== undefined){
      this.getServiceSummary(this.props.location.state.selectedOption);    
    }

  }
 
  getServiceSummaryDates = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services-summary-dates`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let filters;

        try{
          filters = res.data.data;
          if (filters === null || filters === undefined){
            filters = [];
          }          
        }catch(ex){
          filters = [];
        }
        
        this.setState({...this.state, filters: filters, loading:false });        
        
      }     
    })
  }

  getServiceSummary = (selectedOption) => {
    this.setState({
      ...this.state,
      loading:true,
      isDisableFilter:true,
      saatGoster:false
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-services-summary`,{ "sid": this.props.user.SessionID,"sdate":selectedOption.value},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let services;
        try{
          services = res.data.data;
          if (services === null || services === undefined){
            services = [];
          }
        }catch(ex){
          services = [];
        }
        this.props.setServices(services);
        this.props.setExcelData(services);        
        let tempDate = selectedOption.label.split(" ");
        let currentDate = tempDate[0].replace(/[\/]/g,'-'); //eslint-disable-line
        const excelFileName = currentDate;
        this.getSaatFilters(selectedOption.value);
        this.setState({...this.state, selectedOption,excelFileName:excelFileName, isDisableFilter:false });
      }     
    })
  }

  getSaatFilters = (tarihValue) => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-activeservice-times`,{ "sid": this.props.user.SessionID, "sdate": tarihValue},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let filtersSaat;
        try{
          filtersSaat = res.data.data;
          if (filtersSaat === null || filtersSaat === undefined){
            filtersSaat = [];
          }          
        }catch(ex){
          filtersSaat = [];
        }
        filtersSaat.unshift({"value":'',"label":"Hepsi"});
        this.setState(
          {
            ...this.state,
            filtersSaat: filtersSaat,
            disableSaat:false,
            loading:false
          }
        );
        //this.props.setPassengersList([]);
        if (this.props.location.state !== undefined){
          try{
            this.refs.servicenameCol.applyFilter(this.state.selectedOptionSaat.value);
          }catch(ex){}
        }

      }     
    })
  }  



  buttonDetail = (cell, row) => {
    return (
      <button type="button" className="btn btn-warning btn-rota-detay" onClick={() => {this.props.setCurrentService(row); this.props.history.push('/raporlar-yolcu-listesi',this.state); }}>Yolcu Listesi</button>
    )
  }

  routeType = (cell, row) => {
    return cell === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"
  }

  handleFilterSearch = (value) => {
    try{ this.refs.servicenameCol.applyFilter(value);} catch(ex){}
  }

  handleSaatChange = (selectedOption) => {
    try{
       this.refs.servicenameCol.applyFilter(selectedOption.value);
       this.setState({...this.state, selectedOptionSaat: selectedOption });      
      
      } catch(ex){}
  }  


  handleFilterChange = (selectedOption ) => {
    this.getServiceSummary(selectedOption);
//    this.refs.servicedateCol.applyFilter(selectedOption.value);
  }

  afterColumnFilter = (filterConds, result) => {
    this.RESERVATIONCOUNT = 0;
    this.MAXPASSENGERS = 0;
    this.TOTALPASSENGERS = 0;
    const tempData = this.props.excelData;
    if (JSON.stringify(tempData) !== JSON.stringify(result)){
      this.props.setExcelData(result);
    }
  }

  render(){
    
    const options = {
      // sizePerPageList: [],
      // hidePageListOnlyOnePage: true,
      // sizePerPage: 100,
      afterColumnFilter: this.afterColumnFilter,
      noDataText: 'Gösterilecek kayıt yok'
      
    };
    const footerData = [
      [
        {
          label: '',
          columnIndex: 2,
          align: 'center',
          formatter: (tableData) => {
            let total = tableData.length;
            return (
              <strong>Toplam : { total } adet</strong>
            );
          }
          
        },
        {
          label: '',
          columnIndex: 7,
          align: 'center',
          formatter: (tableData) => {
            let labelReservation = 0;
            let labelMaxPassengers = 0;
            let labelTotalPassengers = 0;
            for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
              labelReservation += parseInt(tableData[i].RESERVATIONCOUNT) || 0;
              labelMaxPassengers += parseInt(tableData[i].MAXPASSENGERS) || 0;
              labelTotalPassengers += parseInt(tableData[i].TOTALPASSENGERS) || 0;
            }
            return (
              <strong>Toplam : { labelReservation } / { labelMaxPassengers } / { labelTotalPassengers }</strong>
            );
          }
        }
      ]
    ];    
     
    
    return(
      <div>
      <div className="card card-body bg-light content-toolbar">
      <div className="d-flex">
          <div className="p-2 d-flex">
          <label className="filterLabelServisTarihi">Servis Tarih / Saat : </label>
          <Select
            className="filterSelect"
            options={this.state.filters} 
            value={this.state.selectedOption}
            onChange={this.handleFilterChange}
            placeholder="Servis tarihi seçin.."
          />
          <Select
          className="filterSelectYolcuRaporu"
          options={this.state.filtersSaat} 
          value={this.state.selectedOptionSaat}
          onChange={this.handleSaatChange}
          placeholder="Saat seçiniz.."
          isDisabled={this.state.disableSaat}
        />          
          </div>
          <div className="ml-auto p-2">
              <div className="d-flex">
              {
                this.state.isDisableFilter === false &&
                  <ReactHTMLTableToExcel  
                    className={`btn btn-info btn-excel btn-summary-excel`}  
                    table="emp-summary"
                    filename={`${this.state.excelFileName} Servis Listesi`}  
                    sheet={this.state.excelFileName}  
                    buttonText="Excel'e aktar"
                  />               
              }
                <label className="filterLabel">Servis Adı : </label> <input id="editFilter" type="text" className="form-control edit" placeholder="Servis adı" disabled={this.state.isDisableFilter} onChange={(event) => this.handleFilterSearch(event.target.value)}  />

              </div>
          </div>
      </div>   
      </div>       
      {
        this.state.loading ? <LoadingIndicator /> :
        this.props.services.length > 0 ? (
          <BootstrapTable ref='tableService' data={this.props.services} containerClass='services-list' striped hover options={options} footerData={ footerData } footer>
            <TableHeaderColumn dataField='SERVICEID' isKey={true} hidden>ID</TableHeaderColumn>
            <TableHeaderColumn ref='servicedateCol' hidden dataField='SERVICEDATE' filter={ { type: 'TextFilter', delay: 1000 } }>Servis Tarihi</TableHeaderColumn>
            <TableHeaderColumn ref='servicenameCol' width='350px' className='servicenameCol'  filter={ { type: 'TextFilter', delay: 1000 } } dataField='SERVICENAME' dataSort={ true }>Servis Adı</TableHeaderColumn>
            <TableHeaderColumn dataField='ROUTETYPE' width='200px' dataAlign='center' headerAlign='center' dataFormat={ this.routeType } dataSort={ true }>Servis Yönü</TableHeaderColumn>
            <TableHeaderColumn dataField='BUSPLATE' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Plaka </TableHeaderColumn>
            <TableHeaderColumn dataField='BUSDRIVER' width='250px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Adı </TableHeaderColumn>
            <TableHeaderColumn dataField='DRIVERPHONE' width='200px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Cep Telefonu </TableHeaderColumn>
            <TableHeaderColumn dataField='CAPACITY' dataAlign='center' headerAlign='center' width='280px' dataSort={ true }>Rezervasyon / Kapasite / Toplam </TableHeaderColumn>
            <TableHeaderColumn dataField="button" width='170px' tdStyle={ { padding: '5px' } } dataFormat={this.buttonDetail} dataSort={ true }></TableHeaderColumn>
          </BootstrapTable>   
        ) : 
        <p>Gösterilecek kayıt yok.</p>
      }
      
      {
        this.props.excelData.length > 0 &&
        <table id="emp-summary" className="excel-table">  
        <tbody>             
          <tr>
            <td><b>Servis Tarihi</b></td>
            <td>{this.state.selectedOption === null ? '' : this.state.selectedOption.value }</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>Servis Adı</b></td>
            <td><b>Servis Yönü</b></td>
            <td><b>Plaka</b></td>
            <td><b>Sürücü Adı</b></td>
            <td><b>Sürücü Cep Telefonu</b></td>
            <td><b>Rezervasyon | Kapasite | Toplam</b></td>
          </tr>                                   
            { 
              this.props.excelData.map((p, index) => {  
                  this.RESERVATIONCOUNT += parseInt(p.RESERVATIONCOUNT) || 0;
                  this.MAXPASSENGERS += parseInt(p.MAXPASSENGERS) || 0;
                  this.TOTALPASSENGERS += parseInt(p.TOTALPASSENGERS) || 0;
                  return (
                    <tr key={index}>  
                    <td >{p.SERVICENAME}</td>  
                    <td >{p.ROUTETYPE === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"}</td>  
                    <td >{p.BUSPLATE}</td>  
                    <td >{p.BUSDRIVER}</td>  
                    <td >{p.DRIVERPHONE}</td>
                    <td >{isNaN(parseInt(p.RESERVATIONCOUNT)) ? '0' : parseInt(p.RESERVATIONCOUNT)} | { isNaN(parseInt(p.MAXPASSENGERS)) ? '0' : parseInt(p.MAXPASSENGERS) } | {isNaN(parseInt(p.TOTALPASSENGERS)) ? '0' : parseInt(p.TOTALPASSENGERS) }</td>    
                  </tr>  
                  )
              })  
            }  
            <tr>
            <td><b>Toplam</b></td>
            <td><b>{this.props.excelData.length}</b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b>{this.RESERVATIONCOUNT} | {this.MAXPASSENGERS} | {this.TOTALPASSENGERS} </b></td>
          </tr>  
          </tbody>  
          </table>  
          
      }          
      
      
                    
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    services: state.passenger.services,
    excelData: state.passenger.excelData,
    serviceRow: state.passenger.serviceRow
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setPassenger: (passengers) => dispatch({ type: PassengerActionTypes.SET_PASSENGERS, passengers: passengers}),
    setServices:  (services) => dispatch({ type: PassengerActionTypes.SET_SERVICES, services: services}),
    setCurrentService: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE, payload: payload}),
    setExcelData: (excelData) => dispatch({ type: PassengerActionTypes.SET_EXCEL_DATA, excelData: excelData})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ServiceSummary));