import React from 'react';
import ReactDOM from 'react-dom';
import { ContextMenuTrigger } from "react-contextmenu";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';
import tr from 'date-fns/locale/tr';
import PopupModal from '../../components/popup-modal/popup-modal.component';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import ReactTooltip from "react-tooltip";
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import { showWarningLayer, showWarningLayerPopup, hideWarningLayerPopup, generateID } from '../../utils/utils';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css';
import './schedules.styles.scss';
import '../../assets/styles/react-bootstrap-table-all.min.css';
import '../../assets/styles/react-contextmenu.css';

//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action}>
        <p className="text-center passwordInfo"><b>{text}</b></p>
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class Schedules extends React.Component {
  constructor(props){
    super(props);
    this.guid = generateID();
    this.excelData = [];
    this.state = {
      loading: false,
      loadingPopup:false,
      firstLoading:true,
      loadingModalNew: false,
      loadingModalEdit:true,
      selectedScheduleID: null,
      selectedScheduleData:null,
      modalNewZamanlama:false,
      modalEditZamanlama:false,
      isFilterDisable:true,
      isRowSelected: false,
      statusOption: {label: 'Kampüse Gidiş', value: 1},
      currentSchedule:{
        SCHNAME: '',
        SCHTYPE: '',
        SCHDESC: '',
        SCHDETAILS: '',
        SCHID:''
      },
      columnFilterData:{}      
    }

  }


  componentDidMount(){
    this.getSchedules();
  }
  handleNewSchedule = () => {
    this.props.setLoadingPopup(false);
    this.setState({...this.state,statusOption: {label: 'Kampüse Gidiş', value: 1}, modalNewZamanlama:true});    
  }

  handleEditSchedule = () => {
    this.props.setLoadingPopup(false);
    if (this.state.selectedScheduleData === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama seçiniz.'});
      showWarningLayer();
      return;      
    }        

    this.setState({
      ...this.state,
      statusOption: this.state.selectedScheduleData !== null ? {label: this.state.selectedScheduleData.SCHTYPE === "1" ? 'Kampüse Gidiş' : 'Kampüsten Dönüş', value: this.state.selectedScheduleData.SCHTYPE === "1" ? 1 : 0} : null,
      modalEditZamanlama: true
    });
    
  }

  insertSchedule = async () => {
    this.props.setLoadingPopup(true);
    let schname = this.schname.value;  
    let schtype =  this.state.statusOption.value;
    let schdesc = this.schdesc.value;
    let schdetails = this.schdetails.value;  

    if (schname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama adını kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-schedule`,
      {
        "sid": this.props.user.SessionID,
        "schname": schname,
        "schtype":schtype,
        "schdesc":schdesc,
        "schdetails":schdetails
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Schedulename"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama adını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Zamanlama eklendi.'});
        showWarningLayer();
        this.props.setLoading(true);
        this.getSchedules();
        this.setState({...this.state,modalNewZamanlama:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }
  updateSchedule = async () => {
    this.props.setLoadingPopup(true);
    let schid = this.state.selectedScheduleData.SCHID;
    let schname = this.schname.value;  
    let schtype = this.state.statusOption.value;  
    let schdesc = this.schdesc.value;
    let schdetails = this.schdetails.value;  

    if (schid === "" || schid === null){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (schname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama adını kontrol edin. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-schedule`,
      {
        "sid": this.props.user.SessionID,
        "schname": schname,
        "schtype":schtype,
        "schdesc":schdesc,
        "schdetails":schdetails,
        "schid":schid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');   
        this.props.setLoadingPopup(false);       
      }else if (res.data.message === "DP_Schedulename"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama adını kontrol edin.'});
        showWarningLayerPopup();
        this.props.setLoadingPopup(false);
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Zamanlama güncellendi.'});
        showWarningLayer();
        this.props.setLoading(true);
        this.getSchedules();
        this.setState({...this.state,modalEditZamanlama:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      
    } 
  }  

  deleteSchedule = async () => {
    this.props.setLoading(true);
    let schid = this.state.selectedScheduleData.SCHID;
    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/delete-schedule`,
      {
        "sid": this.props.user.SessionID,
        "schid":schid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Scheduleid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Zamanlama silindi.'});
        this.getSchedules();
        showWarningLayer();
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }


  handleFilterSearch = (value) => {
    try{
      this.refs.schnameCol.applyFilter(value);
    }catch(ex){}
    
  }

  getSchedules = () => {
    this.setState({
      ...this.state,
      isFilterDisable:true,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-schedules`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let schedules;
        try{
          schedules = res.data.data;
          if (schedules === null || schedules === undefined){
            schedules = [];
          }
        }catch(ex){
          schedules = [];
        }
        
        this.props.setExcelData(schedules);
        this.setState({...this.state,isFilterDisable:false,firstLoading:false, loading:false});
        this.props.setSchedules(schedules);
        this.props.setLoading(false);
      }     
    })
  }

  handleStatusChange = statusOption => {
    this.setState(
      { statusOption });
  };  

  handleRowSelect = (row, isSelected, e) => {
    this.setState(
      {
      ...this.state,
      selectedScheduleData: row,
      isRowSelected: isSelected
      }
      );
  }    

  handleDeleteSchedule = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen zamanlama seçiniz.'});
      showWarningLayer();
      return;      
    }    

    confirmDialog(true,'changepassword','Seçilen zamanlamayı silmek istediğinize emin misiniz ?',() => this.deleteSchedule())();    

  }  


  //Column Templates
  suColumnType = (cell, row, type) => {
    if (type === "schtype"){
      return cell === "1" ? '<span class="badge badge-primary columnInfo">Kampüse Gidiş</span>' : '<span class="badge badge-warning columnInfo">Kampüsten Dönüş</span>'
    }
  }    


  afterColumnFilter = (filterConds, result) => {
    //console.log(filterConds);
    const tempData = this.props.excelData;
    if (JSON.stringify(tempData) !== JSON.stringify(result)){
      this.props.setExcelData(result);
    }

    if (JSON.stringify(this.state.columnFilterData) !== JSON.stringify(filterConds)){    
      this.setState({
        ...this.state,
        columnFilterData: filterConds
      });
    }
  }

  render(){
    const durumOptions = [
      { value: 0, label: 'Kampüsten Dönüş' },
      { value: 1, label: 'Kampüse Gidiş' }
    ];     
    //const editGuid = generateID();
    //let tempDate = formatNow(new Date(),"YYYY-MM-DD");
    //const excelFileName = tempDate;
    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 50,
      noDataText: 'Gösterilecek kayıt yok',
      afterColumnFilter: this.afterColumnFilter,
    };    
    const selectRow = {
      mode: 'radio',  // multi select
      clickToSelect: true,
      className: 'row-select-class',
      bgColor: '#004288',
      Color:'white',
      onSelect: this.handleRowSelect,
      selected: this.state.selectedScheduleData !== null ? [this.state.selectedScheduleData.SCHID] : []      
    };   
    const footerData = [
      [
        {
          label: '',
          columnIndex: 1,
          align: 'left',
          formatter: (tableData) => {
            //let total = tableData.length;
            let total = this.props.excelData.length;
            return (
              <strong>Toplam : { total } zamanlama</strong>
            );
          }
          
        }
      ]
    ];    

    const scheduleStatTypes = {
      0: 'Kampüsten Dönüş',
      1: 'Kampüse Gidiş'
    };    
    

    return(
      <div className="passengersPageWrapper">
      <div className="card card-body bg-light content-toolbar">
      <div className="d-flex">
          <div className="p-2">
            <button type="button" className="btn btn-sm btn-general btn-light p-2 margin-left-5 userbuttons minibuttons" data-tip="Zamanlama Ekle" onClick={() => this.handleNewSchedule()}><i className="fas fa-plus"></i></button>
            <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Zamanlama Düzelt" onClick={() => this.handleEditSchedule()}><i className="fas fa-edit"></i> </button>
            <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Zamanlama Sil" onClick={() => this.handleDeleteSchedule()}><i className="fas fa-times"></i> </button>
          </div>
          <div className="ml-auto p-2">
            <div className="d-flex">
               <label className="filterLabelYolcuIslemleri">Filtrele :</label><input id="editFilter" type="text" disabled={this.state.isFilterDisable} className="form-control edit editFilter margin-right-5" placeholder="Zamanlama Adı" onChange={(event) => this.handleFilterSearch(event.target.value)}  />
            </div>              
          </div>
      </div>   
      </div>       
        <div className="SplitterPanelLeft">
        <ContextMenuTrigger id="submenu1" holdToDisplay={1000}>            
        {
          this.state.firstLoading ? <LoadingIndicator /> :
          this.props.schedules.length > 0 ? (
            <BootstrapTable data={ this.props.schedules } container className='driver-list surucu-tanimlari-table' hover options={options}  selectRow={ selectRow } footerData={ footerData } footer pagination >
              <TableHeaderColumn dataField='SCHID' isKey={true} hidden>ID</TableHeaderColumn>
              <TableHeaderColumn dataField='SCHNAME' width='auto' ref='schnameCol' className="displayCol" filter={ { type: 'TextFilter', delay: 1000}}  dataSort={ true } headerAlign='center'>Zamanlama Adı</TableHeaderColumn>
              <TableHeaderColumn dataField='SCHTYPE' width='200px' ref='schtypeCol' className="displayCol" dataSort={ true } dataAlign='center' headerAlign='center' filterFormatted formatExtraData={ scheduleStatTypes } dataFormat={(cell,row) => this.suColumnType(cell,row,'schtype') }>Rota Yönü</TableHeaderColumn>
              <TableHeaderColumn dataField='SCHDESC' width='250px' dataSort={ true } dataAlign='center'>Açıklama</TableHeaderColumn>
              <TableHeaderColumn dataField='SCHDETAILS' width='300px' ref='schdetailsCol' className="displayCol" dataSort={ true }>Detaylar</TableHeaderColumn>
          </BootstrapTable>          
          ) : 
          <p className="padding15">Gösterilecek kayıt yok.</p>
        }              
        </ContextMenuTrigger>        
                        
          {
            <table id="emp-zamanlama-tanimlari" className="excel-table">  
            <tbody>             
              <tr>
                <td><b>Zamanlama Adı {this.state.columnFilterData === null || this.state.columnFilterData === undefined ? '' : Object.keys(this.state.columnFilterData).length === 0 ? '' : this.state.columnFilterData.SCHNAME !== undefined && '*'}</b></td>
                <td><b>Rota Yönü</b></td>
                <td><b>Açıklama</b></td>
                <td><b>Detaylar</b></td>
              </tr>                                   
                { 
                  this.props.excelData.map((p, index) => {  
                      return (
                      <tr key={index}>  
                        <td >{p.SCHNAME}</td>  
                        <td >{p.SCHTYPE === "1" ? "Kampüse Gidiş" : "Kampüsten Dönüş" }</td>  
                        <td >{p.SCHDESC}</td>  
                        <td >{p.SCHDESC}</td>  
                      </tr>  
                      )
                  })  
                }  
                <tr>
                <td><b>Toplam</b></td>
                <td><b>{this.props.excelData.length}</b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td><b></b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>  
              </tbody>  
              </table>  
              
          }          
                  


        </div>
          <PopupModal
              show={this.state.modalNewZamanlama}
              onHide={() => this.setState({...this.state,modalNewZamanlama:false})}
              titlemodal={`Yeni Zamanlama Ekle`}
              onClick={()=> this.insertSchedule}
            >
            
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen zamanlama bilgilerini giriniz.</p>
            <form className="address-form" name="schedule-form">
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schname_${this.guid}`}>Zamanlama Adı : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`schname_${this.guid}`}  ref={(ref)=>{ this.schname = ref;}} placeholder="Zamanlama Adı zorunlu"  required />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schtype_${this.guid}`}>Rota Yönü :</label>
                <Select id={`schtype_${this.guid}`} className="edit max-width-element" placeholder="Rota Yönü" options={durumOptions} value={this.state.statusOption} onChange={this.handleStatusChange} />
              </div>
            </div>             
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schdesc_${this.guid}`}>Açıklama :</label>
                <input type="text" className="form-control" id={`schdesc_${this.guid}`}  ref={(ref)=>{ this.schdesc = ref;}} placeholder="Açıklama"   />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schdetails_${this.guid}`}>Detaylar :</label>
                <textarea className="form-control" id={`schdetails_${this.guid}`}  ref={(ref)=>{ this.schdetails = ref;}} placeholder="Detaylar"></textarea>
              </div>
            </div>            
                                    
            </form>                

            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
          </PopupModal> 
          
          <PopupModal
            show={this.state.modalEditZamanlama}
            onHide={() => this.setState({...this.state,modalEditZamanlama:false})}
            titlemodal={`Zamanlama Düzelt - ${this.state.selectedScheduleData === null ? '' : this.state.selectedScheduleData.SCHNAME }`}
            onClick={()=> this.updateSchedule}
            state={this.state}
          
          >
        
            <p className="contentInfoText"><i className="fas fa-info-circle su-color"></i> Lütfen zamanlama bilgilerini giriniz.</p>
            <form className="address-form" name="schedule-form">
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schname_${this.guid}`}>Zamanlama Adı : <span className="requiredFieldMark">*</span></label>
                <input type="text" className="form-control" id={`schname_${this.guid}`}  ref={(ref)=>{ this.schname = ref;}} placeholder="Zamanlama Adı zorunlu" defaultValue={this.state.selectedScheduleData === null ? "" : this.state.selectedScheduleData.SCHNAME}  required />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schtype_${this.guid}`}>Rota Yönü :</label>
                <Select id={`schtype_${this.guid}`} className="edit max-width-element" placeholder="Rota Yönü" options={durumOptions} value={this.state.statusOption} onChange={this.handleStatusChange} />
              </div>
            </div>             
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schdesc_${this.guid}`}>Açıklama :</label>
                <input type="text" className="form-control" id={`schdesc_${this.guid}`}  ref={(ref)=>{ this.schdesc = ref;}} placeholder="Açıklama" defaultValue={this.state.selectedScheduleData === null ? "" : this.state.selectedScheduleData.SCHDESC}   />
              </div>
            </div>
            <div className="form-row address-row">
              <div className="col-md-6 mb-6">
                <label htmlFor={`schdetails_${this.guid}`}>Detaylar :</label>
                <textarea className="form-control" id={`schdetails_${this.guid}`}  ref={(ref)=>{ this.schdetails = ref;}} placeholder="Detaylar" defaultValue={this.state.selectedScheduleData === null ? "" : this.state.selectedScheduleData.SCHDETAILS}></textarea>
              </div>
            </div>  
               
            </form>                
            <LoadingLayer showLoading={this.props.showLoadingPopup} />
            <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
        </PopupModal>           
      
      

            <ReactTooltip /> 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    schedules: state.definitions.schedules,
    excelData: state.definitions.excelData,
    showLoading:state.login.showLoading,
    showLoadingPopup:state.login.showLoadingPopup,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setSchedules: (schedules) => dispatch({ type: DefinitionsActionTypes.SET_SCHEDULES, schedules: schedules}),
    setExcelData: (excelData) => dispatch({ type: DefinitionsActionTypes.SET_EXCEL_DATA, excelData: excelData})
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Schedules));