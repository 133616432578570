import React, { Fragment } from 'react';

import './service-change-button.styles.scss';

const ServiceChangeButton = ({classes,children, ...otherProps}) => (
  <Fragment>
    <div className="service-button-wrapper">
      <button type="button" className={`${classes} btn btn-default btn-block btnEDMSDefault`}
        {...otherProps}
      >{children}</button>
    </div>
  </Fragment>
);



export default ServiceChangeButton;