import { DefinitionsActionTypes  } from './definitions.types';

const INITIAL_STATE = {
  buses:[],
  drivers:[],
  users:[],
  schedules:[],
  busroutes:[],
  excelData:[],
  routeBusDrivers:[]
}

const definitionsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){

    case DefinitionsActionTypes.SET_BUSES:
      return {
        ...state,
        buses: action.buses
      }

    case DefinitionsActionTypes.SET_DRIVERS:
      return {
        ...state,
        drivers: action.drivers
      }

    case DefinitionsActionTypes.SET_USERS:
      return {
        ...state,
        users: action.users
      }

    case DefinitionsActionTypes.SET_SCHEDULES:
      return {
        ...state,
        schedules: action.schedules
      }
  
    case DefinitionsActionTypes.SET_BUSROUTES:
      return {
        ...state,
        busroutes: action.busroutes
      }
  
    case DefinitionsActionTypes.SET_ROUTEBUSDRIVERS:
      return {
        ...state,
        routeBusDrivers: action.routeBusDrivers
      }  

  
    case DefinitionsActionTypes.SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.excelData
      }        

    default:
      return state;
  }
}

export default definitionsReducer;