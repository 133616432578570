import React from 'react';
import { useLocation, Link } from "react-router-dom";
import LoginLogo from '../../components/login-logo/login-logo.component';
import LoginCopyright from '../../components/login-copyright/login-copyright.component';
import axios from 'axios';
import './resetpassword.styles.scss';

class ResetPassword extends React.Component{
	constructor(){
		super();
		this.state = {
			message:"",
			status:false,
		};
	}
	// shouldComponentUpdate(){
	// 			return false;
	// } 

	resetPasswordByLink = async (r,e) => {
		let result = false;
		if (this.state.status === false){
			const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/reset-password-bylink`,{ "rid": r, "email": e},{crossDomain: true});
			if (res.data.type === "error"){
				result = false;
			} else{
				if (res.data.message === "DP_Done"){
					this.setState({...this.state,status:true,message:"Şifreniz başarıyla sıfırlandı ve e-posta adresinize gönderilmiştir.Lütfen e-posta adresinizi kontrol edin."});
					result = true;
				}else{
					this.setState({...this.state,status:false,message:"Geçersiz link veya e-posta adresi."});
					result = false;
				}
			} 
			return result;
		}else{
			return false;
		}
		
	};  	

	componentDidMount(){
		const query = new URLSearchParams(this.props.location.search);;
		const email = query.get("e");
		const rid = query.get("rid");
		if (email === "" || rid === ""){
			this.setState({message:"Geçersiz link veya e-posta adresi."});
		}else{
			this.resetPasswordByLink(rid,email);
		}



	}
	useQuery = () => {
		return new URLSearchParams(useLocation().search);
	}

	render(){
		return(
			<div className="overlayID">
			<LoginLogo logoName="sabanci-logo.jpg" appName="Servis Radar Personel" />
			<form className="loginForm">
						<div className="loginFormElements bg-white resetpageInfo">            
									<h4 className="resetpageTitle">Şifre Sıfırla</h4>
									<p className="resetpageMessage">{this.state.message}</p>
									<Link className="btn btn-su" to="/">Giriş Sayfasına Git</Link>
						</div>
			</form>
			
			<LoginCopyright copyrightText='Tüm hakları saklıdır' copyright='DocPlant' />   
			</div>
		)
	}
}



export default ResetPassword;