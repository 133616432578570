import React from 'react';
import './custom-button.styles.scss';

const CustomButton = ({children, inverted, ...otherProps}) => (
  <button
    className={`${inverted ? 'inverted' : ''} btn btn-default btn-block btnEDMSDefault`}
    {...otherProps}
  >
  {children}
  </button>
);

export default CustomButton;