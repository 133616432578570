import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PopupModal from '../../components/popup-modal/popup-modal.component';
//import DualListBox from 'react-dual-listbox';
import DatePicker from "react-datepicker";
import tr from 'date-fns/locale/tr';
import { formatToTimeZone } from 'date-fns-timezone';

import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import JqueryConfirm from '../jquery-confirm/jquery-confirm.component';
import { showWarningLayer, fromTimeToDate, showWarningLayerPopup, hideWarningLayerPopup } from '../../utils/utils';
import WarningLayerPopup from '../warning-layer-popup/warning-layer-popup.component';
import LoadingLayer from '../loading-layer/loading-layer.component';

import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-tabs/style/react-tabs.css';
import './routes.styles.scss';
import '../../assets/styles/react-bootstrap-table-all.min.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';

//JQUERY CONFIRM DIALOG BOX
const confirmDialog = (open = false, type = 'alert', text = '', action) => () => {
  ReactDOM.render(
      <JqueryConfirm open={open} type={type} action={action}>
        <p className="text-center passwordInfo"><b>{text}</b></p>
      </JqueryConfirm>
   , document.getElementById('jqueryconfirm'));  
}


class Routes extends React.Component {
  constructor(props){
    super(props);
    //console.log(props.selecteduser);
    this.state = {
      loading: false,
      loadingPopup:false,
      selectedPassengerID: null,
      modalShowNewRoute: false,
      modalNewUserShow:false,
      modalEditUserShow:false,
      modalRotaTasima:false,
      modalServisTasima:false,
      modalAdresTanimlari:false,
      modalRotaTanimlari:false,
      modalYeniAdres:false,
      modalDuzeltAdres:false,
      modalShowSetTime:false,
      activeRouteDisable:true,
      userRotes:[],
      userAddress:[],
      userRouteAddress:[],
      selectedOption: null,
      selectedOptionAddress: null,
      activeRoutes:[],
      selectedUserID:null,
      selectedUser:props.pagedata.selectedUserData,
      selectedRouteData: null,
      isFilterDisable:true,
      isRowSelected:false,
      servisSaati: new Date(),
      servisSaatiEdit: new Date()
    }

  }

  componentDidMount(){
    this.getPassengerRouteAddress(this.props.pagedata.selectedUserData.USERID);
  }

  getPassengerRouteAddress = (userid) => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-passenger-route-address`,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let routes;
        try{
          routes = res.data.data;
          if (routes === null || routes === undefined){
            routes = [];
          }
        }catch(ex){
          routes = [];
        }
        
        this.setState({...this.state, loading:false, isFilterDisable: false });
        this.props.setRoutes(routes);
      }     
    })  
  }    

  insertAddress = async () => {
    this.props.setLoading(true);
    let addressname = this.addressname.value;  
    let address1 = this.address1.value;  
    let address2 = this.address2.value;  
    let semt = this.semt.value;  
    let ilce = this.ilce.value;  
    let postakodu = this.postakodu.value;  
    let locationx = this.locationx.value;  
    let locationy = this.locationy.value;  
    let locationz = this.locationz.value;  

    if (addressname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz. '});
      showWarningLayer();
      this.props.setLoading(true);
      return false;
    }

    if (address1 === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz. '});
      showWarningLayer();
      this.props.setLoading(true);
      return false;
    }


    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "userid": this.state.selectedUserID,
        "addressname": addressname,
        "address1":address1,
        "address2":address2,
        "semt":semt,
        "ilce":ilce,
        "postakodu":postakodu,
        "locationx":locationx,
        "locationy":locationy,
        "locationz":locationz
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Addressname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Address1"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres eklendi.'});
        showWarningLayer();
        this.getPassengerAddress(this.state.selectedUserID);
        this.setState({...this.state,modalYeniAdres:false});
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }

  handleGlobalAddressChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        ...this.state,
        currentAddress: {
          ...this.state.currentAddress,
          [name.toUpperCase()]:value
        }
      }
    )
  }  

  handleGirisServisSaati = (date) => {
    this.setState({
      ...this.state,
      servisSaati: date
    })
  }  

  handleGirisServisSaatiEdit = (date) => {
    this.setState({
      ...this.state,
      servisSaatiEdit: date
    })
  }    

  updateRouteTime = async () => {
    this.props.setLoading(true);
    let addressname = this.addressname.value;  
    let address1 = this.address1.value;  
    let address2 = this.address2.value;  
    let semt = this.semt.value;  
    let ilce = this.ilce.value;  
    let postakodu = this.postakodu.value;  
    let locationx = this.locationx.value;  
    let locationy = this.locationy.value;  
    let locationz = this.locationz.value;  

    if (addressname === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz. '});
      showWarningLayer();
      this.props.setLoading(true);
      return false;
    }

    if (address1 === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz. '});
      showWarningLayer();
      this.props.setLoading(true);
      return false;
    }


    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-passenger-address`,
      {
        "sid": this.props.user.SessionID,
        "userid": this.state.selectedUserID,
        "padrid": this.state.currentAddress.PADRID,
        "addressname": addressname,
        "address1":address1,
        "address2":address2,
        "semt":semt,
        "ilce":ilce,
        "postakodu":postakodu,
        "locationx":locationx,
        "locationy":locationy,
        "locationz":locationz
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Padrid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Addressname"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres adı giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }else if (res.data.message === "DP_Address1"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres satır 1 giriniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Adres güncellendi.'});
        showWarningLayer();
        this.getPassengerAddress(this.state.selectedUserID);
        this.setState({...this.state,modalDuzeltAdres:false});
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }

  handleDeleteRoute = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota seçiniz.'});
      showWarningLayerPopup();
      return;      
    }    

    //

    let uid = this.props.pagedata.selectedUserData.USERID;
    let rid = this.state.selectedRouteData.ROUTEID;
    let padrid = this.state.selectedRouteData.PADRID;

    confirmDialog(true,'changepassword','Rotayı silmek istediğinizden emin misiniz ?',() => this.deleteRoute(padrid,rid,uid))();  

  }

  deleteRoute = async (padrid,rid,uid) => {
    this.props.setLoading(true);

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/remove-passenger-route`,
      {
        "sid": this.props.user.SessionID,
        "padrid":padrid,
        "rid":rid,
        "uid":uid
      },
      {crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }else if (res.data.message === "DP_Padrid"){
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz.'});
        showWarningLayer();
        this.props.setLoading(false);
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Rota silindi.'});
        this.getPassengerRouteAddress(uid);
        showWarningLayer();
      }else{
        this.props.setLoading(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayer();
      }
      
    } 
  }


  handleFilterSearch = (value) => {
    try{
      this.refs.displaynameCol.applyFilter(value);
    }catch(ex){}
    
  }
  
  //...
  handleChangeUserRoute = async (selectedOptionRoutes) => {
    this.setState({...this.state, loading:false, selectedOptionRoutes });    
  } 


  handleChangeAddress = selectedOptionAddress => {
    this.setState(
      { ...this.state, selectedOptionAddress }
    );
  };

  goBack = () => {
    this.props.history.push('/yolcu-islemleri',this.props.location.state);
  }  

  getUserRoutes = async (userid) => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-routes-not-passenger`,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let routes;
      try{
        routes = res.data.data;
        if (routes === null || routes === undefined){
          routes = [];
        }
      }catch(ex){
        routes = [];
      }
      this.getUserAddress(userid);
      //console.log(JSON.stringify(routes));
      //console.log(services);
      this.props.setLoadingPopup(false);        
      this.setState({...this.state, userRotes: routes });
    } 
  }

  getUserAddress = async (userid) => {
    // this.setState({
    //   ...this.state,
    //   loading:true
    // });

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/get-passenger-address-select`,{ "sid": this.props.user.SessionID, "uid": userid},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');          
      }
    } else{
      let address;
      try{
        address = res.data.data;
        if (address === null || address === undefined){
          address = [];
        }
      }catch(ex){
        address = [];
      }
      
      //console.log(JSON.stringify(routes));
      //console.log(services);
      this.setState({...this.state, userAddress: address });
    } 
  }  


  handleRowSelect = (row, isSelected, e) => {
    let xdate = row.SERVICETIME === "" ? fromTimeToDate("00:00") : fromTimeToDate(row.SERVICETIME);
    this.setState(
      {
      ...this.state,
      servisSaatiEdit: xdate,
      selectedRouteData: row,
      isRowSelected: isSelected
      }
      );
  }  

  goTo = (page,currentData) => {
    if (page === "kullanici-servisleri"){
      this.props.history.push('/yolcu-islemleri-kullanici-servisleri',currentData);
    }else if (page === "yolcu-ekle"){
      this.props.history.push('/yolcu-islemleri-yolcu-ekle',currentData);
    }else if (page ==="yolcu-duzelt"){
      this.props.history.push('/yolcu-islemleri-yolcu-duzelt',currentData);
    }else if (page ==="rota-tanimlari"){
      this.props.history.push('/rota-tanimlari',currentData);
    }
  } 

  handleNewRoute = () => {
    // if (this.state.isRowSelected === false){
    //   this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota seçiniz.'});
    //   showWarningLayer();
    //   return;      
    // }    
    if (this.state.pagedata !== null){
      this.props.setLoadingPopup(true);
      this.getUserRoutes(this.props.pagedata.selectedUserData.USERID); 
      this.setState({...this.state, selectedUserID: this.props.pagedata.selectedUserData.USERID, selectedUser: this.props.pagedata.selectedUserData.DISPLAYNAME, modalShowNewRoute:true});    
    }
  }

  handleSetTime = () => {
    if (this.state.isRowSelected === false){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota seçiniz.'});
      showWarningLayer();
      return;      
    }    
    this.setState({...this.state, modalShowSetTime:true});    

  }

  addRoute = async () => {
    this.props.setLoadingPopup(true);        
    let srid = "";
    let trid = "";
    try{
      srid = this.state.selectedOptionRoutes === null ? null : this.state.selectedOptionRoutes.value;
      trid = this.state.selectedOptionAddress === null ? null : this.state.selectedOptionAddress.value;
    }catch(ex){}
    const stime = this.state.servisSaati;
    const date = new Date(stime);
    const format = 'HH:mm';
    const output = formatToTimeZone(date, format, { timeZone: 'Europe/Istanbul' });

    if (srid === null || srid === undefined || srid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (trid === null || trid === undefined || trid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/add-passenger-route`,{ "sid": this.props.user.SessionID, "userid": this.state.selectedUserID,"routeid":srid, "padrid":trid, "stime":output},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage'); 
        this.props.setLoadingPopup(false);                 
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Rota eklendi.'});
        showWarningLayer();
        this.getPassengerRouteAddress(this.props.pagedata.selectedUserData.USERID)
        this.setState({...this.state,modalShowNewRoute:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      this.props.setLoadingPopup(false);
    }     
  }  

  setTime = async () => {
    let srid = "";
    let trid = "";
    try{
      srid = this.state.selectedRouteData.ROUTEID;
      trid = this.state.selectedRouteData.PADRID;
    }catch(ex){}
    const stime = this.state.servisSaatiEdit;
    const date = new Date(stime);
    const format = 'HH:mm';
    const output = formatToTimeZone(date, format, { timeZone: 'Europe/Istanbul' });
    this.props.setLoadingPopup(true);
    if (srid === null || srid === undefined || srid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen rota seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    if (trid === null || trid === undefined || trid === ""){
      this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen adres seçiniz. '});
      showWarningLayerPopup();
      this.props.setLoadingPopup(false);
      return false;
    }

    const res = await axios.post(`https://azr04.infotraq.com/servisradarsu/update-passenger-time`,{ "sid": this.props.user.SessionID, "userid": this.props.pagedata.selectedUserData.USERID,"routeid":srid, "padrid":trid, "stime":output},{crossDomain: true});
    if (res.data.type === "error"){
      if (res.data.message === "DP_Session"){
        this.props.logOut();
        this.props.setDefault();
        const bodyElement = document.querySelector('body');
        bodyElement.classList.remove('noBodyImage');  
        this.props.setLoadingPopup(false);        
      }
    } else{
      if (res.data.message === "DP_Done"){
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'success', warningMessageText: 'Servis saati güncellendi.'});
        showWarningLayer();
        this.getPassengerRouteAddress(this.props.pagedata.selectedUserData.USERID)
        this.setState({...this.state,modalShowSetTime:false});
      }else{
        this.props.setLoadingPopup(false);
        this.props.onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu.Lütfen tekrar deneyiniz.'});
        showWarningLayerPopup();
      }
      this.props.setLoadingPopup(false);
    }     
  }  


  //Column Templates
  suColumnType = (cell, row, type) => {
    if (type === "faturalimi"){
      return cell === "1" ? '<span class="badge badge-success columnInfo">Evet</span>' : '<span class="badge badge-primary columnInfo">Hayır</span>'
    }else if (type === "isactive"){
      return cell === "1" ? '<span class="badge badge-success columnInfo">Aktif</span>' : '<span class="badge badge-danger columnInfo">Pasif</span>'
    }
    
  }  

  render(){

    const options = {
      sizePerPageList: [],
      hidePageListOnlyOnePage: true,
      sizePerPage: 100,
      noDataText: 'Gösterilecek kayıt yok'
    };    
    const selectRow = {
      mode: 'radio',  // multi select
      clickToSelect: true,
      className: 'row-select-class',
      bgColor: '#004288',
      Color:'white',
      onSelect: this.handleRowSelect      
    };    
    return(
      <div className="passengersPageWrapper">
        <div className="card card-body bg-light content-toolbar">
          <div className="d-flex">
              <div className="p-2">
                <button type="button" className="btn btn-sm btn-general btn-light p-2 margin-left-5 userbuttons minibuttons" data-tip="Yolcu Rotası Ekle" onClick={() => this.handleNewRoute()}><i className="fas fa-plus"></i></button>
                <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons " data-tip="Yolcu Rotası Sil" onClick={() => this.handleDeleteRoute()}><i className="fas fa-times"></i> </button>
                <button type="button" className="btn btn-sm btn-general btn-light margin-left-5 userbuttons minibuttons" data-tip="Rota Saati Ayarla" onClick={() => this.handleSetTime()}><i className="fas fa-clock"></i></button>
              </div>
              <div className="ml-auto p-2">
                <div className="d-flex">
                   <label className="filterLabelYolcuIslemleri">Filtrele :</label><input id="editFilter" type="text" disabled={this.state.isFilterDisable} className="form-control edit editFilter margin-right-5" placeholder="Rota Adı Filtrele" onChange={(event) => this.handleFilterSearch(event.target.value)}  />
                   <button type="button" className="btn btn-general btn-light margin-left-5 userbuttons" onClick={() => this.goBack()} ><i className="fas fa-arrow-left"></i> Geri</button>
                </div>              
              </div>
          </div>   
          </div> 

            <div className="SplitterPanelLeft">
            {
              this.state.loading ? <LoadingIndicator /> :
              this.props.routes.length > 0 ? (
                <BootstrapTable data={ this.props.routes } container className='routes-list' hover options={options} pagination selectRow={ selectRow }>
                  <TableHeaderColumn dataField='ROUTEID' isKey={true} hidden>ID</TableHeaderColumn>
                  <TableHeaderColumn ref='displaynameCol' className='displaynameCol'  filter={ { type: 'TextFilter', delay: 1000 } } dataField='ROUTENAME' width='450px' dataSort={ true }>Rota Adı</TableHeaderColumn>
                  <TableHeaderColumn  width='150px' dataField='SCHNAME'  dataSort={ true } >Planlama Adı</TableHeaderColumn>
                  <TableHeaderColumn dataField='ROUTETIME' width='150px' dataSort={ true }>Rota Zamanı</TableHeaderColumn>
                  <TableHeaderColumn dataField='BUSPLATE' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Servis Plaka</TableHeaderColumn>
                  <TableHeaderColumn dataField='BUSCAPACITY' width='200px' dataSort={ true }>Servis Kapasite</TableHeaderColumn>
                  <TableHeaderColumn dataField='BUSLIMIT' width='200px' dataSort={ true }>Servis Limit</TableHeaderColumn>
                  <TableHeaderColumn dataField='SERVICETIME' width='200px' dataSort={ true }>Servis Saati</TableHeaderColumn>
                  <TableHeaderColumn dataField='DRIVERNAME' width='200px' dataSort={ true }>Sürücü Adı</TableHeaderColumn>
                  <TableHeaderColumn dataField='DRIVERPHONE' width='200px' dataSort={ true } dataAlign='center' headerAlign='center'>Sürücü Telefon</TableHeaderColumn>
                  <TableHeaderColumn dataField='ADDRESSNAME' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Adres Adı</TableHeaderColumn>
                  <TableHeaderColumn dataField='ADDRESS1' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Adres 1. Satır</TableHeaderColumn>
                  <TableHeaderColumn dataField='ADDRESS2' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Adres 2. Satır</TableHeaderColumn>
                  <TableHeaderColumn dataField='SEMT' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Semt</TableHeaderColumn>
                  <TableHeaderColumn dataField='ILCE' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>İlçe</TableHeaderColumn>
                  <TableHeaderColumn dataField='POSTAKODU' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Posta Kodu</TableHeaderColumn>
                  <TableHeaderColumn dataField='LOCATIONX' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Lokasyon X</TableHeaderColumn>
                  <TableHeaderColumn dataField='LOCATIONY' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Lokasyon Y</TableHeaderColumn>
                  <TableHeaderColumn dataField='LOCATIONZ' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Lokasyon Z</TableHeaderColumn>
                  <TableHeaderColumn dataField='VALIDFROM' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Başlangıç Zamanı</TableHeaderColumn>
                  <TableHeaderColumn dataField='VALIDTO' width='200px' dataAlign='center' headerAlign='center'  dataSort={ true }>Bitiş Zamanı</TableHeaderColumn>
                </BootstrapTable>          
              ) : 
              <p className="padding15">Gösterilecek kayıt yok.</p>
            }          

            </div>
            
            <PopupModal
                show={this.state.modalShowNewRoute}
                onHide={() => this.setState({...this.state,modalShowNewRoute:false})}
                titlemodal={`Yeni Yolcu Rotası - ${this.state.selectedUser}`}
                onClick={()=> this.addRoute}>
                <p className="rotalabel">Rotalar</p> 
                <Select
                  placeholder="Rota seçiniz"
                  value={this.selectedOptionRoutes}
                  onChange={this.handleChangeUserRoute}
                  options={this.state.userRotes}
                />
                <br/>
                <p className="rotalabel">Adresler</p> 
                <Select
                  placeholder="Adres seçiniz"
                  value={this.state.selectedOptionAddress}
                  options={this.state.userAddress}
                  onChange={this.handleChangeAddress}
                />
                <br/>
                <p className="rotalabel">Servis Saati</p> 
                <DatePicker
                    selected={this.state.servisSaati} 
                    onChange={this.handleGirisServisSaati} 
                    className="form-control edit max-width-element"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    locale={tr}
                    timeCaption="Saat"
                    dateFormat="HH:mm"
                  />    
              <LoadingLayer showLoading={this.props.showLoadingPopup} />
              <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
    
            </PopupModal>      

            <PopupModal
                show={this.state.modalShowSetTime}
                onHide={() => this.setState({...this.state,modalShowSetTime:false})}
                titlemodal={`${this.state.selectedRouteData === null ? '' : this.state.selectedRouteData.ROUTENAME }`}
                onClick={()=> this.setTime}>
                <p className="rotalabel">Rota Servis Saati</p> 
                <DatePicker
                    selected={this.state.servisSaatiEdit} 
                    onChange={this.handleGirisServisSaatiEdit} 
                    className="form-control edit max-width-element"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    locale={tr}
                    timeCaption="Saat"
                    dateFormat="HH:mm"
                  />    
                <LoadingLayer showLoading={this.props.showLoadingPopup} />
                <WarningLayerPopup hideWarning={hideWarningLayerPopup} text={this.props.warningMessage} warningType={this.props.warningMessageType} />
    
            </PopupModal>              


            <ReactTooltip /> 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    routes: state.passenger.routes,
    showLoadingPopup:state.login.showLoadingPopup,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType

  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setLoadingPopup: (showLoadingPopup) => dispatch({ type: LoginActionTypes.SET_LOADING_POPUP, showLoadingPopup: showLoadingPopup}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setRoutes: (routes) => dispatch({ type: PassengerActionTypes.SET_ROUTES, routes: routes}),
    setAddress: (payload) => dispatch({ type: PassengerActionTypes.SET_ADDRESS_FORM, payload: payload})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Routes));