import { PassengerActionTypes  } from './passenger.types';

const INITIAL_STATE = {
  passengers:[],
  services:[],
  routes:[],
  passengersList:[],
  currentServiceID: "",
  currentServiceName: "",
  serviceRow: null,
  serviceDate: null,
  excelData: [],
  excelYolcu: [],
  formAddress: {
    addressname: "",
    address1: "",
    address2: "",
    semt:"",
    ilce: "",
    postakodu: "",
    locationx: "",
    locationy: "",
    locationz: ""
  }      
}

const passengerReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){

    case PassengerActionTypes.SET_PASSENGERS:
      return {
        ...state,
        passengers: action.passengers
      }

    case PassengerActionTypes.SET_SERVICES:
      return {
        ...state,
        services: action.services
      }

    case PassengerActionTypes.SET_ROUTES:
      return {
        ...state,
        routes: action.routes
      }

    case PassengerActionTypes.SET_PASSENGERS_LIST:
      return {
        ...state,
        passengersList: action.passengersList
      }      

    case PassengerActionTypes.SET_CURRENT_SERVICE:
      return {
        ...state,
        currentServiceID: action.payload.SERVICEID,
        currentServiceName: action.payload.SERVICENAME,
        serviceRow: action.payload
      }
  
      
    case PassengerActionTypes.SET_CURRENT_SERVICE_ID:
      return {
        ...state,
        currentServiceID: action.value
      }
        
    case PassengerActionTypes.SET_CURRENT_SERVICE_NAME:
      return {
        ...state,
        currentServiceName: action.value
      }

    case PassengerActionTypes.SET_SERVICE_DATE:
      return {
        ...state,
        serviceDate: action.payload
      }
  
    case PassengerActionTypes.SET_SERVICE_FILTERS:
      return {
        ...state,
        filters: action.payload
      }

    case PassengerActionTypes.SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.excelData
      }      

      
    case PassengerActionTypes.SET_EXCEL_YOLCU:
      return {
        ...state,
        excelYolcu: action.excelYolcu
      }      
  
    case PassengerActionTypes.SET_ADDRESS_FORM:
      return {
        ...state,
        formAddress:{
          ...state.formAddress,
          [action.payload.name]: action.payload.value
        } 
      }      


    default:
      return state;
  }
}

export default passengerReducer;