import { LoginActionTypes  } from './login.types';
import '../project/project.actions';
import axios from 'axios';

export const loginStart = (value) => {
  return{
    type: LoginActionTypes.LOGIN_START,
    loginStart: value
  }
}

export const loginSuccess = (loginData) => {
  return {
    type: LoginActionTypes.LOGIN_SUCCESS,
    loginData: loginData,
    User: loginData.data.data,
    error:null
  }
}
// will be merged later
export const loginFail = (loginData) => {
  return {
    type: LoginActionTypes.LOGIN_FAIL,
    loginData: loginData,
    error: loginData.data.data
  }
}

export const networkFail = (payload) => {
  return {
    type: LoginActionTypes.NETWORK_FAIL,
    payload: payload
  }
}


export const setLoginWarning = (payload) => {
  return {
    type: LoginActionTypes.SET_LOGIN_WARNING,
    payload: payload
  }
}


export const setLoading = (showLoading) => {
  return {
    type: LoginActionTypes.SET_LOADING,
    showLoading: showLoading
  }
}

export const setLoadingPopup = (showLoadingPopup) => {
  return {
    type: LoginActionTypes.SET_LOADING_POPUP,
    showLoadingPopup: showLoadingPopup
  }
}


export const userSignIn = (username, password) => {
  return async dispatch => {
    dispatch(loginStart(true));
    try{
      const loginResult = await axios.post(`https://azr04.infotraq.com/servisradarsu/action-login`,{ "username":username, "password": password},{crossDomain: true});
      if (loginResult.status === 200){
        if (loginResult.data.type === "success"){
           dispatch(loginSuccess(loginResult));
        }else{
          dispatch(loginFail(loginResult));
        }
      }else{
        dispatch(loginFail(loginResult));
      }      
    }catch(err){
      dispatch(networkFail('network'));
    }
  }
}

export const handleChange = (event) => {
  return {
    type: LoginActionTypes.LOGIN_INPUT_CHANGE,
    event: event
  }
}

export const logOut = () => {
  return {
    type: LoginActionTypes.SIGN_OUT
  }
}

export const userLogout = () => {
  return dispatch => {
    dispatch(logOut());
  }
}
