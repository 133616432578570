import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator.component';
import Select from 'react-select';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import * as actionCreators from '../../redux/login/login.actions';
import { LoginActionTypes } from '../../redux/login/login.types';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { PassengerActionTypes } from '../../redux/passenger/passenger.types';
import axios from 'axios';

import './passengers-list.styles.scss';

import '../../assets/styles/react-bootstrap-table-all.min.css';

class PassengersList extends React.Component {
  constructor(){
    super();
    this.searchRef = React.createRef()
    this.excelYolcu = [];
    this.state = {
      loading: false,
      canSearch:false,
      defaultSearch:'',
      searchText:'',
      filtersTarih: [],
      filtersSaat: [],
      disableSaat:true,
      selectedOptionTarih: null,
      selectedOptionSaat: null,
      currentOptionTarihValue: "",
      currentOptionSaatValue: ""
    }

  }

  componentDidMount(){
    this.props.setPassengersList([]);
    this.getTarihFilters();
  }
 
  getTarihFilters = () => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-activeservice-dates`,{ "sid": this.props.user.SessionID},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let filtersTarih;
        try{
          filtersTarih = res.data.data;
          if (filtersTarih === null || filtersTarih === undefined){
            filtersTarih = [];
          }          
        }catch(ex){
          filtersTarih = [];
        }
        
        this.setState(
          {
            ...this.state,
            filtersSaat:[],
            disableSaat:true,
            selectedOptionSaat: null,
            currentOptionSaatValue: "",
            selectedOptionTarih: filtersTarih[0],
            currentOptionTarihValue: filtersTarih[0].value,
            filtersTarih: filtersTarih,
            loading:false
          }
        );
        
      }     
    })
  }

  getSaatFilters = (tarihValue) => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-activeservice-times`,{ "sid": this.props.user.SessionID, "sdate": tarihValue},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let filtersSaat;
        try{
          filtersSaat = res.data.data;
          if (filtersSaat === null || filtersSaat === undefined){
            filtersSaat = [];
          }          
        }catch(ex){
          filtersSaat = [];
        }
        
        this.setState(
          {
            ...this.state,
            filtersSaat: filtersSaat,
            selectedOptionSaat: null,
            currentOptionSaatValue: "",
            selectedOptionTarih: { "value":tarihValue, "label":tarihValue},
            currentOptionTarihValue: tarihValue,            
            disableSaat:false,
            loading:false
          }
        );
        //this.props.setPassengersList([]);
        
      }     
    })
  }

  getPassengersMadeReservation = (saatValue) => {
    this.setState({
      ...this.state,
      loading:true
    });
    axios.post(`https://azr04.infotraq.com/servisradarsu/get-passengers-reservation`,{ "sid": this.props.user.SessionID,"sdate":this.state.selectedOptionTarih.value, "stime": saatValue},{crossDomain: true})
    .then(res => {
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          this.props.logOut();
          this.props.setDefault();
          const bodyElement = document.querySelector('body');
          bodyElement.classList.remove('noBodyImage');          
        }
      } else{
        let passengersList;
        try{
          passengersList = res.data.data;
          if (passengersList === null || passengersList === undefined){
            passengersList = [];
          }
        }catch(ex){
          passengersList = [];
        }
        
        this.setState(
          {
            ...this.state,
            selectedOptionSaat: { "label": saatValue, "value":saatValue },
            currentOptionSaatValue: saatValue,            
            loading:false
          }
          );
        this.props.setPassengersList(passengersList);
        this.props.setExcelYolcu(passengersList);
      }     
    })
  }


  routeType = (cell, row) => {
    return cell === "1" ? "Kampüse Geliş" : "Kampüsten Dönüş"
  }

  actionStat = (cell, row) => {
    return cell === "1" ? "Rezervasyon Yapılmış" : cell === "2" ? "Rezervasyon İptal" : cell === "3" ? "Geldi" : "İşlem Yapılmamış"
  }  

  serviceStat = (cell, row) => {
    //p.SERVICESTAT > 0 ? "Rezervasyona Kapalı" : "Rezervasyona Açık"
    return cell > 0 ? "Rezervasyona Kapalı" : "Rezervasyona Açık"
  }  


  handleFilterSearch = (value) => {
    //this.refs.servicenameCol.applyFilter(value);

    if (value === ""){
      try{
        this.refs.tableService.handleSearch(value);
        this.setState({defaultSearch: value});
      }catch(e){
        return false;
      }
      
    }else{
      if (value.length >= 2){
        try{
          this.refs.tableService.handleSearch(value);
          this.setState({defaultSearch: value});
        }catch(ex){
          return false;
        }
      }else{
        return false;
      }
    }
    
  }

  handleFilterTarihChange = (selectedOptionTarih) => {
    this.setState(
      {
        ...this.state,
        loading:true,
        canSearch:false,
        selectedOptionTarih,
        currentOptionTarihValue:selectedOptionTarih.value,
        selectedOptionSaat: { "value":"","label":""},
        currentOptionSaatValue: ""

      }
    );    
    //this.searchRef.current.value = '';
    this.props.setPassengersList([]);
    this.getSaatFilters(selectedOptionTarih.value);


  }

  handleFilterSaatChange = (selectedOptionSaat) => {
    this.setState(
      {
        ...this.state,
        loading:true,
        canSearch:false,
        selectedOptionSaat,
        currentOptionSaatValue:selectedOptionSaat.value
      }
    );    
    //this.searchRef.current.value = '';
    this.getPassengersMadeReservation(selectedOptionSaat.value);
    //this.getSaatFilters(selectedOptionTarih.value);

  }

  afterSearch = (searchText, result) => {
    const tempData = this.props.excelYolcu;
    //const currentData = this.refs.tableService.getTableDataIgnorePaging();


    if (JSON.stringify(tempData) !== JSON.stringify(result)){
      this.props.setExcelYolcu(result);
    }
  }

  onSortChange = (sortName, sortOrder) =>{
    const tempData = this.props.excelYolcu;
    const currentData = this.refs.tableService.getTableDataIgnorePaging();
    if (JSON.stringify(tempData) !== JSON.stringify(currentData)){
      this.props.setExcelYolcu(currentData);
    }
  }  

  

  render(){
    let tempDate = this.state.currentOptionTarihValue.split(" ");
    let currentDate = tempDate[0].replace(/[\/]/g,'-'); //eslint-disable-line
    const excelFileName = currentDate+" "+this.state.currentOptionSaatValue.replace(":","-");
    
    const options = {
      // sizePerPageList: [],
      // hidePageListOnlyOnePage: true,
      // sizePerPage: 100,
      defaultSearch: this.state.defaultSearch,
      afterSearch: this.afterSearch,
      noDataText: 'Gösterilecek kayıt yok',
      onSortChange: this.onSortChange
      
    };
    const footerData = [
      [
        {
          label: '',
          columnIndex: 1,
          align: 'left',
          formatter: (tableData) => {
            let total = tableData.length;
            return (
              <strong>Toplam : { total } adet</strong>
            );
          }
          
        }

      ]
    ];    
     
    
    return(
      <div>
      <div className="card card-body bg-light content-toolbar">
      <div className="d-flex">
          <div className="p-2 d-flex">
          <label className="filterLabelServisTarihSaat">Servis Tarih / Saat : </label>
          <Select
            className="filterSelectYolcuRaporu firstSelect"
            options={this.state.filtersTarih} 
            // value={this.state.selectedOptionTarih}
            onChange={this.handleFilterTarihChange}
            placeholder="Tarih seçiniz.."
          />
          <Select
            className="filterSelectYolcuRaporu"
            options={this.state.filtersSaat} 
            value={this.state.selectedOptionSaat}
            onChange={this.handleFilterSaatChange}
            placeholder="Saat seçiniz.."
            isDisabled={this.state.disableSaat}
          />          
      
          </div>
          <div className="ml-auto p-2">
              <div className="d-flex">
              {
                this.props.passengersList.length > 0 &&
                <ReactHTMLTableToExcel  
                  className={`btn btn-info btn-excel btn-summary-excel`}  
                  table="emp-yolcuraporu"
                  filename={`${excelFileName} Servis Yolcu Raporu`}  
                  sheet={excelFileName}  
                  buttonText="Excel'e aktar"
                /> 
              }
              <label className="filterLabelYolcuRapor">Filtrele : </label> <input id="editFilter" ref={this.searchRef} type="text" className="form-control edit" placeholder="Arama kriteri" onChange={(event) => this.handleFilterSearch(event.target.value)}  />

              </div>
          </div>
      </div>   
      </div>       
      {
        this.state.loading ? <LoadingIndicator /> :
        this.state.selectedOptionSaat !== null && this.props.passengersList.length > 0 ? (
          <BootstrapTable ref='tableService' data={this.props.passengersList} containerClass='services-list' striped hover options={options} footerData={ footerData } footer search>
            <TableHeaderColumn dataField='RECID' isKey={true} hidden>ID</TableHeaderColumn>
            <TableHeaderColumn dataField='SERVICENAME' width='350px' className='servicenameCol' dataSort={ true }>Servis Adı</TableHeaderColumn>
            {/* <TableHeaderColumn dataField='RESERVATIONTIME' width='200px' dataSort={ true }>Rezervasyon Zamanı</TableHeaderColumn> */}
            <TableHeaderColumn dataField='ACTIONSTAT' width='200px' dataAlign='center' headerAlign='center' dataFormat={ this.actionStat } dataSort={ true }>Durum</TableHeaderColumn>
            <TableHeaderColumn dataField='DISPLAYNAME' width='350px' dataAlign='center' headerAlign='center' dataSort={ true }>Ad / Soyad</TableHeaderColumn>
            <TableHeaderColumn dataField='IDNO' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Sicil No </TableHeaderColumn>
            <TableHeaderColumn dataField='PHONENUMBER' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Telefon Numarası </TableHeaderColumn>
            <TableHeaderColumn dataField='EMAIL' width='300px' dataAlign='center' headerAlign='center' dataSort={ true }>E-Posta </TableHeaderColumn>
            <TableHeaderColumn dataField='BUSPLATE' width='200px' dataAlign='center' headerAlign='center' dataSort={ true }>Plaka </TableHeaderColumn>
            <TableHeaderColumn dataField='BUSDRIVER' width='250px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Adı </TableHeaderColumn>
            <TableHeaderColumn dataField='DRIVERPHONE' width='200px'dataAlign='center' headerAlign='center' dataSort={ true }>Sürücü Cep Telefonu </TableHeaderColumn>
            <TableHeaderColumn dataField='SERVICESTAT' dataAlign='center' headerAlign='center' width='280px' dataFormat={ this.serviceStat } dataSort={ true }>Servis Durumu</TableHeaderColumn>
            
          </BootstrapTable>   
        ) : 
        <p>Gösterilecek kayıt yok.</p>
      }

      {
        <table id="emp-yolcuraporu" className="excel-table">  
        <tbody>             
          <tr>
            <td><b>Servis Tarih / Saat</b></td>
            <td>{this.state.currentOptionTarihValue} {this.state.currentOptionSaatValue}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>Servis Adı</b></td>
            {/* <td><b>Rezervasyon Zamanı</b></td> */}
            <td><b>Durum</b></td>
            <td><b>Ad / Soyad</b></td>
            <td><b>Sicil No</b></td>
            <td><b>Telefon Numarası</b></td>
            <td><b>E-Posta</b></td>
            <td><b>Plaka</b></td>
            <td><b>Sürücü Adı</b></td>
            <td><b>Sürücü Cep Telefonu</b></td>
            <td><b>Servis Durumu</b></td>
          </tr>                                   
            { 
              this.props.excelYolcu.map((p, index) => {  
                  return (
                    <tr key={index}>  
                    <td >{p.SERVICENAME}</td>  
                    {/* <td >{p.RESERVATIONTIME}</td>   */}
                    <td >{p.ACTIONSTAT === "1" ? "Rezervasyon Yapılmış" : p.ACTIONSTAT === "2" ? "Rezervasyon İptal" : p.ACTIONSTAT === "3" ? "Geldi" : "İşlem Yapılmamış"}</td>  
                    <td >{p.DISPLAYNAME}</td> 
                    <td >{p.IDNO}</td>
                    <td >{p.PHONENUMBER}</td>
                    <td >{p.EMAIL}</td>
                    <td >{p.BUSPLATE}</td>  
                    <td >{p.BUSDRIVER}</td>  
                    <td >{p.DRIVERPHONE}</td>
                    <td >{p.SERVICESTAT > 0 ? "Rezervasyona Kapalı" : "Rezervasyona Açık"}</td>    
                  </tr>  
                  )
              })  
            }  
            <tr>
            <td><b>Toplam</b></td>
            <td><b>{this.props.excelYolcu.length}</b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
            <td><b></b></td>
          </tr>  
          </tbody>  
          </table>  
          
      }          
      
      
                    
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.User,
    isLoading: state.login.isLoading,
    error: state.login.error,
    passengersList:state.passenger.passengersList,
    excelYolcu: state.passenger.excelYolcu,
    serviceRow: state.passenger.serviceRow
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(actionCreators.userLogout()),
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setLoading: (showLoading) => dispatch({ type: LoginActionTypes.SET_LOADING, showLoading: showLoading}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setPassengersList:  (payload) => dispatch({ type: PassengerActionTypes.SET_PASSENGERS_LIST, passengersList: payload}),
    setCurrentService: (payload) => dispatch({ type: PassengerActionTypes.SET_CURRENT_SERVICE, payload: payload}),
    setExcelYolcu: (excelYolcu) => dispatch({ type: PassengerActionTypes.SET_EXCEL_YOLCU, excelYolcu: excelYolcu})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(PassengersList));