import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './root-reducer';

// const logger = store => {
//   return next => {
//     return action => {
//       console.log('[Middleware] Dispatching',action);
//       const result = next(action);
//       console.log('[Middleware] next state', store.getState());
//       return result;
//     }
//   } 
// }

//const middlewares = [thunk,logger];
const middlewares = [thunk];

// if (process.env.NODE_ENV === 'development'){
//   middlewares.push(logger);
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export const persistor = persistStore(store);

export default { store, persistor };